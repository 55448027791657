import * as React from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ListPagination from 'components/ListPagination';
import GridToolbar from 'components/GridToolbar';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { useList } from "hooks/useList";
import { getListActions, checkCreatePermission } from "utils/listActions";
import { deleteBlogById, getBlogs } from 'services/blog';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    includeTotal: true,
};

export default function Blogs() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);

    const {
        loading,
        items,
        total,
        editItem,
        deleteItem,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getBlogs,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "blogs",
        deleteItemById: deleteBlogById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value)
            setFilters({ ...BaseFilters, [columnField]: value });
        else
            setFilters(BaseFilters);
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleConfirmationSuccess = () => {
        deleteItem(activeItemId);
        setActiveItemId(null);
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: 'title',
                headerName: 'Title',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'category',
                headerName: 'Category',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'description',
                headerName: 'Description',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'minimumScore',
                headerName: 'Min Score',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'maximumScore',
                headerName: 'Max Score',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'createdAt',
                headerName: 'Added on',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) =>
                    getListActions(params.id, editItem, setActiveItemId, "Blog")
            },
        ];
    }, []);

    return (
        <Box>
            <ContentHeader
                title="Blog"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                {checkCreatePermission("Blog") &&
                    <CustomButton
                        variant="contained"
                        onClick={editItem}
                    >
                        Create
                    </CustomButton>
                }
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ConfirmationDialog
                message={
                    `This BLog will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete blog"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
        </Box>
    );
}
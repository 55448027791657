import Client from "./_client";

export const getTags = async (tagFilters = {}) => {
    let url = "/tag?";

    Object.keys(tagFilters).forEach((key) => {
        if (tagFilters[key]) url += `${key}=${tagFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get tags. Please try again."
        );
    }

    return result?.data;
};

export const getTagById = async (id) => {
    let url = `/tag/${id}`;

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get tag. Please try again."
        );
    }

    return result?.data;
};

export const deleteTag = async (id) => {
  let url = `/tag/${id}`;

  let result = await new Client({
      path: url,
  }).delete();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to delete tag. Please try again."
      );
  }

  return result.data;
}

export const createTag = async (data) => {
  let url = `/tag`;

  let result = await new Client({
      path: url,
      payload: data,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to create tag. Please try again."
      );
  }

  return result.data;
}

export const updateTag = async (id, data) => {
  let url = `/tag/${id}`;

  let result = await new Client({
      path: url,
      payload: data,
  }).put();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to update tag. Please try again."
      );
  }

  return result.data;
}
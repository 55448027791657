import * as React from 'react';
import { useField } from "formik";
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    padding: "0px",
    background: '#e4e3e8',
    borderRadius: '8px',
    height: 'fit-content',
    margin: '8px 0',
  },
  radioGroupRoot: {
    margin: "0",
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    width: "-webkit-fill-available",
    display: "flex",
    flexDirection: "row",
  },
  formControlLabel: {
    margin: 0,
    padding: 0,
    borderRadius: 0,
  },
}));

export function FormCheckbox(props) {
  const classes = useStyles();

  const [field, meta, helpers] = useField(props);

  const isError = !!(meta.touched && meta.error);

  return (
    <div className={classes.checkboxContainer}>
      <FormControlLabel
        className={classes.formControlLabel}
        componentsProps={{
          typography: {
            fontSize: 12,
            fontWeight: "light",
          }
        }}
        style={{ borderColor: isError ? "#f55446" : "#c4c4c4" }}
        control={
          <Checkbox
            size='small'
            {...field}
            checked={field.value}
            disabled={props.readOnly}
            onChange={(e, checked) => helpers.setValue(checked)}
            style={{ padding: 7 }}
          />
        }
        label={props.label}
      />
      {isError && (
        <FormHelperText
          error
          component="span"
        >
          {meta.error}
        </FormHelperText>
      )}
    </div>
  );
}
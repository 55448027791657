import * as React from 'react';
import * as Yup from 'yup';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import GridToolbar from 'components/GridToolbar';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import FilterList from './FilterList';
import ListQuickSearch from 'components/ListQuickSearch';
import GoogleSheetImport from 'components/dialogs/GSheetImportDialog';
import { getListActions, checkCreatePermission } from "utils/listActions";
import { bulkJobDescriptionUpload, getJobDescription } from 'services/jobDescription';
import { deleteTag } from 'services/tagService';
import { useLoading, useSnackbar } from 'contexts';
import { session } from 'utils';
import { getDomains } from 'services';
import { toCode } from 'utils';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));


export default function JobDescription() {
    const classes = useStyles();
    const navigate = useNavigate();
    const loadings = useLoading();
    const snackbar = useSnackbar()

    const [activeItemId, setActiveItemId] = React.useState(null);
    const [data,setData]=React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNo, setPageNo] = React.useState(0);
    const [filters, setFilters] = React.useState({company:"",jobRole:""});
    const [openImport , setOpenImport] = React.useState(false)
    const [refresh , setRefresh] = React.useState(false)

    const TagSchema = Yup.object().shape({
        tags: Yup.array(Yup.object().shape({
          company: Yup.string().required('Company is required'),
          jobRole: Yup.string().required('Job Role is required'),
          domain: Yup.string().required('Domain is required'),
          jobDescription: Yup.string()
          .required('Job Description is required')
        }))
      });

    React.useEffect(() => {
          fetchData();
    }, [filters]);

    React.useEffect(()=> {
        if(refresh == true){
            fetchData()
        }
    },[refresh])

    const editItem = (e, itemId = 'create') => navigate(itemId);
    
    const handleConfirmationSuccess = () => {
        deleteTag(activeItemId)
        setActiveItemId(null);
        fetchData()
        setRefresh(true)
    };

    const handleFilterChange = (model) => {
        
        const { columnField, value } = model.items[0];
        setFilters((prevFilters) => ({
            ...prevFilters,
            [columnField]: value
        }));

    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const jobDescriptionData = await getJobDescription();
            const companiesArray = Object.values(jobDescriptionData);
            
            let fetchedData = companiesArray.flatMap(company =>
                company.jobRoles.map(jobRole => ({
                    key: jobRole.jobDescriptions?._id || jobRole?._id,
                    companyId: company._id,
                    company: company.name,
                    jobRoleId: jobRole._id,
                    jobRole: jobRole.name,
                    domainId: jobRole.domain?._id,
                    domain: jobRole.domain?.name,
                    jobDescription: jobRole.jobDescriptions?.content || "",
                    id: jobRole.jobDescriptions?._id,
                }))
            );

            setData(applyFilters(fetchedData, filters));
            setLoading(false);
            setRefresh(false)
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setLoading(false);
        }
    };

    const applyFilters = (data, filters) => {
        return data.filter((item) => {
            return Object.keys(filters).every((key) => {
                const value = filters[key];
                if (!value) return true;
                const itemValue = item[key]?.toString().toLowerCase();
                const filterValue = value.toLowerCase();
                return itemValue.includes(filterValue);
            });
        });
    };

    const handleSheetImport = () => {
        setOpenImport(true)
    }

    const handleClose = () => {
        setOpenImport(false)
        setRefresh(true)
    }
    
    const handleImport = async (rows) => {
        try {
          loadings.show();
          console.log("rows----",rows)
      
          const valid = await TagSchema.validate({ tags: rows }, { abortEarly: false });
          if (!valid) {
            snackbar.error(
              "Seems like you have some invalid data! Please check the student list."
            );
            return;
          }
      
          const { interviewDomains } = await getDomains();
          const userId = session.userId;

          const jobDescriptions = rows.map((item , index) => {
            const matchedDomain = interviewDomains.find(
              (domain) => domain.code === toCode(item.domain)
            );
           
            
            if (!matchedDomain) {
                throw new Error(`Domain "${item.domain}" not found in interview domains at row ${index + 1}.`);
              }else {
                snackbar.showSnackbar("Imported sheet data successfully", "success", true);
              }
      
            return {
              company: item.company,
              jobRole: item.jobRole,
              user: userId,
              domain: matchedDomain._id,
              jobDescription: item.jobDescription,
            };
          });
      
          const payload = { jobDescriptions };
      
          const result = await bulkJobDescriptionUpload(payload)
          handleClose()
        } catch (err) {
          console.log("err----",err)
          console.error(err.message || "An unexpected error occurred.", "error");
          snackbar.showSnackbar(err.message || "An unexpected error occurred.", "error");
        } finally {
          loadings.hide();
        }
      };
      

    const columns = React.useMemo(() => {
        return [
            {
                field: 'company',
                headerName: 'Company',
                flex: 1,
                headerClassName: classes.tableHeader,
            },
            {
                field: 'jobRole',
                headerName: 'Job Role',
                flex: 1,
                headerClassName: classes.tableHeader,
            },
            {
                field: 'domain',
                headerName: 'Domain',
                flex: 1,
                headerClassName: classes.tableHeader,
            },
            {
                field: 'jobDescription',
                headerName: 'Job Description',
                flex: 1,
                headerClassName: classes.tableHeader,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) => getListActions(params.id, editItem, setActiveItemId, "Tag")     
            },
        ];
    }, []);

    return (
        <Box>
            <ContentHeader
                title="Company Job Description"
                BaseFilters={{}}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                {checkCreatePermission("Tag") &&
                    <CustomButton
                        variant="contained"
                        onClick={editItem}
                    >
                        Create
                    </CustomButton>
                }
                <CustomButton 
                    variant="outlined"
                    onClick={handleSheetImport}
                    >
                        Import
                    </CustomButton>
                <FilterList>
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by company"
                        field="company"
                        value={filters.company}
                    />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by jobRole"
                        field="jobRole"
                        value={filters.jobRole}
                    />
                </FilterList>
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    loading={loading}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    page={pageNo}
                    pageSize= {pageSize}
                    onFilterModelChange={handleFilterChange}
                    rows={data}
                    getRowId={(row) => row.key}
                    columns={columns}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                    }}
                />  
            </div>
            <ConfirmationDialog
                message={
                    `This data will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete this data"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
               <GoogleSheetImport  open={openImport} onClose={handleClose} onSuccess={handleImport}
               tagCreation={true}/>
        </Box>
    );
}
export const tutorialTips = [
    {
        id: "first-create-interview-tip",
        message:
            "Select domain of your interview template",
    },
    {
        id: "second-create-interview-tip",
        message:
            "Select the type of template it is",
    },
    {
        id: "third-create-interview-tip",
        message:
            "Select a type to which the questions belong to",
    },
    {
        id: "fourth-create-interview-tip",
        message:
            "Add questions & answers from here",
    },
    {
        id: "fifth-create-interview-tip",
        message:
            "Select a sufficient time for you to answer the question",
    },
];

export const templateTypes = [
    {
        _id: "practice",
        name: "Practice"
    },
    {
        _id: "assignment",
        name: "Assignment"
    },
];

export const InterviewQuestionTypes = [
    {
        _id: "technical",
        name: "Technical"
    },
    {
        _id: "situational",
        name: "Situational"
    },
];
import React from 'react';

function SEPWebhookIFrame() {
  return (
    <div
      style={{
        height: "-webkit-fill-available",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <div style={{ width: "100%", height: "80vh", boxSizing: "border-box", border: "1px solid green" }}>
        <iframe
          title="Inprep"
          src={process.env.REACT_APP_SEP_WEBHOOK_URL}
          style={{ width: "100%", height: '80vh' }}
          allow=""
          frameBorder={0}
        />
      </div>
    </div>
  );
}

export default SEPWebhookIFrame;
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import ContentHeader from 'components/ContentHeader';
import FormikForm from "components/FormikForm";
import { useSnackbar } from "contexts";
import { getUserById, saveInternalUser, getRoles } from 'services';
import { InternalUserRoles } from 'config';

const fieldOptionsValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required')
        .min(3, "Atleast 3 letters required")
        .max(50, "Name can be of max 50 letters"),
    email: Yup.string()
        .email()
        .required("Required"),
    role: Yup.string()
        .required("Select a role"),
    contact: Yup.string()
        .optional().min(10).max(13),
});

const fields = [
    {
        name: 'name',
        type: 'text',
        label: 'Name'
    },
    {
        name: 'email',
        type: 'text',
        label: 'Email'
    },
    {
        name: 'contact',
        type: 'text',
        label: 'Mobile Number'
    },
    {
        name: 'role',
        type: 'select',
        label: 'Role'
    }
];

function InternalUserEditor() {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [loadingOptions, setLoadingOptions] = React.useState(true);

    const [formOptions, setFormOptions] = React.useState({ roles: [] });

    const [initialValues, setInitialValues] = React.useState({
        name: '',
        email: '',
        role: '',
        contact: '',
    });

    React.useEffect(() => {
        if (id !== "create" && !loadingOptions) {
            (async () => {
                try {
                    setLoading(true);

                    let user = await getUserById(id, { role: true });

                    setInitialValues({
                        name: user.name,
                        email: user.email,
                        contact: user.contact || "",
                        role: user?.role?._id,
                    });
                } catch (error) {
                    console.log(error);
                    snackbar.showSnackbar("Unable to get internal user", "error", true);
                } finally {
                    setLoading(false);
                }
            })();
        } else {
            setLoading(false);
        }
    }, [loadingOptions]);

    React.useEffect(() => {
        (async () => {
            try {
                setLoadingOptions(true);

                const { roles } = await getRoles();

                setFormOptions({
                    role: roles.filter(r => InternalUserRoles.has(r.name)),
                });
            } catch (error) {
                snackbar.showSnackbar("Unable to get roles", "error");
            } finally {
                setLoadingOptions(false);
            }
        })();
    }, []);

    const createOrUpdateUser = async (values) => {
        const userData = {
            name: values.name,
            email: values.email,
            role: values.role,
            contact: values.contact || "",
        };
        try {
            await saveInternalUser(id === "create" ? null : id, userData);

            snackbar.showSnackbar("User saved successfully", 'success', true);
            goBack();
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', true);
        }
    };

    const goBack = () => navigate("/users/internal");

    return (
        <>
            <ContentHeader title={"Internal Users Form"} />

            {((id !== "create" && initialValues.option !== "") || id === "create") &&
                !loading && (
                    <FormikForm
                        initialValues={initialValues}
                        fields={fields}
                        formOptions={formOptions}
                        validationSchema={fieldOptionsValidationSchema}
                        onSubmit={createOrUpdateUser}
                        onCancel={goBack}
                    />
                )}
        </>
    );
}

export default InternalUserEditor;
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { SimpleTextField } from 'components/FormTextField';
import { SimpleSelect } from 'components/FormSelect';

import { getDomains } from 'services/domains';
import { templateTypes } from '../TemplateEditor/constants';
import ClientSelect from 'components/ClientSelect';

function TemplateDetails(props) {
    const formik = props.formik;
    const { isCreator } = formik.values;
    const [domains, setDomains] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            try {
                const { interviewDomains } = await getDomains();
                setDomains(interviewDomains);
            } catch (error) {

            }
        })();
    }, []);

    return (
        <Box display="flex">
            <Box width={"60%"} m={2}>
                <SimpleTextField
                    label="Template Name*"
                    fullWidth
                    placeholder="e.g. Product Management Advanced"
                    variant="outlined"
                    name="template.name"
                    value={formik.values?.template?.name || ""}
                    onChange={formik.handleChange}
                    inputProps={{
                        readOnly: !isCreator,
                    }}
                    error={formik.touched.template?.name && Boolean(formik.errors?.template?.name)}
                    helperText={formik.errors?.template?.name}
                />
                <SimpleTextField
                    fullWidth
                    label="Remarks"
                    placeholder="e.g. crucial for placements"
                    name="template.remarks"
                    variant="outlined"
                    value={formik.values?.template?.remarks || ""}
                    onChange={formik.handleChange}
                    inputProps={{
                        readOnly: !isCreator,
                    }}
                />
                <ClientSelect
                    defaultValue={formik?.values?.template?.user}
                    onChange={(client) =>
                        formik.setFieldValue('template.user', client._id)
                    }
                    error={formik.errors?.template?.user}
                />
                <SimpleSelect
                    label="Domain*"
                    id="first-create-interview-tip"
                    options={domains}
                    name="template.domain"
                    value={formik.values?.template?.domain || ""}
                    onChange={formik.handleChange}
                    inputProps={{
                        readOnly: !isCreator,
                    }}
                    error={Boolean(formik.errors?.template?.domain)}
                    helperText={formik.errors?.template?.domain}
                />
                <Box
                    display="flex"
                    width={"100%"}
                    justifyContent="space-between"
                    flexDirection={"column"}
                    mt={1}
                >
                    <SimpleSelect
                        label="Template Type*"
                        id="second-create-interview-tip"
                        options={templateTypes}
                        name="template.type"
                        value={formik.values?.template?.type || ""}
                        onChange={formik.handleChange}
                        inputProps={{
                            readOnly: !isCreator,
                        }}
                        error={Boolean(formik.errors?.template?.type)}
                        helperText={formik.errors?.template?.type}
                    />
                    <SimpleTextField
                        label="Sheet Url*"
                        fullWidth
                        variant="outlined"
                        name="sheetUrl"
                        value={formik.values?.sheetUrl || ""}
                        onChange={formik.handleChange}
                        inputProps={{
                            readOnly: !isCreator,
                        }}
                        error={formik.touched.sheetUrl && Boolean(formik.errors?.sheetUrl)}
                        helperText={formik.errors?.sheetUrl}
                    />
                    <SimpleTextField
                        label="Sheet name*"
                        fullWidth
                        variant="outlined"
                        name="sheetName"
                        value={formik.values?.sheetName || ""}
                        onChange={formik.handleChange}
                        inputProps={{
                            readOnly: !isCreator,
                        }}
                        error={formik.touched.sheetName && Boolean(formik.errors?.sheetName)}
                        helperText={formik.errors?.sheetName}
                    />
                    {isCreator && <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        sx={{
                            height: 'fit-content',
                            alignSelf: 'flex-end',
                            mt: 1,
                        }}
                    >
                        Import from google sheet and create
                    </Button>}
                </Box>
            </Box>
        </Box >
    );
}

export default TemplateDetails;
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { FormHelperText, Typography } from '@mui/material';
import { getDomains } from 'services';

const useStyles = makeStyles((theme) => ({
    error: {
        marginLeft: 16,
    },
    root: {
        margin: "8px 0",
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
        "& .MuiInputLabel-root": {
            fontSize: "12px !important",
            fontWeight: "normal !important",
        },
        "& .MuiFilledInput-root": {
            fontSize: 12,
            fontWeight: "normal",
            borderRadius: "6px",
            backgroundColor: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            '&>*': {
                padding: 10
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "#E4E3E8",
            borderRadius: "6px",
            fontSize: 12,
            fontWeight: "normal",
            '& fieldset': {
                borderRadius: "6px",
                borderColor: "transparent",
                fontWeight: "normal",
                fontStyle: "normal",
                fontFamily: "'Inter', sans-serif",
                fontSize: 12,
            },
            '&:hover fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: "normal",

            },
            '&.Mui-focused fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: "normal",
            },
        },
    },
}));

export function DomainSelect({ 
  defaultValue, onChange = () => { }, error = "", disabled=false
}) {
    const classes = useStyles();
    const [domains, setDomains] = React.useState([]);
    const [value, setValue] = React.useState(null);
    const handleDomainChange = (event, newValue) => {
        setValue(newValue);
        onChange(newValue);
    }
    React.useEffect(() => {
        (async () => {
            const { interviewDomains } = await getDomains();
            setDomains(interviewDomains);
        })();
    }, []);

    React.useEffect(() => {
        const domain = domains.find(c => c._id === defaultValue);

        if (domain) {
            setValue(domain);
            onChange(domain);
        }
    }, [domains, defaultValue]);

    return (
        <>
            <Autocomplete
                fullWidth
                size="small"
                options={domains}
                value={value}
                name='domain'
                onChange={handleDomainChange}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={`Choose a domain`}
                        className={classes.root}
                        error={!!error}
                    />
                )}
                getOptionLabel={(user) => `${user.name}`}
                renderOption={(props, option) => (
                    <Typography
                        {...props}
                        component="li"
                        sx={{ fontSize: 14, fontWeight: "normal", color: "inherit" }}
                    >
                        {option.name}
                    </Typography>
                )}
            />
            {!!error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
        </>
    );
}
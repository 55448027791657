import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';

import { MockClientSelect } from 'components/ClientSelect';
import { SimpleTextField } from 'components/FormTextField';
import { useSnackbar } from "contexts";
import { generateMockMagicLink, generateMockReportLink } from 'services';
import { copyToClipboard } from "utils";
import { useEffect } from 'react';
import { Divider } from '@mui/material';

function MockMagicLink(props) {
    const snackbar = useSnackbar();
    const [client, setClient] = React.useState();
    const [rClient, setRClient] = React.useState();
    const [attemptId, setAttemptId] = React.useState("");
    const [error, setError] = React.useState("");
    const [inputs, setInputs] = React.useState({});
    const [link, setLink] = React.useState("");
    const [reportLink, setReportLink] = React.useState("");
    const [localLink, setLocalLink] = React.useState("");

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    useEffect(() => {
        const queryUrl = link?.split('//')[1]?.split('/')[1];
        setLocalLink(`http://localhost:3000/${queryUrl}`);
    }, [link]);


    const handleSubmit = (e) => {
        e.preventDefault();

        setError("")
        if (!client) {
            setError("Select client")
            return false;
        }

        localStorage.setItem("ml-generation-data", JSON.stringify({
            client,
            inputs
        }));

        generateMockMagicLink({
            clientId: client._id,
            clientEmail: client.email,
            user_name: inputs.name,
            user_email: inputs.email,
            duration: inputs.duration,
            mockType: inputs.mockType
        })
            .then((data) => {
                setLink(data.link);
            })
            .catch((err) => snackbar.showSnackbar(err.message, "error"));
    };

    const fetchReportLink = (e) => {
        e.preventDefault();

        if (!client) {
            snackbar.showSnackbar('Select client for fetch report link', "error")
            return false;
        }

        generateMockReportLink({
            clientId: rClient._id,
            attemptId
        })
            .then((data) => {
                setReportLink(data.reportLink);
            })
            .catch((err) => snackbar.showSnackbar(err.message, "error"));
    }

    React.useEffect(() => {
        const cached = JSON.parse(localStorage.getItem("ml-generation-data"));
        if (cached) {
            setInputs(cached.inputs);
            setClient(cached.client);
        }
    }, []);


    return (
        <>
            <ContentHeader title="Mock Api integration" />
            <br />
            <br />
            <Box width="50%" m="auto">
                <form onSubmit={handleSubmit}>
                    <Typography>Generate mock magic link -</Typography>
                    <MockClientSelect
                        error={error}
                        defaultValue={client?._id}
                        onChange={(client) => setClient(client)}
                    />

                    <SimpleTextField
                        placeholder="john@gmail.com"
                        name="email"
                        label="User Email"
                        type="email"
                        value={inputs.email || ""}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <SimpleTextField
                        placeholder="John wick"
                        name="name"
                        label="User name"
                        type="text"
                        value={inputs.name || ""}
                        onChange={handleChange}
                        required
                    />
                    <br />


                    <FormControl>
                        <FormLabel id="mockType">Mock type:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="mockType"
                            name="mockType"
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value='toefl'
                                name='mockType'
                                control={<Radio required />}
                                label="toefl"
                                checked={inputs.mockType === "toefl"}
                            />
                            <FormControlLabel
                                value='ielts'
                                name='mockType'
                                control={<Radio required />}
                                label="ielts"
                                checked={inputs.mockType === "ielts"}
                            />
                        </RadioGroup>
                    </FormControl>

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                    <FormControl>
                        <FormLabel id="duration">Duration :</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="duration"
                            name="duration"
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value='short'
                                name='duration'
                                control={<Radio required />}
                                label="short"
                                checked={inputs.duration === "short"}
                            />
                            <FormControlLabel
                                value='full'
                                name='duration'
                                control={<Radio required />}
                                label="full"
                                checked={inputs.duration === "full"}
                            />
                        </RadioGroup>
                    </FormControl>
                    <br />

                    <CustomButton variant="contained" type="submit" name="sep">
                        Generate Link
                    </CustomButton>
                </form>
                <br />

            </Box>
            {link && (
                <>
                    <Box display="flex" alignItems="center" >
                        <SimpleTextField
                            readOnly
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={link}
                            spellCheck="false"
                            disabled
                        >
                        </SimpleTextField>
                        &nbsp;&nbsp;
                        <ContentCopyOutlinedIcon
                            fontSize="medium"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                copyToClipboard(link);
                                snackbar.showSnackbar('copied!', 'info', true);
                            }}
                        />
                        <Link to={`/integrations/iframe?url=${link}`} style={{ textDecoration: "none", }} >
                            <Button
                                style={{ width: 160 }}
                                variant="contained"
                            >
                                View in IFrame
                            </Button>
                        </Link>
                    </Box>

                </>
            )}

            <br />  <Divider /> <br />


            <Box width="50%" m="auto">
                <form onSubmit={fetchReportLink}>
                    <Typography>Fetch report link -</Typography>
                    <MockClientSelect
                        error={error}
                        defaultValue={rClient?._id}
                        onChange={(client) => setRClient(client)}
                    />

                    <SimpleTextField
                        placeholder="6428486e18089f25ee1fb806"
                        name="attemptId"
                        label="Attempt id"
                        type="text"
                        value={attemptId || ""}
                        onChange={(e) => setAttemptId(e.target.value)}
                        required
                    />
                    <br />

                    <CustomButton variant="contained" type="submit" name="sep">
                        Get Report link
                    </CustomButton>
                    {reportLink && (
                        <>
                            <Box display="flex" alignItems="center" >
                                <SimpleTextField
                                    readOnly
                                    size='small'
                                    variant="outlined"
                                    fullWidth
                                    value={reportLink}
                                    spellCheck="false"
                                    disabled
                                >
                                </SimpleTextField>
                                &nbsp;&nbsp;
                                <ContentCopyOutlinedIcon
                                    fontSize="medium"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        copyToClipboard(reportLink);
                                        snackbar.showSnackbar('copied!', 'info', true);
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </form>
                <br />

            </Box>
        </>
    );
}

export default MockMagicLink;
import React from 'react'
import ContentHeader from '../../ContentHeader';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider } from '@mui/material';
import ModuleEditor from './ModuleEditor';
import AppShell from 'components/AppShell';
import Sidebar from './Sidebar';
import Pathway from './Pathway';
import Questions from './Questions';
import useStore from './store';
import shallow from 'zustand/shallow';
import { useParams } from 'react-router-dom';
import { getPathwayTagMappings, getPathways } from 'services/pathwayService';
import { useLoading, useSnackbar } from 'contexts';

const useStyles = makeStyles((theme) => ({
  pathwayForm: {
    marginBlock: "1.5rem",
    marginTop: '20px'
  },
  contentRoot: {
    flex: "auto",
    paddingRight: '10px'
  }
}));

const sideBarLinks = {
  PATHWAY_EDITOR: {
    field: 'PATHWAY_EDITOR',
    label: "Pathway Editor",
    path: '#',
  },
  QUESTION_EDITOR: {
    field: 'QUESTION_EDITOR',
    label: "Question Editor",
    path: '#',
  },
  MODULE_EDITOR: {
    field: 'MODULE_EDITOR',
    label: "Module Editor",
    path: '#',
  },
}


function PathwayEditor() {
  const classes = useStyles();
  const { id } = useParams();
  const loading = useLoading();

  const snackbar = useSnackbar();

  const [activeTab, setActiveTab] = useState('PATHWAY_EDITOR');
  const pathway = useStore(state => state.pathway, shallow);
  const setPathway = useStore(state => state.setPathway, shallow);
  
  const reset = useStore(state => state.reset, shallow);

  React.useEffect(() => {
    (async () => {
      try {
        loading.show();
        if (id && id !== 'create') {
          let { pathways } = await getPathways({_id: id, include: ['tags'] });

          const jobRoleTypeTag = pathways[0].tags?.find(tag=> tag.category === 'jobRoleType');

          const tags = pathways[0].tags?.filter(tag=> tag.category !== 'jobRoleType').map(tag => tag._id);
          pathways[0].tags = tags;
          pathways[0].jobRoleType = jobRoleTypeTag?._id;

          reset();
          setPathway(pathways[0]);
        }
        else{
          setPathway({});
        }
      } catch (error) {
        snackbar.showSnackbar(error.message, 'error', false);
      } finally {
        loading.hide();
      }
    })();
  }, [id]);

  return (
    <>
      <AppShell>
        <Box display='flex' width='100%'>
          <Sidebar activeTab={activeTab} setActiveTab={setActiveTab}
            sideBarLinks={sideBarLinks} pathway={pathway}
          />

          <Box className={classes.contentRoot}>
            <ContentHeader title={sideBarLinks[activeTab].label} />

            {activeTab === sideBarLinks.PATHWAY_EDITOR.field ? <Pathway /> : null}
            {activeTab === sideBarLinks.QUESTION_EDITOR.field ? <Questions /> : null}
            {
              activeTab === sideBarLinks.MODULE_EDITOR.field ?
                <>
                  <Divider />
                  <ModuleEditor />
                </>
                : null
            }
          </Box>
        </Box>
      </AppShell>
    </>
  )
}

export default PathwayEditor
import { createTheme } from "@mui/material/styles";

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});


export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#2DABFF",
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: "#f55446",
    },
    divider: "rgba(133, 140, 144, 1)",
    success: {
      main: "#35e9a8",
    },
  },
  typography: {
    fontFamily: "'Sora',sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: 30,
      [breakpoints.breakpoints.down('md')]: {
        fontSize: 25,
      },
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    h2: {
      fontWeight: 700,
      fontSize: 25,
      [breakpoints.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    h3: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 700,
      fontSize: "3vmax",
      fontStyle: "normal",
    },
    h4: {
      fontWeight: 600,
      fontSize: 24,
      [breakpoints.breakpoints.down('md')]: {
        fontSize: 20
      },
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: 15
      }
    },
    h5: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 700,
      fontSize: "1.5vmax",
      fontStyle: "normal",
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      [breakpoints.breakpoints.down('md')]: {
        fontSize: 18
      },
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: 15
      }
    },
    body1: {
      //text_x_large
      fontWeight: 700,
      fontSize: 18,
      [breakpoints.breakpoints.down('md')]: {
        fontSize: 15
      },
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: 13
      }
    },
    subtitle1: {
      //body text large
      fontWeight: 400,
      fontSize: 15,
      [breakpoints.breakpoints.down('md')]: {
        fontSize: 13
      },
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: 12
      }
    },
    body2: {
      //body text
      fontWeight: 400,
      fontSize: 13,
      [breakpoints.breakpoints.down('md')]: {
        fontSize: 10
      },
      [breakpoints.breakpoints.down('sm')]: {
        fontSize: 7
      }
    },
    subtitle2: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "0.70vmax",
      fontStyle: "bold",
    },
    caption: {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontSize: "0.7vmax",
      color: "#000000",
    },
    button_text: {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1.2vmax",
      color: "#000000",
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;

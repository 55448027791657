import Typography from '@mui/material/Typography';

export function FieldLabel({ children, ...props }) {
    return (
        < Typography variant="body1">
            {children}
        </Typography>
    );
}

export function FieldLabelSmall({ children, ...props }) {
    return (
        < Typography {...props} variant="body1">
            {children}
        </Typography>
    );
}

export default FieldLabel;
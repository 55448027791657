import Client from "./_client";
import Session from "../utils/Session";

export const getApplications = async (applicationFilters = {}) => {
    let url = "/application?";

    if (!applicationFilters.user) applicationFilters.user = Session.userId;

    Object.keys(applicationFilters).forEach((key) => {
        if (applicationFilters[key])
            url += `${key}=${applicationFilters[key]}&`;
    });

    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            'Uh Oh! Unable to get applications. Please try again.'
        );
    }

    return result.data;
}

export const getApplicationById = async (applicationId) => {
    const result = await new Client({
        path: `/application/${applicationId}`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message
            || 'Uh Oh! Unable to get application. Please try again.'
        );
    }

    return result.data;
}

export const saveApplication = async (applicationId, name) => {
    let result;

    if (applicationId) {
        result = await new Client({
            path: `/application/${applicationId}`,
            payload: { name }
        }).put();
    } else {
        result = await new Client({
            path: `/application`,
            payload: { name },
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save application. Please try again."
        );
    } else {
        return result.data;
    }
}

export const deleteApplicationById = async (applicationId) => {

    let url = `/application/${applicationId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message ||
            "Uh Oh! Unable to delete application. Please try again."
        );

    return result.data;
}



import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider, LoadingProvider } from "contexts";

import Clients from "./Clients";
import Configs from "./Configs";
import SignIn from "./SignIn";
import theme from "theme";
import NotFound from "./NotFound";
import Users from "./Users";
import Analytics from "./Analytics";
import General from "./General";
import Templates from "./Templates";
import SessionHelper from "components/SessionHelper";
import PrivateRoute from "components/PrivateRoute";
import Integrations from "./Integrations";
import MockSectionEditor from "./Workspace";
import ErrorBoundary from "components/ErrorBoundary";
import Pathways from "./Pathways";

export default function Screen() {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <ThemeProvider theme={theme}>
                    <LoadingProvider>
                        <SnackbarProvider>
                            <SessionHelper>
                                <Routes>
                                    <Route path='/' element={<SignIn />} />
                                    <Route path="/users/*" element={<PrivateRoute component={Users} />} />
                                    <Route path="/clients/*" element={<PrivateRoute component={Clients} />} />
                                    <Route path="/configs/*" element={<PrivateRoute component={Configs} />} />
                                    <Route path="/analytics/*" element={<PrivateRoute component={Analytics} />} />
                                    <Route path="/general/*" element={<PrivateRoute component={General} />} />
                                    <Route path="/templates/*" element={<PrivateRoute component={Templates} />} />
                                    <Route path="/pathways/*" element={<PrivateRoute component={Pathways} />} />
                                    <Route path="/integrations/*" element={<PrivateRoute component={Integrations} />} />
                                    <Route path="/workspace" element={<MockSectionEditor />} />
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </SessionHelper>
                        </SnackbarProvider>
                    </LoadingProvider>
                </ThemeProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
}
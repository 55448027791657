import * as React from 'react';
import {
    DataGrid,
    getGridStringOperators,
    GridActionsCellItem
} from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { getUsers, deleteUserById, getUserById } from "services/users";

import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import GridToolbar from 'components/GridToolbar';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ListPagination from 'components/ListPagination';
import ListQuickSearch from 'components/ListQuickSearch';
import FilterList from './FilterList';
import { useList } from "hooks/useList";

import { ROLES } from '../../config';
import { formatDate } from 'utils/date';
import { checkCreatePermission } from 'utils';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = { roleNames: [ROLES.AMBASSADOR_USER], includeTotal: true };

export default function CAPUsers() {
    const classes = useStyles();

    const [activeItemId, setActiveItemId] = React.useState(null);

    const {
        loading,
        items,
        total,
        editItem,
        setFilters,
        filters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getUsers,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "users",
        deleteItemById: deleteUserById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value) {
            setFilters({ ...BaseFilters, filters, [columnField]: value });
        } else {
            const newFilters = { ...BaseFilters, ...filters };
            delete newFilters[[columnField]];
            setFilters(newFilters);
        }
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'contact',
                headerName: 'Contact',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'createdAt',
                headerName: 'Onboarded on',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const date = params?.row?.createdAt;
                    return formatDate(date);
                }
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 130,
                headerClassName: classes.tableHeader,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="View"
                        onClick={(e) => setActiveItemId(params.row._id)}
                    />
                ]
            },
        ];
    }, [classes.tableHeader]);

    return (
        <Box>
            <ContentHeader
                title="Ambassador Users"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <FilterList>
                    <ListQuickSearch onFilterChange={handleFilterChange} value={filters[['name']]} />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by email"
                        field="email"
                        value={filters[['email']]}
                    />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by Contact"
                        field="contact"
                        value={filters[['contact']]}
                    />
                </FilterList>
                {checkCreatePermission("User") &&
                    <CustomButton
                        variant="contained"
                        onClick={editItem}
                    >
                        Create
                    </CustomButton>
                }
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <EntityViewDialog
                open={!!activeItemId}
                getEntity={getUserById}
                title="SEP User"
                entityId={activeItemId}
                onClose={() => setActiveItemId(null)}
            />
        </Box>
    );
}
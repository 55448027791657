import Bowser from 'bowser';
import Session from "./Session";

export const subMinutes = function (date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
};

export const getViewportDimensions = () => {

  let width;
  let height;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth !== 'undefined') {
    width = window.innerWidth;
    height = window.innerHeight;
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (typeof document.documentElement !== 'undefined'
    && typeof document.documentElement.clientWidth !=
    'undefined' && document.documentElement.clientWidth !== 0) {
    width = document.documentElement.clientWidth;
    height = document.documentElement.clientHeight;
  }

  // older versions of IE
  else {
    width = document.getElementsByTagName('body')[0].clientWidth;
    height = document.getElementsByTagName('body')[0].clientHeight;
  }
  return { width, height };
};

export const isBrowserSupported = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  const isValidBrowser = browser.satisfies({
    windows: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>57',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    linux: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>57',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    macos: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>57',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    ios: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>57',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    android: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>9999',
      firefox: '>9999',
      opera: '>9999',
      edge: '>9999'
    },
    'Chrome OS': {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>57',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
  });
  return isValidBrowser;
};

const getUserMedia = constraints => {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      var getUserMedia = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser')
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  } else {
    return navigator.mediaDevices.getUserMedia(constraints);
  }
};

export const checkMicrophone = () => {
  return new Promise((resolve, reject) => {
    getUserMedia({ audio: true })
      .then(function (stream) {
        resolve(true);
      })
      .catch(function (err) {
        resolve(false);
      });
  });
};

export const secondsToMMSS = (seconds) => {
  if (seconds < 0) return "";
  var MM = Math.floor(seconds / 60);
  var SS = seconds - (MM * 60);

  if (MM < 10) MM = `0${MM}`;
  if (SS < 10) SS = `0${SS}`;

  return `${MM}:${SS}`;
}

export const getClientPrefix = () => {
  const clientName = Session?.client
    ? Session?.client?.name
    : Session.user?.name
  return clientName ? clientName
    .split(" ")
    .map(part => part.toLowerCase())
    .join("-") : clientName;
}

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
}

export const formatSeconds = (time = 0) => {
  const duration = +time;
  if (duration === 0) return '0 secs';

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  let result = '';
  if (minutes) result += `${minutes} min `;
  result += `${seconds} secs`;

  return result;
};

export const toCode = (name = "") => {

  var strArr = name.trim().split(" ");
  var snakeArr = strArr.map((val) => val.toLowerCase());
  return snakeArr.join("_");
}

export * from "./date";
export * from "./listActions";
export * from "./roles";
export * from "./Session";
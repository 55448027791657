import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ContentEditableDiv({ containerProps, label, required, ...props }) {
  return (
    <Box position="relative" {...containerProps} my={1} >
      <Typography
        variant="caption"
        color="GrayText"
        fontWeight={600}
        fontSize={"0.8em"}
        bgcolor="white"
        sx={{ position: 'absolute', top: -12, left: 12, m: 0, py: 0, px: 0.5 }}
      >
        {label}{required ? "*" : ""}
      </Typography>
      <Box
        contentEditable
        minHeight={72}
        widht={"100%"}
        mt={2}
        p={1}
        border="1px solid lightgrey"
        borderRadius={1}
        style={{ outlineColor: "#2DABFF" }}
        maxHeight={180}
        overflow="auto"
        {...props}
      />
    </Box >
  );
}

import { session } from "utils";
import Client from "./_client";

export const getBlogs = async (blogFilters = {}) => {
    let url = "/blog?";

    Object.keys(blogFilters).forEach((key) => {
        if (blogFilters[key] !== undefined) url += `${key}=${blogFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to fetch blogs!");

    return result.data || [];
}

export const getBlogById = async (blogId) => {
    const url = `/blog/${blogId}`;

    let result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get blog. Please try again."
        );
    }

    return result.data;
}

export const createBlog = async (blog) => {

    let url = `/blog`;

    let result = await new Client({
        path: url,
        payload: { ...blog, user: session.userId },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save blog. Please try again."
        );
    }

    return result.data;
}

export const updateBlogById = async (blogId, blog) => {

    let url = `/blog/${blogId}`;

    let result = await new Client({
        path: url,
        payload: blog,
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to udpate blog. Please try again."
        );
    }

    return result.data;
}

export const deleteBlogById = async (blogId) => {

    let url = `/blog/${blogId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete blog. Please try again."
        );

    return result.data;
}

import Client from "./_client";

export const getJobDescription = async (jobDescriptionFilters = {}) => {
    let url = "/tag/companyJobRoleMap?";

    Object.keys(jobDescriptionFilters).forEach((key) => {
        if (jobDescriptionFilters[key]) {
            url += `${key}=${jobDescriptionFilters[key]}&`;
        }
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get Job Description. Please try again."
        );
    }

    return result?.data;
};

export const saveJobDescription = async (companyId, company) => {
    let result;
    if (companyId) {
        result = await new Client({
            path: `/tag/${companyId}`,
            payload: company,
        }).put();
    } else {
        result = await new Client({
            path: `/tag/companyJobRole`,
            payload: company,
        }).post();
    }
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save Job Description. Please try again."
        );
    }

    return result.data;
}

export const bulkJobDescriptionUpload = async (data) => {
    let result;

    result = await new Client({
        path: `/tag/jobDescription/bulk`,
        payload: data,
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save Job Description in bulk. Please try again."
        );
    }

    return result.data;
} 
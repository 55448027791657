import Client from "./_client";

export const getInternalUsers = async (userFilters = {}) => {
  let url = "/internalUser?";

  Object.keys(userFilters).forEach((key) => {
    if (userFilters[key]) url += `${key}=${userFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get internal users. Please try again."
    );
  }

  return result?.data;
};

export const saveInternalUser = async (id, user) => {
  let result;
  if (id) {
    result = await new Client({
      path: `/internalUser/${id}`,
      payload: user,
    }).put();
  } else {
    result = await new Client({
      path: `/internalUser`,
      payload: user,
    }).post();
  }

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save user. Please try again."
    );
  }

  return result.data;
}
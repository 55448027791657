import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import useEditorStore from './editorStore';

function AddButton({ show }) {
    const addQuestion = useEditorStore(state => state.addQuestion);
    const creator = useEditorStore(state => state.creator);

    return creator ? (
        <Button
            onClick={addQuestion}
            sx={{ marginLeft: 'auto', }}
            startIcon={<AddIcon />}
            variant="outlined"
        >
            Add Question
        </Button>
    ) : null;
}

export default AddButton;
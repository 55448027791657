import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import ContentHeader from "components/ContentHeader";
import FormikForm from "components/FormikForm";
import { useSnackbar } from "contexts/SnackbarProvider";
import { createDomain, getDomainById, updateDomainById } from "services";

const domainValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
});

const fields = [
    {
        name: 'name',
        type: 'text',
        label: 'Name'
    },
    {
        name: 'code',
        type: 'text',
        label: 'Code',
        readOnly: true
    },
    {
      name: 'alias',
      type: 'text',
      label: 'Alias'
  },
];

export default function DomainEditor() {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = React.useState(
        {
            name: '',
            code: 'Auto generated',
            alias: '',
        });
    const { id } = useParams();

    async function loadDomainById() {
        try {
            let tempDomain = await getDomainById(id);
            setInitialValues({ name: tempDomain.name, code: tempDomain.code, alias: tempDomain?.alias });
        } catch (error) {

        }
    }

    React.useEffect(() => {
        if (id !== "create")
            loadDomainById();
    }, []);

    async function saveDomain(values) {
        try {
            const domain = {};
            domain["name"] = values.name;
            domain["alias"] = values.alias;
            domain["code"] = values.code;
            if (id === "create") {
                await createDomain(domain);
                snackbar.showSnackbar("Domain created successfully", 'success', true);
            }
            else {
                await updateDomainById(id, domain);
                snackbar.showSnackbar("Domain updated successfully", 'success', true);
            }
            navigate("/configs/domains");
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', true);
        }
    }

    function goBack() {
        navigate("/configs/domains");
    }

    return (
        <>
            <ContentHeader title={`${id === "create" ? "Create" : ""}Domain}`} />
            {((id !== "create" && initialValues.name !== "") || id === "create") &&
                <FormikForm
                    initialValues={initialValues}
                    fields={fields}
                    validationSchema={domainValidationSchema}
                    onSubmit={saveDomain}
                    onCancel={goBack}
                />}
        </>
    );
}
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ContentHeader from 'components/ContentHeader';
import { useSnackbar } from "contexts";
import FormikForm from "components/FormikForm";
import {
    getSurveyQuestionById, saveSurveyQuestions, getDistinctFields
} from "services";
import { surveyQuestionValidationSchema, fields } from './constants';

const initialFormOptions = {
    questionFor: [
        {
            _id: "EXTENSION_USER",
            name: "Extension User"
        },
        {
            _id: "INTERVIEW_STUDENT",
            name: "Interview Student"
        },
        {
            _id: "INTERVIEW_INSTRUCTOR",
            name: "Interview Instructor"
        },
    ],
    questionType: [
        {
            _id: "multiChoice",
            name: "Multi Choice"
        },
        {
            _id: "singleChoice",
            name: "Single Choice"
        },
    ],
};

export default function SurveyQuestionEditor() {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = React.useState({
        question: '',
        questionFor: '',
        options: '',
        metadata: '',
        metadataOptions: '',
        title: ''
    });
    const { id } = useParams();
    const [formOptions, setFormOptions] = React.useState(initialFormOptions);

    async function loadMetadataOptions() {
        try {
            let tempMetadataOptions = await getDistinctFields();
            setFormOptions({ ...formOptions, metadataOptions: tempMetadataOptions });
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', true);
        }
    }

    React.useEffect(() => {
        loadMetadataOptions()
        if (id !== "create") {
            (async () => {
                try {
                    let tempSurveyQuestion = await getSurveyQuestionById(id);
                    setInitialValues({
                        ...tempSurveyQuestion,
                        options: tempSurveyQuestion.options.join(', '),
                        questionType: tempSurveyQuestion?.type || '',
                        metadata: tempSurveyQuestion?.metadata?.metadata || '',
                        metadataOptions: tempSurveyQuestion?.metadata?.options || '',
                        title: tempSurveyQuestion?.metadata?.title || ''
                    });
                } catch (error) {
                    snackbar.showSnackbar(error.message, 'error', true);
                }
            })();
        }
    }, []);

    async function createOrUpdateSurveyQuestion(values) {
        let tempSurveyQuestion = {
            question: values.question,
            questionFor: values.questionFor,
            type: values.questionType,
            options: values.options.split(','),
            metadata: {
                metadata: values.metadata,
                options: values.metadataOptions,
                title: values.title
            }
        };
        try {
            if (id === "create")
                await saveSurveyQuestions(null, tempSurveyQuestion);
            else
                await saveSurveyQuestions(id, tempSurveyQuestion);
            snackbar.showSnackbar("Survey question saved successfully", 'success', true);
            navigate("/general/surveyQuestions");
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', true);
        }
    }

    function goBack() {
        navigate("/general/surveyQuestions");
    }

    return (
        <>
            <ContentHeader title={"Survey Question"} />
            {((id !== "create" && initialValues.question !== "") || id === "create") &&
                formOptions?.metadataOptions &&
                <FormikForm
                    initialValues={initialValues}
                    fields={fields}
                    formOptions={formOptions}
                    validationSchema={surveyQuestionValidationSchema}
                    onSubmit={createOrUpdateSurveyQuestion}
                    onCancel={goBack}
                />}
        </>
    );
}

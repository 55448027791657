import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import ContentHeader from "components/ContentHeader";
import { useSnackbar } from "contexts";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import ClientSelect from "components/ClientSelect";
import CustomButton from "components/CustomButton";
import { getBatchById, saveBatch } from "services/batch";
import { DomainSelect } from "components/DomainSelect";

const batchValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    client: Yup.string().required("Required"),
    domain: Yup.string().nullable(),
    forPathway: Yup.boolean().required("Required"),
});

const InitialValues = {
    name: '', client: '', domain: null, forPathway: false,
};

const useStyles = makeStyles({
    fields: { margin: 20 },
    fieldContainer: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    copyIcon: {
        marginLeft: '8px',
        cursor: 'pointer'
    },
    buttonContainer: {
        margin: "30px 10px"
    }
});

export default function BatchEditor() {
    const classes = useStyles();
    const { id } = useParams();

    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [batch, setBatch] = useState(InitialValues);

    const handleSubmit = async () => {
        try {
          const valid = await batchValidationSchema.isValid(batch, { abortEarly: false});
          if (!valid) {
            snackbar.error("Seems like you have some invalid data!! Please check the student list");
            return;
          }

          if (id && id !== 'create') {
              await saveBatch(id, batch);
          } else {
              await saveBatch(null, batch);
          }

          snackbar.showSnackbar("Batch saved successfully !!");
          navigate(`/general/batches`);
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (id && id !== 'create') {
                    const batch = await getBatchById(id);

                    const newInitialValues = {
                        name: batch.name,
                        client: batch.client,
                        domain: batch.domain,
                        forPathway: batch?.forPathway || false,
                    };

                    setBatch(newInitialValues);
                }
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error', false);
            } finally {
                setLoading(false)
            }
        })();
    }, [id]);

    const goBack = () => navigate("/general/batches");

    return (<>
        <ContentHeader title="Batch Form" />
        <br />
        <Box>
          <TextField name="name" value={batch.name} placeholder="Batch Name"
            onChange={(e)=>{setBatch({...batch, name: e.target.value})}}
          />
          <br/><br/>
          <FormControlLabel control={<Checkbox name="name" checked={batch.forPathway} 
            onChange={(e)=>{setBatch({...batch, forPathway: e.target.checked})}}
          />} 
            label="For Pathway" />
          <br/><br/>
          <ClientSelect
            defaultValue={batch.client}
            onChange={(e)=>{setBatch({...batch, client: e._id})}}
          />
          <DomainSelect
            defaultValue={batch.domain}
            onChange={(e)=> {setBatch({...batch, domain: e?._id || null})}}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CustomButton
              type="submit"
              variant="contained"
              onClick={handleSubmit}
          >
              Save
          </CustomButton>
          <CustomButton
              variant="outlined"
              onClick={goBack}
          >
              Cancel
          </CustomButton>
        </Box>
    </>);
}
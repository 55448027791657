import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Session from 'utils/Session';

const useStyles = makeStyles({
    addIcon: {
        color: "#034AA6",
        fontSize: "2rem",
        borderRadius: 20,
        boxShadow: "0px 4px 4px rgb(0 0 0 / 15%)",
        cursor: "pointer",
    }
});

const DefaultQuestion = {
    question: '',
    expectedAnswer: '',
    allottedTime: '',
    added: true,
    resources: "",
    type: "",
    user: Session.userId
};

function AddButton({ show, push, questionsLength, setQuestionsLength = () => { } }) {
    const classes = useStyles();

    return show ? (
        <IconButton onClick={() => {
            setQuestionsLength(questionsLength + 1);
            push(DefaultQuestion)
        }}
            sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}
        >
            <AddIcon className={classes.addIcon} />
        </IconButton>
    ) : null;
}

export default AddButton;
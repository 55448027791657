import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as yup from "yup";
import Box from '@mui/material/Box';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import TemplateDetails from './TemplateDetails';
import QuestionSection from "./QuestionSection";
import AddButton from "./AddButton";
import GSheetImportDialog from 'components/dialogs/GSheetImportDialog';
import { useLoading, useSnackbar } from "contexts";

import {
    saveTemplateWithQuestions,
    getTemplateWithQuestionsById,
} from 'services';
import Session from 'utils/Session';
import { InterviewQuestionTypes } from './constants';
import { Typography } from '@mui/material';

const validationSchema = yup.object({
    questions: yup.array().of(
        yup.object().shape({
            question: yup.string().required("Question is required"),
            expectedAnswer: yup.string().when('type', (type, schema) => {
                return type === InterviewQuestionTypes[0]._id
                    ? schema.required("Answer is required")
                    : schema.optional();
            }),
            allottedTime: yup
                .number()
                .min(1, "Should be greater than 0")
                .max(10, "Should be less than 10 mins")
                .required("Must be greater than 1 and less than 10mins"),
            resources: yup.string().trim(),
            type: yup
                .string()
                .oneOf(InterviewQuestionTypes.map(type => type._id))
                .required("Question type is required"),
            hint: yup.string().trim(),
        }).required(),
    ).min(1, "Add questions to save").required(),
    template: yup.object().shape({
        name: yup.string().required("Name of the Interview is required"),
        type: yup.string().required("Type of interview is required"),
        domain: yup.string().required("Domain of Interview is required"),
        remarks: yup.string(),
        user: yup.string().required("Select a Client"),
    }),
});

const initialValues = {
    isCreator: true,
    questions: [],
    template: {
        name: "",
        domain: "",
        remarks: "",
        type: "",
        user: "",
    },
};

function TemplateEditor() {
    const snackbar = useSnackbar();
    const loading = useLoading();
    const navigate = useNavigate();
    let { id } = useParams();

    const [questionsLength, setQuestionsLength] = React.useState(0);
    const [openTemplateImport, setOpenTemplateImport] = React.useState(false);
    const [starTemplate, setStarTemplate] = React.useState(false);

    useEffect(() => {
        (async () => {
            if (id && id !== "create") {
                try {
                    loading.show();
                    let { interviewTemplate, questions } = await getTemplateWithQuestionsById(id);

                    let isStarTemplate = false;
                    questions = questions.map(question => {
                        question.resources = question.resources.join("\n");
                        question.allottedTime = question.allottedTime / 60;
                        if (question?.starAnswers?.length) isStarTemplate = true;
                        return question;
                    });

                    setStarTemplate(isStarTemplate);
                    setQuestionsLength(questions.length);
                    formik.setFieldValue("questions", questions);
                    formik.setFieldValue("template", interviewTemplate);
                    formik.setFieldValue("template.remarks", interviewTemplate?.remarks || "");
                    formik.setFieldValue("isCreator", true);
                } catch (error) {
                    console.log(error);
                    snackbar.showSnackbar("Unable to load the template", "error");
                } finally {
                    loading.hide();
                }
            } else {
                formik.setFieldValue("questions", initialValues.questions);
                formik.setFieldValue("template", initialValues.template);
            }
        })();
    }, [id]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {
                loading.show();
                if (values?.template?.remarks?.trim() === "")
                    delete values.template.remarks;

                values.questions = values.questions.map(question => {
                    if (question?.resources?.trim() === "")
                        delete question.resources
                    else
                        question.resources = question?.resources?.split(',');
                    return question;
                });

                await saveTemplateWithQuestions(
                    id !== "create" ? id : null,
                    values.template,
                    values.questions.map(question => {
                        if (id !== "create") {
                            question['interviewTemplate'] = id;
                        }
                        if (!(question.added || question.updated || question.deleted)) question.updated = true;
                        question.allottedTime = question.allottedTime * 60;
                        question['user'] = values.template.user || Session.userId;
                        return question
                    }),
                );

                snackbar.showSnackbar('Saved template successfully', 'success', true);
                navigate('/templates/inprep');
            } catch (error) {
                console.log(error);
                snackbar.showSnackbar(
                    error.message || 'Unable to save template with questions',
                    'error',
                    true
                );
            } finally {
                formik.setFieldValue('questions',
                    formik.values.questions.map(question => {
                        delete question.added;
                        delete question.updated;
                        delete question.deleted;
                        question.allottedTime = question.allottedTime / 60;
                        return question;
                    })
                );
                loading.hide();
            }
        },
    });

    const handleDeleteQuestion = (index, removeQuestion) => {
        if (!formik.values.questions[index].hasOwnProperty('added'))
            formik.setFieldValue(`questions[${index}]`, {
                ...formik.values.questions[index],
                deleted: true
            });
        else
            removeQuestion(index);
        setQuestionsLength(questionsLength - 1);
    };

    const setQuestionUpdated = (e, index, field) => {
        if (!formik.values.questions[index].hasOwnProperty('updated')) {
            if ((typeof (e.target.value) === 'string' &&
                formik.values.questions[index][field] !== e.target.value.trim()) ||
                (typeof (e.target.value) === 'number' &&
                    formik.values.questions[index][field] !== e.target.value))
                formik.setFieldValue(`questions[${index}][updated]`, true);
        }
    }

    const onClose = () => {
        setOpenTemplateImport(false);
    }

    const onSuccess = (importedQuestions) => {
        const questions = importedQuestions?.map((record) => {
            const keysMap = Object.keys(record).reduce((acc, key) => {
                acc[key.trim()] = key;

                return acc;
            }, {});
            return {
                question: record[keysMap['Question']]?.trim(),
                hint: record[keysMap['Hint']]?.trim(),
                resources: record[keysMap['Resource']]?.trim(),
                expectedAnswer: record[keysMap['Answer']],
                type: record[keysMap['Type(t/s)']]?.trim() === 's' ? 'situational' : 'technical',
                allottedTime: +record[keysMap['Time(min)']]?.trim() || 1,
                user: Session.userId,
            };
        });

        if (questions.length) {
            setQuestionsLength(questions.length);
            formik.setFieldValue("questions", questions);
            snackbar.success(`Imported ${questions.length} questions successfully`);
        } else {
            snackbar.warn("No questions found to import");
        }
        onClose();
    }

    return (<>
        <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
            <Box position="relative">
                <ContentHeader
                    title={(!id || id === "create") ? "Create Interview Template" : "Interview Template"}
                >
                    {id === "create" && (<CustomButton
                        variant="outlined"
                        onClick={() => setOpenTemplateImport(true)}
                    >
                        Import from Google Sheet
                    </CustomButton>
                    )}
                </ContentHeader>
                <TemplateDetails
                    formik={formik}
                    questionsLength={questionsLength}
                    starTemplate={starTemplate} />
            </Box>

            {starTemplate && <Typography color={'red'}>
                This template contains star questions so you cannot edit it,
                if you want to edit then visit the STAR Template section
            </Typography>}

            <FormikProvider value={formik}>
                <FieldArray name="questions" >
                    {({ remove, push }) => (
                        <div
                            style={{
                                marginTop: 8,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >

                            {formik.values.questions.map((question, index) => (
                                !question?.deleted ? (
                                    <QuestionSection
                                        key={index}
                                        index={index}
                                        question={question}
                                        templateId={id}
                                        handleChange={formik.handleChange}
                                        setQuestionUpdated={setQuestionUpdated}
                                        handleDeleteQuestion={handleDeleteQuestion}
                                        removeQuestion={remove}
                                        readOnly={!formik.values.isCreator || starTemplate}
                                        formik={formik}
                                    />
                                ) : null)
                            )}
                            <AddButton
                                push={push}
                                show={formik.values.isCreator}
                                type="questions"
                                questionsLength={questionsLength}
                                setQuestionsLength={setQuestionsLength}
                            />
                        </div>
                    )}

                </FieldArray>
            </FormikProvider>
        </form>
        {id === "create" &&
            <GSheetImportDialog
                open={openTemplateImport}
                title="Import Template from Google Sheet"
                onSuccess={onSuccess}
                onClose={onClose}
                sampleSheetUrl="https://docs.google.com/spreadsheets/d/1hcf4ClSCAcQWbxYWjGyREjkVY5hAtqDOjXQWxDgARRY/edit#gid=0"
            />
        }
    </>)
}

export default TemplateEditor;
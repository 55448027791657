import { useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import FieldSet from "./FieldSet";
import useField from "./useField";

const useStyles = makeStyles({
    editor: {
        "&[contenteditable=true]:empty:before": {
            content: 'attr(placeholder)',
            pointerEvents: 'none',
            display: 'block',
            color: 'grey',
            backgroundColor: 'transparent'
        }
    }
});

export default function ContentEditable({
    index, readOnly, questions, defaultValue,
}) {
    const classes = useStyles();
    const editorRef = useRef();
    const { handleChange, error, config } = useField({ index, name: 'question' });

    const handleInput = () => {
        const inputValue = editorRef.current.textContent;
        handleChange({
            target: {
                value: inputValue,
                name: 'question'
            }
        });
    };

    useEffect(() => {
        if (questions && questions[index]) {
            editorRef.current.textContent = questions[index];
        } else {
            editorRef.current.textContent = defaultValue || "";
        }
    }, [questions, index, defaultValue]);

    useEffect(() => {
        const handlePaste = (e) => {
            e.preventDefault();
            const text = e.clipboardData.getData('text/plain');
            document.execCommand('insertText', false, text);
        };
        
        const editor = editorRef.current;
        editor.addEventListener("paste", handlePaste);
        
        return () => editor.removeEventListener("paste", handlePaste);
    }, []);

    return (
        <FieldSet readOnly={readOnly} label={`Q${index + 1}*`} error={error}>
            <div
                contentEditable={!readOnly}
                ref={editorRef}
                name={'question'}
                placeholder={config.placeholder}
                className={classes.editor}
                onInput={handleInput}
                suppressContentEditableWarning={true}
                style={{
                    width: "100%",
                    height: 60,
                    overflowY: 'scroll',
                    padding: 8,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    outline: 'none'
                }}
            >
            </div>
        </FieldSet>
    );
}
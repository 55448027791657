import React from 'react';
import ReactJson from 'react-json-view'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Divider } from '@mui/material';

function EntityViewDialog({
  open,
  onClose,
  entityId,
  getEntity,
  title = "View",
  edit = false,
}) {
  const [entity, setEntity] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [edited, setEdited] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      setEntity({});
      (async () => {
        try {
          const data = await getEntity(entityId);

          setEntity(data);
        } catch (err) {
          setEntity({ message: err.message });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [open, entityId, getEntity]);

  const onEdit = ({ updated_src }) => {
    setEdited(true);
    setEntity(updated_src);
    return true;
  };

  const onAdd = ({ updated_src }) => {
    setEntity(updated_src);
    return true;
  };

  const onDelete = ({ updated_src }) => {
    setEdited(true);
    setEntity(updated_src);
    return true;
  };

  return (
    <Dialog
      fullScreen={!!edit}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: 600 }}>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress variant="indeterminate" color="success" />
          </div>
        ) : (
          <ReactJson
            collapsed={edit}
            enableClipboard={edit}
            displayDataTypes={edit}
            displayObjectSize={edit}
            iconStyle="square"
            src={JSON.parse(JSON.stringify(entity))}
            name={title}
            collapseStringsAfterLength={50}
            theme="apathy:inverted"
            onEdit={edit ? onEdit : undefined}
            onAdd={edit ? onAdd : undefined}
            onDelete={edit ? onDelete : undefined}
          />
        )}
      </DialogContent>
      <DialogActions>
        {(edit && edited) && (
          <Button
            onClick={(e) => onClose(e, entity)}
            variant='contained'
          >
            Save Changes
          </Button>
        )}
        <Button
          onClick={onClose}
          variant='outlined'
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EntityViewDialog;
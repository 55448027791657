import React from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import ContentShell from "components/ContentShell";
import Feedbacks from "components/lists/Feedbacks";
import OptionEditor from "components/forms/OptionEditor";
import Options from "components/lists/Options";
import Referrals from "components/lists/Referrals";
import SEPResumeReferrals from "components/lists/SEPResumeReferrals";
import SurveyQuestionEditor from "components/forms/SurveyQuestionEditor";
import SurveyQuestions from "components/lists/SurveyQuestions";
import WebhookEvents from "components/lists/WebhookEvents";
import Blogs from "components/lists/Blogs";
import BlogEditor from "components/forms/BlogEditor";
import Batches from "components/lists/Batches";
import BatchEditor from "components/forms/BatchEditor";
import Subscribers from "components/lists/Subscribers";
import JobDescription from "components/lists/JobDescription";
import JobDescriptionEditor from "components/forms/JobDescriptionEditor";

export default function General() {
    return (
        <Routes>
            <Route path="/" element={<GeneralLayout />}>
                <Route index element={<Navigate to='options' />} />
                <Route
                    path="options"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Options />
                        </React.Suspense>
                    }
                />
                <Route
                    path="options/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <OptionEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path="feedbacks"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Feedbacks />
                        </React.Suspense>
                    }
                />
                <Route
                    path="surveyQuestions"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SurveyQuestions />
                        </React.Suspense>
                    }
                />
                <Route
                    path="surveyQuestions/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SurveyQuestionEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path='referrals'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Referrals />
                        </React.Suspense>
                    }
                />
                <Route
                    path='sep-resume-referral'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SEPResumeReferrals />
                        </React.Suspense>
                    }
                />
                <Route
                    path='webhook-events'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <WebhookEvents />
                        </React.Suspense>
                    }
                />

                <Route
                    path='blogs'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Blogs />
                        </React.Suspense>
                    }
                />

                <Route
                    path='blogs/:id'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <BlogEditor />
                        </React.Suspense>
                    }
                />

                <Route
                    path='batches'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Batches />
                        </React.Suspense>
                    }
                />

                <Route
                    path='batches/:id'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <BatchEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path="subscribers"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Subscribers />
                        </React.Suspense>
                    }
                />
                <Route
                    path="jobDescription"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <JobDescription />
                        </React.Suspense>
                    }
                />
                <Route
                    path='jobDescription/:id'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <JobDescriptionEditor />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

const SideBarLinks = [
    {
        label: "Options",
        path: 'options',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Feedbacks",
        path: 'feedbacks',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Survey Questions",
        path: 'surveyQuestions',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Mock Referrals",
        path: 'referrals',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES"],
    },
    {
        label: "SEP Resume Referrals",
        path: 'sep-resume-referral',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES"],
    },
    {
        label: "Webhook Events",
        path: 'webhook-events',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES"],
    },
    {
        label: "Blogs",
        path: 'blogs',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES"],
    },
    {
        label: "Batches",
        path: 'batches',
        allowedRoles: ["ADMIN", "PRODUCT"],
    },
    {
        label: "Subscribers",
        path: 'subscribers',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Job Description",
        path: 'jobDescription',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES"],
    },
];

function GeneralLayout() {
    return (
        <ContentShell sideBarLinks={SideBarLinks}>
            <Outlet />
        </ContentShell>
    );
}
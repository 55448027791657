import Client from "./_client";

export const getSignedUploadUrl = async (file, saveLocation, toPublic, uniqueName) => {
  const result = await new Client({
    path: "/uploads/generateSignedUploadUrl",
    payload: {
      contentType: file.type,
      fileName: file.name,
      location: saveLocation,
      toPublic,
      uniqueName
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to generate the upload url."
    );
  } else {
    return result.data;
  }
};

export const uploadFile = async (file, _blob, saveLocation, toPublic, uniqueName=true) => {
  const { url } = await getSignedUploadUrl(file, saveLocation, toPublic, uniqueName);

  let binary = atob(_blob.split(",")[1]);
  let array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  let blobData = new Blob([new Uint8Array(array)], { type: file.type });

  await fetch(url, {
    method: "PUT",
    body: blobData,
  });

  return url.split("?")[0];
};

import React from 'react';
import { useField } from "formik";
import { styled, makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import { Checkbox, FormControlLabel } from '@mui/material';

const useStyles = makeStyles(theme => ({
  select: {
    minWidth: 200,
    background: 'white',
    color: grey[500],
    fontWeight: 200,
    fontSize: 12,
    borderStyle: 'none',
    borderWidth: 2,
    borderRadius: 12,
    paddingLeft: 24,
    paddingTop: 16,
    paddingBottom: 16,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    "&:focus": {
      borderRadius: 12,
      background: 'white',
      borderColor: grey[100]
    },
  },
  icon: {
    color: grey[900],
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    maxHeight: 150, overflowY: "auto",
    padding: "14px 20px",
    background: '#E4E3E8',
    "& li": {
      fontWeight: 400,
      borderBottom: "0.5px solid black",
      padding: "4px 0px 4px 0px",
      fontSize: 12,
      fontFamily: "Inter"
    },
    "& li:hover": {
      background: '#E4E3E8',
      borderBottom: "1px solid #02569D",
      fontWeight: "500"
    },
    "& li.Mui-selected": {
      background: '#E4E3E8',
    },
    "& li.Mui-selected:hover": {
      background: '#E4E3E8',
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: "6px",
    position: 'relative',
    backgroundColor: "#E4E3E8",
    border: 'none',
    fontSize: "12px",
    padding: '6px 16px',
    fontFamily: "Inter",
    fontWeight: "500",
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: "6px",
      borderColor: '#02569D',
      boxShadow: '0 0 0 0.1rem #02569D',
    },
  },
}));

export function SimpleSelect({ options, helperText, value, ...props }) {
  const classes = useStyles();

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  const [selected, setSelected] = React.useState([]);

  React.useEffect(()=>{
    setSelected(value || []);
  }, [value]);

  return (
    <Box sx={{ minWidth: 120 }} >
      <FormControl
        fullWidth
        disabled={!options?.length}
        size="small"
        variant='outlined'
        error={props.error}
      >
        <InputLabel
          id="simple-select-label"
          style={{ fontSize: 12, fontWeight: "normal" }}
        >
          {props.label}
        </InputLabel>
        <Select
          labelId="simple-select-label"
          {...props}
          onChange={e => props.onChange(e)}
          value={selected}
          label={props.label}
          input={<BootstrapInput />}
          MenuProps={menuProps}
          classes={{ root: classes.select }}
          error={props.error}
        >
          {
            options?.map((option) => (
              <MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
            ))
          }
        </Select>
        <FormHelperText error >{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export function FormSelect({ options, ...props }) {
  const classes = useStyles();

  const [field, meta] = useField(props);

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return (
    <Box sx={{ minWidth: 120, px: 1, my: 1, }} width="inherit">
      <FormControl
        fullWidth
        disabled={!options?.length}
        size="small"
        variant='outlined'
      >
        <InputLabel
          id="simple-select-label"
          style={{ fontSize: 12, fontWeight: "normal" }}
        >
          {props.label}
        </InputLabel>
        <Select
          labelId="simple-select-label"
          {...props}
          inputProps={{ ...field }}
          value={props.value}
          label={props.label}
          error={!!(meta.touched && meta.error)}
          MenuProps={menuProps}
          classes={{ root: classes.select }}
          input={<BootstrapInput />}
        >
          <MenuItem value={""}>Select</MenuItem>
          {
            options.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.name}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export function CheckboxOptions({ options, value, onChange, disabled=false }) {
  return (
    <FormControl component="fieldset">
      {options.map(option => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              checked={value === option}
              onChange={() => onChange(option)}
              name={option}
              disabled={disabled}
            />
          }
          label={option}
        />
      ))}
    </FormControl>
  );
}
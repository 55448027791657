import classnames from "classnames";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import AppShell from "./AppShell";
import Sidebar from "./Sidebar";

const useStyles = makeStyles({
    contentRoot: {
        flex: "auto",
        marginLeft: "0px",
        marginTop: "56px",
        width: "0px",
        padding: 16,
        paddingLeft: "216px",
    },
    withoutNavbar: {
        paddingLeft: "16px !important"
    }
});

export default function ContentShell({ withoutNavbar, children, sideBarLinks }) {
    const classes = useStyles();

    return (
        <AppShell>
            <Sidebar sideBarLinks={sideBarLinks} />
            <Box
                className={classnames([
                    classes.contentRoot,
                    withoutNavbar && classes.withoutNavbar
                ])}
            >
                {children}
            </Box>
        </AppShell>
    );
}
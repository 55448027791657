import * as Yup from 'yup';

export const surveyQuestionValidationSchema = Yup.object().shape({
    question: Yup.string().required('Required'),
    questionFor: Yup.string().required('Required'),
    questionType: Yup.string().required('Required'),
    options: Yup.string().required('Required'),
    metadata: Yup.string().required('Required'),
    metadataOptions: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
});

export const fields = [
    {
        name: 'question',
        type: 'text',
        label: 'Question'
    },
    {
        name: 'questionFor',
        type: 'select',
        label: 'Question For'
    },
    {
        name: 'questionType',
        type: 'select',
        label: 'Question Type'
    },
    {
        name: 'options',
        type: 'text',
        label: "Add ',' separated options"
    },
    {
        name: 'metadata',
        type: 'text',
        label: 'Metadata'
    },
    {
        name: 'metadataOptions',
        type: 'select',
        label: 'Metadata Options'
    },
    {
        name: 'title',
        type: 'text',
        label: 'Title'
    },
];
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { isEqual } from 'lodash';

function ClearFilters({ BaseFilters, filters, setFilters, ...props }) {
  const [applied, setApplied] = React.useState(false);
  const clearFilters = () => setFilters(BaseFilters);

  React.useEffect(() => {
    if (filters && BaseFilters)
      setApplied(!isEqual(filters, BaseFilters));
  }, [filters, BaseFilters]);

  return applied ? (
    <IconButton {...props} onClick={clearFilters} title="Clear Filters">
      <Badge color="secondary" variant="dot" >
        <FilterListOffIcon color="primary" />
      </Badge>
    </IconButton>
  ) : null;
}

export default ClearFilters;
import Box from "@mui/material/Box";
import Header from "./Header";

export default function AppShell({ children }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                height: "calc(100vh-0px)",
                marginTop: "0px",
            }}
        >
            <Header />
            {children}
        </Box>
    );
}
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

export default function MultipleSelect({
  placeholder, options, label, values, setValues, required
}) {
  const handleChange = (event) => {
    const { target: { value } } = event;

    setValues(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl fullWidth size="small" required={required}>
      <InputLabel id="multiple-label">{label}</InputLabel>
      <Select
        multiple
        labelId="multiple-label"
        id="multiple-name"
        value={values}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem key={option} value={i}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const languifyIsCredentials = Symbol("languifyIsCredentials");

class Session {
  userId = "";
  user = ""
  accessToken = "";
  accessTokenExpiry = Date.now();
  refreshToken = "";
  refreshTokenExpiry = Date.now();
  roles = [];
  permissions = {};

  _setUser(user) {
    this.userId = user._id;
    this.user = user;
    if (this.user.client) {
      this.client = user.client;
      this.clientId = user.client._id;
    }
    this.redirectUrl = this.user?.metadata?.redirectUrl ||
      this.client?.metadata?.redirectUrl;
    this.role = user?.role || [];
    this.permissions = user?.role?.permissions;
  }

  _unsetUser() {
    this.userId = "";
    this.user = null;
    this.clientId = "";
    this.client = null;
    this.roles = [];
  }

  getUser() {
    return this.user || {};
  }

  getRole() {
    return this.role || {};
  }

  onCreateSession({ user, tokens }) {
    this._setUser(user);
    this.accessToken = tokens.accessToken;
    this.accessTokenExpiry = new Date(tokens.accessExpireAt).getTime();
    this.refreshToken = tokens.refreshToken;
    this.refreshTokenExpiry = new Date(tokens.refreshExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", true.toString());
    localStorage.setItem("languifyRefreshTokenExpiry", this.refreshTokenExpiry.toString());
    localStorage.setItem("languifyRefreshToken", this.refreshToken);
  }

  onRestoreSession({ accessToken, accessExpireAt, user }) {
    this._setUser(user);
    this.accessToken = accessToken;
    this.accessTokenExpiry = new Date(accessExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", true.toString());
  }

  clearSession() {
    try {
      this.accessToken = "";
      this.accessTokenExpiry = Date.now();
      this.refreshToken = "";
      this.refreshTokenExpiry = Date.now();
      localStorage.removeItem("languifyIsLoggedIn", false.toString());
      localStorage.removeItem("languifyRefreshTokenExpiry");
      localStorage.removeItem("languifyRefreshToken");
      this.unRememberMe();
      this._unsetUser();
    } catch (error) {
      console.log(error);
    }
  }

  isLoggedIn() {
    return JSON.parse(localStorage.getItem("languifyIsLoggedIn"));
  }

}

export const session = new Session();

export default session;
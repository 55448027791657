import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FileUploadInput from 'components/FileUploadInput';
import AddButton from './AddButton';
import ContentEditableDiv from './ContentEditableDiv';
import DeleteButton from './DeleteButton';
import Question from './Question';

export default function Group({ index, group, section, onDelete }) {
  const [questions, setQuestions] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  const addNewQuestion = () => {
    const newQuestions = [...questions, {}]

    setQuestions(newQuestions);

    group.questions = newQuestions;
  };

  const deleteQuestion = (index) => {
    const newQuestions = [
      ...questions.slice(0, index), ...questions.slice(index + 1)
    ];

    setQuestions(newQuestions);

    group.questions = newQuestions;
  };

  const onInstructionChange = (e) => {
    group.instrution = e.target.innerHTML;
  };

  return (
    <Box p={1} border="1px solid grey" borderRadius={1} my={1} ml={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>
          Group {index + 1} ({group?.type?.toUpperCase()})
        </Typography>
        <Box display="flex">
          <AddButton onClick={addNewQuestion}>Add Question</AddButton>
          <DeleteButton
            index={index}
            onDelete={onDelete}
            text={`Delete group ${index + 1} (Part ${group?.part + 1}) ?`}
          />
        </Box>
      </Box>
      <Divider />
      {["scq", "mcq", "mark_the_para", "fib"].includes(group.type) && (
        <ContentEditableDiv
          containerProps={{ mx: 1 }}
          required
          label="Instruction"
          placeholder="Add the Intruction for the group here..."
          onInput={onInstructionChange}
        />
      )}
      {group.type === "mark_the_para" && (
        <TextField
          fullWidth
          required
          inputProps={{ minlen: 0, maxlen: 1000 }}
          size="small"
          label="Options"
          placeholder="Add comma options... (e.g. A,B,C,D,E,F,G)"
          onInput={(e) => {
            group.options = e.target.value.split(",");
            setOptions(group.options);
          }}
        />
      )}
      {((
        section === "listening" && group.type === "fib") ||
        (section === "writing" && group.type === "written")
      ) && (
          <>
            <Typography variant="body2" ml={1} mt={1}>
              Image for the questions(Optional)
            </Typography>
            <FileUploadInput
              label="Upload Image"
              toPublic
              uploadLocation={"images/mock"}
              accept={["image/*"]}
              onUpload={({ url, name }) => group.image = url}
            />
          </>
        )}
      <br /><br />
      {questions.map((question, i) => (
        <Question
          key={i}
          index={i}
          type={group.type}
          question={question}
          group={group}
          onDelete={deleteQuestion}
          options={options}
        />
      ))}
    </Box>
  );
}

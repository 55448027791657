import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Box from "@mui/material/Box";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Form, Formik } from "formik";
import * as Yup from 'yup';

import FormTextField from "components/FormTextField";
import { useSnackbar } from "contexts";
import { addCreditToClient, updateClientCredits } from 'services';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';


export function CreditFormDialog({ 
  open = false, creditType, clientId, onClose, onCreditAddition,
  updateCredit = false, editData
}) {
    return (
        <Dialog fullScreen={false}
            open={open}
            maxWidth="lg"
            aria-label='credit-form-dialog'
        >
            <DialogTitle id="responsive-dialog-title">
            {
              !updateCredit ? 'Add Credit' : 'Update Credit'
            }
                
            </DialogTitle>
            <DialogContent>
                <CreditForm creditType={creditType} onClose={onClose} clientId={clientId} 
                  onCreditAddition={onCreditAddition} updateCredit={updateCredit} editData={editData}
                />
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    submitButton: {
        marginRight: "0.5rem",
        marginLeft: "auto"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        width: "75vw",
        maxWidth: "500px",
    },
    action: {
        display: "flex",
        flexDirection: "row",
        marginTop: "1rem"
    }
}))

const initialValues = {
    initialAmount: '',
    description: '',
    expiresOn: '',
};

const creditValidationSchema = Yup.object().shape({
    initialAmount: Yup.number()
        .min(1, 'Should be than zero')
        .notOneOf([0], 'Cannot be zero')
        .required('Amount field is required.'),
    description: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required("Description field is required."),
    expiresOn: Yup.date().required("Expiry field is required."),
})

function CreditForm({ 
  creditType, onClose, clientId, onCreditAddition, updateCredit = false, editData 
}) {
    const classes = useStyles()
    const snackbar = useSnackbar();
    const today = dayjs();

    return <Formik
        validationSchema={creditValidationSchema}
        initialValues={updateCredit? editData : initialValues}
        onSubmit={async (values) => {
            snackbar.info("Creating credit...")
            if(updateCredit){
              const data = {
                expiresOn : values.expiresOn,
                description: values.description
              }
              await updateClientCredits(values._id, data);
              snackbar.success("Credit Updated!")
            }else{
              await addCreditToClient({ ...values, user: clientId, type: creditType });
              snackbar.success("Credit Added!")
            }
            onCreditAddition();
            onClose();
        }}

    >
        {({ setFieldValue, validateField, errors }) => (
            <Form className={classes.form}>
                <FormTextField disabled={updateCredit} type="number" required={true} name={"initialAmount"} label={"Initial Amount"} size="medium" />
                <FormTextField name={"description"} label={"Description"} size="medium" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Validity" onChange={(value) => setFieldValue("expiresOn", value.toISOString())} onClose={() => validateField("expiresOn")}
                        minDate={today}
                        defaultValue={today}
                        slotProps={
                            {
                                textField: {
                                    helperText: errors.expiresOn,
                                    error: errors.expiresOn ? true : false
                                }
                            }
                        }
                    />
                </LocalizationProvider>
                <Box className={classes.action}>
                    <Button variant='outlined'
                        color={'primary'}
                        type='submit'
                        className={classes.submitButton}
                    >
                        Submit
                    </Button>
                    <Button variant='contained' color={'error'} onClick={onClose}>Close</Button>
                </Box>
            </Form>
        )}
    </Formik>

}

export default CreditFormDialog
export const ROLES = Object.freeze({
  ADMIN: "ADMIN",
  CLIENT: "CLIENT",
  INSTRUCTOR: "INSTRUCTOR",
  STUDENT: "STUDENT",
  EXTENSION_USER: "EXTENSION_USER",
  SEP_USER: "SEP_USER",
  BETA_USER: "BETA_USER",
  SALES: "SALES",
  SEP_CLIENT: "SEP_CLIENT",
  PRODUCT: "PRODUCT",
  IELTS_USER: "IELTS_USER",
  TOEFL_USER: "TOEFL_USER",
  AMBASSADOR_USER: "AMBASSADOR_USER",
  MOCK_USER: "MOCK_USER",
  SEP_RESUME: "SEP_RESUME",
});

export const NonInternalUserRoles = new Set([
  ROLES.ADMIN,
  ROLES.CLIENT,
  ROLES.SEP_CLIENT,
  ROLES.INSTRUCTOR,
  ROLES.STUDENT,
  ROLES.EXTENSION_USER,
  ROLES.SEP_USER,
  ROLES.BETA_USER,
  ROLES.IELTS_USER,
  ROLES.TOEFL_USER,
  ROLES.AMBASSADOR_USER,
  ROLES.SEP_RESUME,
]);

export const InternalUserRoles = new Set([
  ROLES.PRODUCT,
  ROLES.SALES,
]);

export * from "./BasePermissions";
import Client from "./_client";

export const getSEPAttempts = async (filters = {}) => {
  let url = '/sepAttempt?';

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "unable to fetch sepAttempts!");

  return result?.data || [];
}

export const getMockAttempts = async (filters = {}) => {
  let url = '/mockAttempt?';

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to fetch attempts!");

  return result?.data || [];
}

import React from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";

import ContentShell from "components/ContentShell";
import InterviewAnalytics from "components/lists/InterviewAnalytics";
import ExtensionAnalytics from "components/lists/ExtensionAnalytics";
import SEPAnalytics from "components/lists/SEPAnalytics";
import MockAnalytics from "components/lists/MockAnalytics";

export default function Analytics() {
    return (
        <Routes>
            <Route path="/" element={<AnalyticsLayout />}>
                <Route index element={<Navigate to="interview" />} />
                <Route path='interview' element={<InterviewAnalytics />} />
                <Route
                    path="extension"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <ExtensionAnalytics />
                        </React.Suspense>
                    }
                />
                <Route
                    path="sep"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SEPAnalytics />
                        </React.Suspense>
                    }
                />

                <Route
                    path="mock"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <MockAnalytics />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

const SideBarLinks = [
    {
        label: "Interview",
        path: 'interview',
        allowedRoles: ["ADMIN", "SALES", "PRODUCT"],
    },
    {
        label: "SEP",
        path: 'sep',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "MOCK",
        path: 'mock',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
];

function AnalyticsLayout() {
    return (
        <ContentShell sideBarLinks={SideBarLinks}>
            <Outlet />
        </ContentShell>
    );
}
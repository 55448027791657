import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import AppShell from 'components/AppShell';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        width: 245
    }
});

function NotFound() {
    const classes = useStyles();

    return (
        <AppShell>
            <Box className={classes.container}
            >
                <Typography
                    variant='body1'
                    color='#2C3840'
                    fontWeight={600}
                    mb='3em'
                >
                    The page you are looking for, is Not Found!!
                </Typography>
                <Box textAlign='center'>
                    <Typography
                        variant='h5'
                        color='#D8D8D8'
                        fontWeight={600}
                        mb={-5}
                    >
                        Error
                    </Typography>
                    <Typography
                        variant='h1'
                        color='#D8D8D8'
                        fontFamily='Sora'
                        fontSize={300}
                    >
                        404
                    </Typography>
                </Box>
                <Link style={{ textDecoration: 'none' }} to="/users">
                    <CustomButton
                        variant='contained'
                        className={classes.button}
                    >
                        Go Back to Homepage
                    </CustomButton>
                </Link>
            </Box>
        </AppShell>
    );
}

export default NotFound;

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';

function ShowCommentDialog({
    open,
    onClose,
    onSuccess,
    comment,
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Comment :
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 380, maxWidth: 500 }}>
                <DialogContentText>
                    {comment}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default ShowCommentDialog;
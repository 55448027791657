import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CustomButton from './CustomButton';
import { SimpleTextField } from './FormTextField';

function CustomChipArray({
    label,
    form = true,
    InputProps = {},
    initialValues = [],
    onValuesChange = () => { },
    onAdd = () => { },
    onDelete = () => { },
    buttonText = "Add",
    readOnly = false,
    error = false,
}) {
    const [value, setValue] = React.useState("");
    const [values, setValues] = React.useState(initialValues);

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const handleDeleteItem = (index) => {
        const newValues = [...values]
        newValues.splice(index, 1);
        onDelete(index, values[index]);
        setValues(newValues);
        onValuesChange(newValues);
    };

    const handleAddItem = (valueToAdd) => {
        const newValues = [...values, valueToAdd];
        onAdd(valueToAdd);
        setValues(newValues);
        onValuesChange(newValues);
    };

    const handleAddItemAndResetText = () => {
        handleAddItem(value);
        setValue("");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleAddItemAndResetText();
    }

    React.useEffect(() => {
        setValues(initialValues);
    }, []);

    return (
        <fieldset
            style={{
                paddingTop: 0,
                padding: 4,
                background: "#e4e3e8",
                borderRadius: 4,
                borderColor: Boolean(error) ? "red" : '#C4C4C4',
            }}
            disabled={readOnly}
        >
            {!!label && <legend style={{ fontSize: 12 }}>{label}</legend>}
            <Box
                display="flex"
                alignItems="center"
                component={form ? "form" : "div"}
                onSubmit={handleSubmit}
                autocomplete="on"
            >
                <SimpleTextField
                    fullWidth
                    required
                    size="small"
                    {...InputProps}
                    value={value}
                    onChange={handleChange}
                    sx={{ m: 0, border: "1px solid grey" }}
                />
                <CustomButton
                    type="submit"
                    variant="outlined"
                    disabled={value.trim() === ""}
                    size="small"
                >
                    {buttonText}
                </CustomButton>
            </Box>
            <Stack direction="row" flexWrap="wrap" pt={1}>
                {values.map((itemValue, index) => (
                    <Chip
                        size="small"
                        variant="outlined"
                        key={index}
                        label={itemValue}
                        sx={{ m: 0.2 }}
                        onDelete={() => handleDeleteItem(index)}
                    />
                ))}
            </Stack>
            <span style={{
                fontSize: 10,
                color: "red",
                marginLeft: 8,
                marginTop: -4,
            }}>
                {!!error && error}
            </span>
        </fieldset>
    );
}

export default CustomChipArray;

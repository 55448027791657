import React from 'react';
import * as Yup from 'yup';
import ContentHeader from 'components/ContentHeader';
import _ from 'lodash';

import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, FormControl, FormHelperText, MenuItem, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useLoading, useSnackbar } from 'contexts';
import { TagCategory } from 'components/lists/Tags';
import { createTag, getTagById, updateTag } from 'services/tagService';

const sx = {
  textField: {
    width: '350px'
  },
  textArea: {
    width: '500px', padding: '10px', fontSize: '18px'
  },
  errorField: {
    fontSize: '14px'
  }
}

const validationSchema = Yup.object({
  name: Yup.string().required('Tag Name required'),
  category: Yup.string().required('category is required'),
});

const updateValidationSchema = Yup.object({
  category: Yup.string().required('Category is required'),
  name: Yup.string()
    .required('Tag Name required')
    .matches(/^[^,]*$/, 'Tag cannot include a comma'),
});


function TagEditor() {
  const { id } = useParams();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [tag, setTag] = React.useState(null);

  const create = React.useMemo(() => id === 'create', [id]);

  React.useEffect(() => {
    (async () => {
      try {
        loading.show();
        if (!create) {
          const result = await getTagById(id);
          setTag(result);
        }
        else {
          setTag({});
        }
      } catch (error) {
        snackbar.showSnackbar(error.message, 'error', false);
      } finally {
        loading.hide();
      }
    })();
  }, [id]);

  const handleSubmit = async (data) => {
    try {
      loading.show();
      if (!create) {
        await updateTag(tag._id, data);
        navigate('/pathways/tag');
        snackbar.success('Tag Updated', false);
      }
      else {
        let tags = data?.name?.split(',') || [];
        tags = _.uniq(tags);

        const promises = tags.map(tag => createTag({ name: tag, category: data.category }));
        const errors = [], results = [];

        await Promise.all(promises.map(promise => promise.then((res) => {
          results.push(res);
        }).catch((err) => {
          errors.push(err);
        })));

        if (!errors.length) {
          snackbar.showSnackbar('Tags Added', 'success', false);
          navigate('/pathways/tag');
        } else {
          let errorSummary = <>Failed {errors.length} | Successfully added {results.length} <br /> {errors.join(' | ')}</>;

          snackbar.showSnackbar(errorSummary, 'error', false);
        }
      }
    } catch (error) {
      snackbar.showSnackbar(error.message, 'error', false);
    } finally {
      loading.hide();
    }
  }

  return (
    <>
      <ContentHeader title={create ? 'Create Tags' : 'Edit Tag'} />
      <br />
      <Box>
        <Formik
          initialValues={{
            name: tag?.name || '',
            category: tag?.category || ''
          }}
          enableReinitialize
          validationSchema={(create) ? validationSchema : updateValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange }) => (
            <Form>
              <Box sx={sx.formFields}>
                <FormControl sx={sx.formControl} required>
                  <Field
                    as={TextField}
                    name="category"
                    select
                    label="Category"
                    sx={sx.textField}
                    error={touched.category && !!errors.category}
                    helperText={touched.category && errors.category}
                    onChange={handleChange}
                    disabled={!create}
                    FormHelperTextProps={{ style: sx.errorField }}
                  >
                    {[
                      { _id: 'company', name: 'Company' },
                      { _id: 'jobRole', name: 'Job Role' },
                      { _id: 'subDomain', name: 'Sub Domain', disabled: true },
                      { _id: 'jobRoleType', name: 'Job Role Type', disabled: true },
                    ].map((option) => (
                      <MenuItem key={option._id} value={option._id} disabled={option?.disabled || false}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <br />
                <br />
                {
                  (create) && <Typography>You can enter multiple tags seperated with comma(,)</Typography>
                }
                {
                  (create) ?
                    <FormControl required>
                      <Field name='name'>
                        {({ field, form: { touched, errors }, meta }) => (
                          <FormControl required error={meta.touched && !!meta.error}>
                            <textarea {...field} style={{ ...sx.textArea }} rows={5} placeholder='Enter Tags' />
                            {meta.touched && meta.error ? (
                              <FormHelperText style={{ fontSize: '14px' }}>{meta.error}</FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      </Field>
                    </FormControl>
                    :
                    <FormControl required>
                      <Field
                        as={TextField} label='Tag Names' name='name'
                        sx={sx.textField} error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        FormHelperTextProps={{ style: sx.errorField }}
                      />
                    </FormControl>
                }
              </Box>
              <br />
              <Box sx={sx.buttonContainer}>
                <Button type="submit" variant='contained' sx={sx.submitBtn}>
                  Save Tag
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default TagEditor
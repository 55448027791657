import * as React from 'react';
import {
    DataGrid,
    getGridSingleSelectOperators,
    getGridStringOperators
} from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useList } from "hooks/useList";
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import GridToolbar from 'components/GridToolbar';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ClientTemplateDialog from 'components/dialogs/ClientTemplateDialog';
import ClientSelect from 'components/ClientSelect';
import ListPagination from 'components/ListPagination';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getTemplates, deleteTemplateById } from "services/templates";
import { getDomains } from 'services/domains';
import { formatDate, formatSeconds } from 'utils';
import { getListActions, checkCreatePermission } from "utils/listActions";
import BatchSelect from 'components/BatchSelect';
import ListQuickSearch from 'components/ListQuickSearch';
import FilterList from './FilterList';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    include: ["domain", "user", "role", "batch"],
    includeTotal: true,
};

const FilterFieldMap = {
    usedBy: "metadata.usedBy",
    numberOfQuestions: "metadata.numberOfQuestions",
    "name": "name",
    "email": 'email',
    "createdAt": "createdAt",
    "deactivated": "deactivated",
};

export default function Templates() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);
    const [selectedUsers, setSelectedUsers] = React.useState(new Set());
    const [selectedTemplates, setSelectedTemplates] = React.useState([]);
    const [clientTemplateDialog, setClientTemplateDialog] = React.useState(false);
    const [domainOptions, setDomainOptions] = React.useState([]);

    const {
        loading,
        items,
        total,
        filters,
        editItem,
        deleteItem,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getTemplates,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "interviewTemplates",
        deleteItemById: deleteTemplateById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value)
            if (columnField === 'batch' && filters.user) {
                setFilters({
                    ...BaseFilters, ...filters, user: filters.user,
                    [FilterFieldMap[columnField] || columnField]: value,
                });
            }
            else {
                setFilters({
                    ...BaseFilters,
                    [FilterFieldMap[columnField] || columnField]: value,
                });
            }
        else
            setFilters(BaseFilters);
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleConfirmationSuccess = () => {
        deleteItem(activeItemId);
        setActiveItemId(null);
    };

    const handleClientTemplateDialog = (state) => {
        if (state)
            setClientTemplateDialog(true);
        else
            setClientTemplateDialog(false);
    }

    const handleClientTemplateSuccess = async (values) => {
        setSelectedTemplates([]);
        setSelectedUsers(new Set());
        setClientTemplateDialog(false);
    }

    const handleSelectedRowChange = (selectedIds) => {
        let selectedIdSet = new Set(selectedIds);
        let tempSelectedUsers = items.reduce((newSelectedUsers, currentRow) => {
            if (selectedIdSet.has(currentRow._id))
                newSelectedUsers.add(currentRow.user._id);
            return newSelectedUsers;
        }, new Set());
        setSelectedUsers(tempSelectedUsers);
        setSelectedTemplates(selectedIds);
    }

    const columns = React.useMemo(() => {
        return [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'domain',
                headerName: 'Domain',
                flex: 1,
                type: "singleSelect",
                filterable: true,
                valueOptions: domainOptions,
                headerClassName: classes.tableHeader,
                filterOperators: getGridSingleSelectOperators().filter(
                    (operator) => operator.value === 'is',
                ),
                valueGetter: (params) => {
                    const domain = params?.row?.domain?.name || 'Not Available';
                    return domain;
                },
            },
            {
                field: 'user',
                headerName: 'User',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: false,
                valueGetter: (params) => {
                    const name = params?.row?.user?.name || 'Not Available';
                    return name;
                },
            },
            {
                field: 'batch',
                headerName: 'Batch',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: false,
                valueGetter: (params) => {
                    const name = params?.row?.batch?.name || '-';
                    return name;
                },
            },
            {
                field: 'numberOfQuestions',
                headerName: 'Number of Questions',
                flex: 1,
                filterable: false,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const { numberOfQuestions } = params?.row?.metadata || {};
                    return numberOfQuestions;
                },
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'usedBy',
                headerName: 'Used By',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: false,
                valueGetter: (params) => {
                    const { usedBy } = params?.row?.metadata || {};
                    return usedBy;
                },
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'averageTime',
                headerName: 'Average Time',
                filterable: false,
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const { averageTime } = params?.row?.metadata || {};
                    return formatSeconds(averageTime);
                },
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'createdAt',
                headerName: 'Created at',
                filterable: false,
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    return formatDate(params?.row?.createdAt, 'DD-MM-YYYY');
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) =>
                    getListActions(params.id, editItem, setActiveItemId, "InterviewTemplate")
            },
        ];
    }, [classes.tableHeader, editItem]);

    const handleClientChange = React.useCallback((client) => {
        if (!client) return;

        handleFilterChange({
            items: [{
                columnField: 'user',
                value: client._id
            }]
        });
    }, []);

    const handleBatchChange = React.useCallback((batch) => {
        if (!batch.length) return;
        let batches = batch.map(({ _id }) => _id);
        handleFilterChange({
            items: [
                {
                    columnField: 'batch',
                    value: batches
                }
            ]
        });
    }, []);

    React.useEffect(() => {
        (async () => {
            try {
                const { interviewDomains } = await getDomains();
                setDomainOptions(interviewDomains.map(x =>
                    ({ value: x._id, label: x.name })
                ));
            } catch (error) {

            }
        })();
    }, []);


    return (
        <Box>
            <ContentHeader
                title={"InPrep Templates"}
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <CustomButton
                    variant="contained"
                    onClick={e => handleClientTemplateDialog(true)}
                    disabled={!((selectedUsers.size > 0) && checkCreatePermission("InterviewTemplate"))}
                >
                    Duplicate
                </CustomButton>

                &nbsp;
                <FilterList width={"350"}>
                    <ListQuickSearch onFilterChange={handleFilterChange} />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by domain"
                        field="domain"
                    />
                    <ClientSelect onChange={handleClientChange} />
                    <BatchSelect
                        onChange={handleBatchChange}
                        defaultValue={filters.batch}
                        client={filters.user}
                    />
                </FilterList>

            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    disableColumnMenu
                    checkboxSelection={checkCreatePermission("InterviewTemplate")}
                    selectionModel={selectedTemplates}
                    onSelectionModelChange={handleSelectedRowChange}
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ConfirmationDialog
                message={
                    `This template will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete Interview Template"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
            <ClientTemplateDialog
                open={clientTemplateDialog}
                onClose={e => handleClientTemplateDialog(false)}
                onSuccess={handleClientTemplateSuccess}
                selectedUsers={selectedUsers}
                selectedTemplates={selectedTemplates}
            />
        </Box>
    );
}
import React from 'react';
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import ContentHeader from 'components/ContentHeader';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import CustomButton from 'components/CustomButton';
import FilterList from './FilterList';
import ListQuickSearch from 'components/ListQuickSearch';

import { DataGrid } from '@mui/x-data-grid';
import { useList } from 'hooks/useList';
import { useSnackbar } from 'contexts';
import { SimpleSelect } from 'components/FormSelect';
import { checkCreatePermission, getListActions } from 'utils';
import { deleteTag, getTags } from 'services/tagService';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: 562,
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    includeTotal: true, orderBy: "updatedAt", order: 'desc'
};

export const TagCategory = [
    { _id: 'company', name: 'Company' },
    { _id: 'jobRole', name: 'Job Role' },
    { _id: 'jobRoleType', name: 'Job Role Type' },
    { _id: 'subDomain', name: 'Sub Domain' },
]

export default function Tags() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [activeItem, setActiveItem] = React.useState(null);

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
        editItem,
        deleteItem,
        refresh,
    } = useList({
        getItems: getTags,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "tags",
        deleteItemById: deleteTag,
    });

    const handleDelete = () => {
        deleteItem(activeItem)
        setActiveItem(null);
    };

    const handleSortModelChange = (newModel) => {
        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value !== undefined) {
            setFilters({ ...BaseFilters, ...filters, [columnField]: value });
        } else {
            const newFilters = { ...BaseFilters, ...filters };
            delete newFilters[[columnField]];
            setFilters(BaseFilters);
        }
    };

    return (
        <>
            <ContentHeader
                title="Tags"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter={true}
            >
                <FilterList>
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        value={filters["name"]}
                        field="name"
                        label="Name"
                    />
                    <SimpleSelect
                        label="category"
                        id={`category`}
                        options={TagCategory}
                        value={filters["category"]}
                        name={`category`}
                        onChange={(e) =>
                            handleFilterChange({ items: [{ columnField: e.target.name, value: e.target.value }] })
                        }
                    />
                </FilterList>
                {checkCreatePermission("Tag") && <CustomButton
                    variant='contained'
                    onClick={editItem}
                >
                    Create
                </CustomButton>}
            </ContentHeader>

            <Box className={classes.tableContainer}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Name',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                            valueGetter: (params) => (params?.row?.name)
                        },
                        {
                            field: 'code',
                            headerName: 'Code',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                            valueGetter: (params) => (params?.row?.code)
                        },
                        {
                            field: `category`,
                            headerName: 'Category',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                        },
                        {
                            field: `createdAt`,
                            headerName: 'Created At',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                        },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            type: "actions",
                            width: 220,
                            headerClassName: classes.tableHeader,
                            getActions: (params) =>
                                getListActions(params.id, editItem, setActiveItem, "Tag")
                        },
                    ]}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </Box>
            <ConfirmationDialog
                message={
                    `This Tag will permanantly get deleted, Are you sure?`
                }
                open={!!activeItem}
                title="Delete Tag"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItem(null)}
                onSuccess={handleDelete}
            />
        </>
    );
};
import React from 'react';
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import DuplicatePathway from 'components/dialogs/DuplicatePathway';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import ExportPathwayAnalyticsDialog from 'components/dialogs/ExportPathwayAnalyticsDialog';
import FilterList from './FilterList';
import ListQuickSearch from 'components/ListQuickSearch';
import ClientSelect from 'components/ClientSelect';
import BatchSelect from 'components/BatchSelect';
import { Button, Typography } from '@mui/material';

import { deletePathway, getPathways, publishPathway } from 'services/pathwayService';
import { useList } from 'hooks/useList';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { checkCreatePermission, formatDate, getpathwayListActions } from "utils";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'contexts';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: 562,
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    pathwayButton: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        borderRadius: '5px',
        marginBottom: '10px'
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    publishBtn: {
      textTransform: 'capitalize', color: '#fff', padding: '1px 5px'
    },
    published: {
      fontSize: '12px', fontWeight: '500'
    }
}));

const BaseFilters = {
    includeTotal: true, orderBy: "updatedAt", order: 'desc', include: ["user"]
};

export default function Pathways() {
    const navigate = useNavigate();
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [activeItem, setActiveItem] = React.useState(null);
    const [activeAction, setActiveAction] = React.useState(null);
    const [exportAll, setExportAll] = React.useState(false);

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
        editItem,
        deleteItem,
        refresh,
    } = useList({
        getItems: getPathways,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "pathways",
        deleteItemById: deletePathway,
    });

    const handleDelete = () => {
        deleteItem(activeItem.id)
        setActiveItem(null);
    }

    const handlePublish = async () => {
      try {
        await publishPathway(activeItem?.id);
        setActiveItem(null);
        refresh()
      } catch (error) {
        snackbar.showSnackbar(error.message, 'error', false);
      }
    }
    const handleUnPublish = async () => {
      try {
        await publishPathway(activeItem?.id,true);
        setActiveItem(null);
        refresh()
      } catch (error) {
        snackbar.showSnackbar(error.message, 'error', false);
      }
    }

    const batchFilters = React.useMemo(() => {
        return filters['batch'];
    }, [filters]);

    const handleSortModelChange = (newModel) => {
        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value !== undefined) {
            setFilters({ ...BaseFilters, ...filters, [columnField]: value });
        } else {
            const newFilters = { ...BaseFilters, ...filters };
            delete newFilters[[columnField]];
            setFilters(BaseFilters);
        }
    };

    const handleClientChange = React.useCallback((user) => {
        if (!user) {
            handleFilterChange({
                items: [{ columnField: 'user', value: undefined }]
            });
        };
        handleFilterChange({
            items: [{ columnField: 'user', value: user._id }]
        });
    }, []);

    const handleBatchChange = (newValue) => {
        if (newValue) {
            const batchList = newValue.map((el) => el._id);
            handleFilterChange({ items: [{ columnField: "batch", value: batchList }] });
        }
    }

    return (
        <>
            <ContentHeader
                title="Pathways"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter={true}
            >
                <Button
                  variant='contained'
                  onClick={()=> { 
                    setActiveAction("EXPORT");
                    setExportAll(true);
                  }}
                >
                  Export Analytics
                </Button>
                <FilterList>
                    <ListQuickSearch onFilterChange={handleFilterChange} value={filters["name"]} />
                    <ClientSelect onChange={handleClientChange} defaultValue={filters[['user']]} />
                    <BatchSelect onChange={handleBatchChange} client={filters["user"]} defaultValue={batchFilters} />
                </FilterList>
                &nbsp;
                {checkCreatePermission("Pathway") && <CustomButton
                    variant='contained'
                    className={classes.pathwayButton}
                    onClick={() => navigate('/pathways/create')}
                >
                    Create
                </CustomButton>}
            </ContentHeader>

            <Box className={classes.tableContainer}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Name',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                        },
                        {
                            field: 'user',
                            headerName: 'Client',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                            valueGetter: (params) => (params?.row?.user?.name)
                        },
                        {
                            field: 'createdAt',
                            headerName: 'Created At',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                            valueGetter: (params) => {
                                const date = params?.row?.createdAt;
                                return formatDate(date);
                            }
                        },
                        {
                            field: 'publishAction',
                            headerName: 'Published Action',
                            width: 180,
                            type: "actions",
                            headerClassName: classes.tableHeader,
                            getActions: (params) => [
                                !params.row?.publishOn ?
                                <GridActionsCellItem
                                  icon={
                                  <Button  variant='contained' className={classes.publishBtn}>
                                    Publish
                                  </Button>}
                                  label="View"
                                  onClick={(e) => {
                                    setActiveAction('PUBLISH');
                                    setActiveItem(params);
                                  }}
                                />
                                : 
                                <GridActionsCellItem
                                icon={
                                <Button  variant='contained' className={classes.publishBtn} style={{ backgroundColor: 'red'}}>
                                  Unpublish
                                </Button>}
                                label="View"
                                onClick={(e) => {
                                  setActiveAction('UNPUBLISH');
                                  setActiveItem(params);
                                }}
                              />
                              
                            ]
                        },
                        {
                            field: 'publishOn',
                            headerName: 'Published On',
                            width: 180,
                            type: "actions",
                            headerClassName: classes.tableHeader,
                            getActions: (params) => [
                                !params.row?.publishOn ?
                                <Typography className={classes.published}>
                                    Not published yet!
                                </Typography>
                                : 
                                <Typography className={classes.published}>
                                  {formatDate(params.row?.publishOn)}
                                </Typography>
                              
                            ]
                        },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            type: "actions",
                            width: 220,
                            headerClassName: classes.tableHeader,
                            getActions: (params) =>
                                getpathwayListActions(params, editItem, setActiveItem, setActiveAction, "InterviewTemplate")
                        },
                    ]}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </Box>
            <DuplicatePathway
                onClose={() => setActiveItem(false)}
                open={activeItem && activeAction === 'DUPLICATE'}
                item={activeItem?.row || {}}
                onSuccess={refresh}
            />
            <ExportPathwayAnalyticsDialog
                open={(activeItem || exportAll) && activeAction === 'EXPORT'}
                onClose={() => {
                  setActiveItem(false);
                  setExportAll(false);
                  }}
                onSuccess={refresh}
                pathway={activeItem?.row}
                exportAll={exportAll}
            />
            <ConfirmationDialog
                message={
                    `This Pathway will permanantly get deleted, Are you sure?`
                }
                open={!!activeItem && activeAction === 'DELETE'}
                title="Delete Pathway"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItem(null)}
                onSuccess={handleDelete}
            />
            <ConfirmationDialog
                message={
                    `This Pathway will be Published to the Users`
                }
                open={!!activeItem && activeAction === 'PUBLISH'}
                title="Publish Pathway"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItem(null)}
                onSuccess={handlePublish}
            />
            <ConfirmationDialog
                message={
                    `This Pathway will be Unpublished to the Users`
                }
                open={!!activeItem && activeAction === 'UNPUBLISH'}
                title="Unpublish Pathway"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItem(null)}
                onSuccess={handleUnPublish}
            />
        </>
    );
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, } from 'formik';
import * as yup from "yup";
import Box from '@mui/material/Box';
import ContentHeader from 'components/ContentHeader';
import TemplateDetails from './TemplateDetails';
import { useLoading, useSnackbar } from "contexts";
import { exportTemplateWithQuestions } from 'services';

const validationSchema = yup.object({
    template: yup.object().shape({
        name: yup.string().required("Name of the Interview is required"),
        type: yup.string().required("Type of interview is required"),
        domain: yup.string().required("Domain of Interview is required"),
        remarks: yup.string(),
        user: yup.string().required("Select a Client"),
    }),
    sheetUrl: yup.string().url('invalid url format').required("Sheet url is required"),
    sheetName: yup.string().required("Sheet name is required"),
});

const initialValues = {
    isCreator: true,
    template: {
        name: "",
        domain: "",
        remarks: "",
        type: "",
        user: "",
    },
    sheetUrl: "",
    sheetName: "",
};

function StarTemplateEditor() {
    const snackbar = useSnackbar();
    const loading = useLoading();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            try {
                loading.show();
                if (values?.template?.remarks?.trim() === "")
                    delete values.template.remarks;

                await exportTemplateWithQuestions(
                    values.template,
                    values.sheetUrl,
                    values.sheetName,
                );

                snackbar.showSnackbar('Saved template successfully', 'success', true);
                navigate('/general');
            } catch (error) {
                console.log(error);
                snackbar.showSnackbar(
                    error.message || 'Unable to save template with questions',
                    'error',
                    true
                );
            } finally {
                loading.hide();
            }
        },
    });


    return (<>
        <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
            <Box position="relative">
                <ContentHeader title={"Create STAR Interview Template"} />
                <TemplateDetails formik={formik} />
            </Box>
        </form>
    </>)
}

export default StarTemplateEditor;
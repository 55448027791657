import * as React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import {
    DataGrid, getGridStringOperators, GridActionsCellItem
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CopiedIcon from '@mui/icons-material/CheckCircle';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentHeader from 'components/ContentHeader';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import { useList } from "hooks/useList";
import { getReferrals } from "services/referrals";
import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import { copyToClipboard } from 'utils';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main, color: "white",
        alignItems: "center"
    }
}));

const BaseFilters = { include: 'user', includeTotal: true, application: "mock" };


function TestLink({ link }) {
    const [copied, setCopied] = React.useState(false);

    const handleCopy = () => {
        if (copied) return;

        copyToClipboard(link);

        setCopied(true);

        setTimeout(() => setCopied(false), 1000);
    };

    return (
        <IconButton onClick={handleCopy} title='Copy Invite URL'>
            {copied ? <CopiedIcon color='success' /> : <CopyIcon />}
        </IconButton>
    )
}

export default function Referrals() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getReferrals,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "referrals",
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value) {
            setFilters({ ...BaseFilters, [columnField]: value });
        } else {
            setFilters(BaseFilters);
        }
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: 'user.email',
                headerName: 'Ambassador Email',
                flex: 2,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
                valueGetter: ({ row }) => row?.user?.email,
            },
            {
                field: 'user.phone',
                headerName: 'Ambassador Contact',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
                valueGetter: ({ row }) => row?.user?.contact,
            },
            {
                field: 'code',
                headerName: 'Code',
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'usage',
                headerName: 'Hits',
                headerClassName: classes.tableHeader,
                flex: 1,
                filterable: false,
                valueGetter: ({ row: { usage } }) => `${usage?.mock || 0}`,
            },
            {
                field: 'usersRegistered',
                headerName: 'User Registered',
                headerClassName: classes.tableHeader,
                filterable: false,
                flex: 1,
                valueGetter: ({ row: { usersRegistered: ur } }) =>
                    `${ur?.mock || 0}`,
            },
            {
                field: 'link',
                headerName: 'Link',
                width: 56,
                filterable: false,
                headerClassName: classes.tableHeader,
                renderCell: ({ row }) => <TestLink
                    link={`${process.env.REACT_APP_TOEFL_URL}?rc=${row.code}`}
                />
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="View"
                        onClick={(e) => setActiveItemId(params.row._id)}
                    />
                ],
            },
        ];
    }, []);

    return (
        <Box>
            <ContentHeader
                title="Referrals"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            />
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <EntityViewDialog
                open={!!activeItemId}
                getEntity={(id) => items.find(item => item._id === id)}
                title="Referral Details"
                entityId={activeItemId}
                onClose={() => setActiveItemId(null)}
            />
        </Box>
    );
}
import React, { useEffect, useMemo } from 'react';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CustomButton from './CustomButton';
import Chip from '@mui/material/Chip';
import { uploadFile } from "services/uploadService";
import { useSnackbar } from "contexts";
import classNames from 'classnames';

const useStyles = makeStyles({
  fileDiv: {
    position: 'relative',
    maxWidth: 400,
    backgroundColor: '#F4F5F5',
    borderRadius: 8,
    margin: 8,
    // overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1em',
  },
  fileDivError: {
    border: '1px solid red'
  },
  fileLabel: {
    position: 'absolute',
    top: -10, backgroundColor: '#ffffff',
    padding: '2px 10px', textTransform: 'capitalize',
    fontSize: '12px'
  },
  fileSubDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'space-between',
    padding: 0,
    height: 48,
  },
  fileName: {
    fontFamily: 'Sora',
    fontWeight: 600,
    fontSize: 18,
    color: '#2C3840',
    flexGrow: 1,
  },
  uploadingDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'fit-content',
    paddingRight: 8,
  },
  uploadingText: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: 16,
    color: '#858C90'
  },
  cancelIcon: {
    width: 25,
    height: 25,
    alignSelf: 'center',
    cursor: 'pointer',
    backgroundColor: '#ffffff', borderRadius: '50%', padding: '2px',
  },
});
const ChooseFolderButton = ({ name='folder-upload', onFileSelected, accept = [], disabled, label }) => {
  return (
    <label htmlFor={name}>
      <input
        id={name}
        name={name}
        style={{ display: 'none' }}
        type="file"
        onChange={onFileSelected}
        accept={accept.join(",")}
        disabled={disabled}
        directory="" webkitdirectory=""
      />
      <CustomButton
        variant="contained"
        component="span"
        disabled={disabled}
      >
        {label}
      </CustomButton>
    </label>
  );
};

const getFolderName = (file) => {
  const folderPath = file.webkitRelativePath;
  const parts = folderPath.split('/');
  return parts.length > 1 ? parts[0] : '';
};


export default function FolderUploadInput({
  name,
  uploadLocation,
  folder,
  toPublic = false,
  accept = [],
  onUpload = () => { },
  onUploadCancelled = () => { },
  errors = {},
  setErrors = () => { },
  readOnly = false,
  label = 'Choose Folder'
}) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [state, setState] = React.useState(folder ? "LOADED" : "NO_FILE");
  const [uploading, setUploading] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([{}]);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [filesData, setFilesData] = React.useState();
  const [folderName, setFolderName] = React.useState(folder);
  
  const selectFile = (event) => {
    if (event.target.files.length) {
      setState("NEW_FILE_SELECTED");
      
      let filesArray = Array.from(event.target.files);
      let filesDataArray = [];
      setSelectedFiles(filesArray);

      setFolderName(getFolderName(filesArray[0]));
      
      filesArray.forEach(file => {
        let reader = new FileReader();
        reader.onload = (e) => {
          filesDataArray.push({ name: file.name, data: e.target.result });
          if (filesDataArray.length === filesArray.length) {
            setFilesData(filesDataArray);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }
  const handleUploadSuccess = (error, uploadedFiles) => {
    setUploading(false);
    if (error) {
      setState('FAILED');
      setMessage("Upload failed");
    } else {
      setState('UPLOADED');
      setMessage("Uploaded");
      snackbar.showSnackbar("File uploaded successfully!!", "success", true);

      setSelectedFiles(uploadedFiles);
      if (onUpload && typeof onUpload === 'function'){
        onUpload({url: `${uploadLocation}/${folderName}/`, name: `${folderName}/`});
        }
    }
  };

  useEffect(() => {
    let uploadedFiles = [];
    try{
      if (filesData && selectedFiles.length && !selectedFiles[0].url) {
      for(let i=0; i<selectedFiles.length; i++){
          setState("UPLOADING");
          setUploading(true);
          setMessage("Uploading...");
          uploadFile(
            selectedFiles[i], filesData[i].data, `${uploadLocation}/${folderName}`, toPublic, false
          ).then((url) => {
            const uploadedFile = {
              name: selectedFiles[i].name,
              url,
            };
            uploadedFiles.push(uploadedFile);
          }).catch((e) => {
            console.error(e);
          });
        }
        setErrors({ ...errors, file: false });
        handleUploadSuccess(null, uploadedFiles);
        setUploading(false);
        setMessage("Uploaded");
        setState("UPLOADED");
      }
    }
    catch(e){
      setState('FAILED');
      setMessage("Upload failed");
      setErrors({ ...errors, file: true });
      console.error(e);
    }
  }, [filesData]);

  const handleCancelClick = () => {
    if (uploading) {
      setUploading(false);
    }
    setState("NO_FILE");
    setSelectedFiles([{ name: undefined, url: "" }]);
    setFolderName(null);
    setFilesData();
    if (onUploadCancelled && typeof onUploadCancelled === 'function')
      onUploadCancelled();
  };

  const onFileClick = () =>
    state === 'LOADED' && folder && window.open(folder, '_blank');

  useEffect(() => {
    if (folder) {
      setSelectedFiles([{name: folder, url: folder}]);
      setFolderName(folder)
      setState('UPLOADED');
    }
    else {
      setSelectedFiles([{}]);
      setFolderName(folder)
      setState('NO_FILE');
    }
  }, [folder]);

  const hasUploadLocation = useMemo(() => {
    return !!uploadLocation;
  }, [uploadLocation]);


  return (
    <Box
      className={classNames([
        (errors?.file || !hasUploadLocation) && classes.fileDivError,
        classes.fileDiv
      ])}
    >
      <Typography className={classes.fileLabel}>
        {label}
      </Typography>
      <Box className={classes.fileSubDiv}>
        <Typography
          variant="caption"
          onClick={onFileClick}
          color={hasUploadLocation ? "default" : "error"}
        >
          {
            hasUploadLocation ?
              folderName || "No file choosen" :
              "Upload location not provided"
          }
        </Typography>
        {state === 'UPLOADING' && (
          <>
            <Box className={classes.uploadingDiv} alignItems="center">
              <Chip
                className={classes.uploadingText}
                color={error ? 'error' : uploading ? 'info' : 'success'}
                label={message}
                variant="outlined"
              />
            </Box>
          </>
        )}
        {(state === 'NO_FILE' || state === 'FAILED') && (
          <ChooseFolderButton
            onFileSelected={selectFile}
            accept={accept}
            disabled={readOnly || !hasUploadLocation}
            label={label}
            name={name}
          />
        )}
        {(['LOADED', 'UPLOADED'].includes(state)) && (
          <IconButton
            style={{ position: 'absolute', right: 0, fontSize: '20px' }}
            onClick={handleCancelClick}
            disabled={readOnly || !hasUploadLocation}
          >
            <CloseIcon className={classes.cancelIcon} />
          </IconButton>
        )
        }
      </Box>
    </Box>
  );
}
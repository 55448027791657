import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomLink from 'components/CustomLink';

const useStyles = makeStyles(theme => ({
  sideBarRoot: {
      bottom: "0px",
      left: "0px",
      position: "fixed",
      top: "56px",
      zIndex: 200,
      backgroundColor: '#FFFFFF',
      boxShadow: '2px 0px 4px rgb(0 0 0 / 15%)',
      borderBottom: '1px solid rgb(0 0 0 / 15%)',
  },
  sideBar: {
      boxSizing: 'border-box',
      border: 'none',
      minWidth: 200,
      paddingTop: "20px",
  },
  menuItem: {
      width: "100%",
      textDecoration: 'unset',
      color: 'black',
      display: "flex",
      alignItems: "center",
      padding: "10px 0",
    },
  menuItemText: {
    transition: "all 1s ease",
    transformOrigin: "0 50% 0",
    overflow: "hidden",
    marginLeft: '1em',
    padding: "10px 0",
    textDecoration: 'none',
  },
  activeMenuItem: {
      borderLeft: "4px solid #2DABFF",
      background: "#2DABFF1C",
      width: "100%",
      color: 'black',
      display: "flex",
      alignItems: "center",
  },
  sidebar: {
    bottom: "0px",
    left: "0px",
    position: "fixed",
    top: "56px",
    zIndex: 200,
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 0px 4px rgb(0 0 0 / 15%)',
    borderBottom: '1px solid rgb(0 0 0 / 15%)',
    boxSizing: 'border-box',
    border: 'none',
    minWidth: 200,
    paddingTop: "20px",
  },
}));

function MenuItem({ menu, activeTab, setActiveTab, disabled, index }) {
  const classes = useStyles();

  return (
      <CustomLink
          className={classes.menuItem}
          disabled={true}
          activeClassName={menu.field === activeTab && classes.activeMenuItem}
          to={''}
          onClick= {(e)=>{ !disabled && setActiveTab(menu?.field)}}
      >
          <Typography
              variant='body2'
              className={classes.menuItemText}
              sx={(disabled && index>0) && {color: '#d8d8d8'}}
              color="black"
          >
              {menu.label}
          </Typography>
      </CustomLink>
    )
}

const Sidebar = ({ activeTab, setActiveTab, sideBarLinks, pathway }) => {
  const classes = useStyles();

  return (
    <Box className={classes.sideBarRoot}>
      <Box className={classes.sideBar}>
          {Object.keys(sideBarLinks).map((key, index) => (
              <MenuItem key={index} menu={sideBarLinks[key]} activeTab={activeTab} index={index}
                setActiveTab={setActiveTab} disabled={!pathway._id?.length}
              />
          ))}
      </Box>
    </Box>
  )
}

export default Sidebar;
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import SignInForm from 'components/forms/SignInForm';
import ContentShell from 'components/ContentShell';

import Session from 'utils/Session';

const useStyles = makeStyles({
    formCenter: {
        display: "flex",
        justifyContent: 'center'
    },
    formPaper: {
        width: 400,
        height: 400,
        alignSelf: 'center',

    },
    formContainer: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        flexWrap: 'wrap',
        height: '100%',
        paddingTop: 64,
    }
});

export default function SignIn() {
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        if (Session.isLoggedIn()) navigate('/clients');
    }, []);

    return (
        <ContentShell withoutNavbar>
            <Box className={classes.formContainer}>
                <Paper elevation={3} className={classes.formPaper}>
                    <SignInForm />
                </Paper>
            </Box>
        </ContentShell>
    );
}

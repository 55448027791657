import React from 'react';

import TextField from '@mui/material/TextField';
import MultipleSelect from './MultipleSelect';
import CustomChipArray from 'components/CustomChipArray';

export default function MCQ({ index, question, answer, setAnswer }) {
  const [answerOptions, setAnswerOptions] = React.useState([]);

  return (
    <>
      <TextField
        required
        fullWidth
        inputProps={{ minlen: 0, maxlen: 1000 }}
        size="small"
        label={"Q".concat(index + 1)}
        placeholder="Add question content..."
        onInput={(e) => question.content = e.target.value}
      />
      <br /><br />
      <CustomChipArray
        label={"Options"}
        buttonText="Add"
        InputProps={{ placeholder: "Add option and hit enter" }}
        onValuesChange={(options) => {
          question.options = options;
          setAnswerOptions(options);
        }}
      />
      <br /><br />
      <MultipleSelect
        required
        label={"Answers"}
        options={answerOptions}
        placeholder="Select answers"
        values={answer}
        setValues={setAnswer}
      />
    </>
  );
}

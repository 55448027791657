import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

function FIBItemWrapper({ children, type }) {
  return (
    <Box
      position="relative"
      p={1}
      border="1px solid lightgrey"
      borderRadius={2}
      flexGrow={1}
    >
      <Typography variant="caption" color="Highlight">
        {type === "FIB" ? "Blank" : "Text"}
      </Typography>
      {children}
    </Box >
  );
}

export default function FIB({ index, question, answer, setAnswer, fibType }) {
  const [content, setContent] = React.useState([
    { type: "FIB" }, { type: "TEXT" }
  ]);

  const inputHandler = (i) => (e) => {
    setContent((content) => {
      const newContent = [...content];
      newContent[i].content = e.target.value;

      if (newContent[i].type === "FIB") setAnswer(e.target.value);

      return newContent;
    })
  };

  React.useEffect(() => {
    switch (fibType) {
      case 'start': {
        setContent(c => {
          if (c.length === 2) {
            if (c[0].type !== 'FIB') return [...c].reverse();
            else return c;
          } else {
            const newContent = [...c];
            newContent.pop();
            return newContent;
          }
        });
        break;
      }
      case 'middle': {
        setContent(c => {
          const newContent = [...c];

          if (c[0].type === 'FIB') {
            newContent.unshift({ type: "TEXT", content: "" });
          } else {
            newContent.push({ type: "TEXT", content: "" });
          }

          return newContent;
        });
        break;
      }
      case 'end': {
        setContent(c => {
          if (c.length === 2) {
            if (c[1].type !== 'FIB') return [...c].reverse();
            else return c;
          } else {
            const newContent = [...c];
            newContent.shift();
            return newContent;
          }
        });
        break;
      }
      default:
    }

  }, [fibType]);

  React.useEffect(() => {
    question.content = content;
  }, [content, question]);

  return (
    <Box>
      <Stack direction={"row"} spacing={2} justifyContent="stretch">
        {content.map((item, i) => (
          <FIBItemWrapper type={item.type} fibType={fibType} key={i}>
            {item.type === "FIB" ? (
              <TextField
                required
                fullWidth
                inputProps={{ minlen: 0, maxlen: 1000 }}
                size="small"
                placeholder="Fill in the blank(answer)"
                onInput={inputHandler(i)}
              />
            ) : (
              <TextField
                required
                fullWidth
                inputProps={{ minlen: 0, maxlen: 1000 }}
                size="small"
                placeholder="Add text here"
                onInput={inputHandler(i)}
              />
            )}
          </FIBItemWrapper>
        ))}
      </Stack>
      <Typography variant="body2" color="Highlight" my={1}>
        Preview
      </Typography>
      <Box mb={2} fontSize={14} fontWeight={400} fontFamily="Inter">
        {content?.map(({ type, content }, i) => type === 'FIB' ? (
          "________"
        ) : (content))}
      </Box>
    </Box>
  );
}

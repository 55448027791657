import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Checkbox } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function MultiSelect({ selected, setSelected, options = [], ...props }) {

  const handleChange = (event) => {
    const { target: { value: newValue }, } = event;
    setSelected(
      // On autofill we get a stringified value.
      typeof newValue === 'string' ? newValue.split(',') : newValue,
    );
  };

  return (
    <FormControl sx={{ m: 2, width: 300 }} size="small">
      <Select
        {...props}
        multiple
        displayEmpty
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected = []) => {
          if (selected.length === 0) {
            return <em>Select &nbsp; {props.label}</em>;
          }
          return selected?.join(', ');
        }}
        MenuProps={MenuProps}
      >
        <MenuItem disabled value="">
          <em>Select&nbsp;{props.label}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selected?.indexOf(option) > -1} />
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
import React from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import Applications from "components/lists/Applications";
import ApplicationEditor from "components/forms/ApplicationEditor";
import ContentShell from "components/ContentShell";
import DomainEditor from "components/forms/DomainEditor";
import Domains from "components/lists/Domains";
import RoleEditor from "components/forms/RoleEditor";
import Roles from "components/lists/Roles";

export default function Configs() {
    return (
        <Routes>
            <Route path="/" element={<ConfigsLayout />}>
                <Route index element={<Navigate to='roles' />} />
                <Route
                    path="domains"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Domains />
                        </React.Suspense>
                    }
                />
                <Route
                    path="domains/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <DomainEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path="applications"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Applications />
                        </React.Suspense>
                    }
                />
                <Route
                    path="applications/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <ApplicationEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path="roles"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Roles />
                        </React.Suspense>
                    }
                />
                <Route
                    path="roles/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <RoleEditor />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

const SideBarLinks = [
    {
        label: "Roles",
        path: 'roles',
        allowedRoles: ["ADMIN"],
    },
    {
        label: "Applications",
        path: 'applications',
        allowedRoles: ["ADMIN"],
    },
    {
        label: "Domains",
        path: 'domains',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
];

function ConfigsLayout() {
    return (
        <ContentShell sideBarLinks={SideBarLinks}>
            <Outlet />
        </ContentShell>
    );
}
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getSEPTemplates } from "services/sepTemplates";
import { getTemplates } from 'services/templates';

export function SEPTemplateSelect({
  onChange = (sepTemplate) => { },
  filters = {},
}) {
  const [sepTemplates, setSEPTemplates] = React.useState([]);

  const handleChange = (e, newValue) => onChange(newValue);

  React.useEffect(() => {
    (async () => {
      const { sepTemplates } = await getSEPTemplates(filters);
      setSEPTemplates(sepTemplates);
    })();
  }, [filters]);

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={sepTemplates}
      renderInput={(params) => (
        <TextField {...params} label="Choose a SEP Template" />
      )}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography
          {...props}
          component="li"
          sx={{ fontSize: 14, fontWeight: "normal" }}
        >
          {option.name}
        </Typography>
      )}
      onChange={handleChange}
    />
  );
}

export function InterviewTemplateSelect({
  filters,
  onChange = (interviewTemplate) => { },
  setAllTemplateList = (list) => { }
}) {
  const [interviewTemplates, setInterviewTemplates] = React.useState([]);

  const handleChange = (e, newValue) => onChange(newValue);

  React.useEffect(() => {
    (async () => {
      const { interviewTemplates } = await getTemplates(filters);
      const initialTemplates = interviewTemplates.map((el) => (el._id));
      setAllTemplateList(initialTemplates);
      setInterviewTemplates(interviewTemplates);
    })();
  }, [filters, setAllTemplateList]);

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={interviewTemplates}
      renderInput={(params) => (
        <TextField {...params} label="Choose Interview Template" />
      )}
      isOptionEqualToValue={(option, value) => option._id.toString() === value._id.toString()}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography
          {...props}
          component="li"
          sx={{ fontSize: 14, fontWeight: "normal" }}
        >
          {option.name}
        </Typography>
      )}
      onChange={handleChange}
    />
  );
}

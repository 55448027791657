import * as React from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useList } from "hooks/useList";
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import GridToolbar from 'components/GridToolbar';
import ClientSelect from 'components/ClientSelect';
import ContentHeader from 'components/ContentHeader';
import ClientTemplateDialog from 'components/dialogs/ClientTemplateDialog';
import CustomButton from 'components/CustomButton';
import ListPagination from 'components/ListPagination';
import { getSEPTemplates, deleteSEPTemplateById } from "services/sepTemplates";
import { getListActions, checkCreatePermission } from "utils/listActions";
import SEPInviteDialog, { InviteListAction } from 'components/dialogs/SEPInviteDialog';
import FilterList from './FilterList';
import ListQuickSearch from 'components/ListQuickSearch';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    include: ["user"],
    includeTotal: true,
};

const FilterFieldMap = {
    usedBy: "metadata.usedBy",
    numberOfQuestions: "metadata.numberOfQuestions"
};

export default function SEPTemplates() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);
    const [selectedUsers, setSelectedUsers] = React.useState(new Set());
    const [selectedTemplates, setSelectedTemplates] = React.useState([]);
    const [clientTemplateDialog, setClientTemplateDialog] = React.useState(false);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);

    const {
        loading,
        items,
        total,
        editItem,
        deleteItem,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getSEPTemplates,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "sepTemplates",
        deleteItemById: deleteSEPTemplateById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value)
            setFilters({
                ...BaseFilters,
                ...filters,
                [FilterFieldMap[columnField] || columnField]: value
            });
        else
            setFilters(BaseFilters);
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleConfirmationSuccess = () => {
        deleteItem(activeItemId);
        setActiveItemId(null);
    };

    const handleClientTemplateDialog = (state) => {
        if (state)
            setClientTemplateDialog(true);
        else
            setClientTemplateDialog(false);
    }

    const handleClientTemplateSuccess = async (values) => {
        setSelectedTemplates([]);
        setSelectedUsers(new Set());
        setClientTemplateDialog(false);
    }

    const handleSelectedRowChange = (selectedIds) => {
        let selectedIdSet = new Set(selectedIds);
        let tempSelectedUsers = items.reduce((newSelectedUsers, currentRow) => {
            if (selectedIdSet.has(currentRow._id))
                newSelectedUsers.add(currentRow.user._id);
            return newSelectedUsers;
        }, new Set());
        setSelectedUsers(tempSelectedUsers);
        setSelectedTemplates(selectedIds);
    }

    const columns = React.useMemo(() => {
        return [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'user',
                headerName: 'Client',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: false,
                valueGetter: (params) => {
                    const name = params?.row?.user?.name || 'Not Available';
                    return name;
                },
            },
            {
                field: 'numberOfQuestions',
                headerName: 'Number of Questions',
                flex: 1,
                filterable: false,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    return params?.row?.questions?.length || 0;
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 120,
                headerClassName: classes.tableHeader,
                getActions: (params) => {
                    const actions = getListActions(
                        params.id, editItem, setActiveItemId, "SEPTemplate"
                    );

                    actions.unshift(
                        <InviteListAction
                            onSelect={setSelectedTemplate}
                            template={params.row}
                        />
                    );

                    return actions;
                }
            },
        ];
    }, [classes.tableHeader, editItem]);

    const handleClientChange = React.useCallback((client) => {
        if (!client) return;

        handleFilterChange({
            items: [{
                columnField: 'user',
                value: client._id
            }]
        });
    }, []);

    return (
        <Box>
            <ContentHeader
                title={"SEP Templates"}
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <CustomButton
                    variant="contained"
                    onClick={e => handleClientTemplateDialog(true)}
                    disabled={!((selectedUsers.size > 0) && checkCreatePermission("InterviewTemplate"))}
                >
                    Duplicate
                </CustomButton>

                {checkCreatePermission("InterviewTemplate") &&
                    <CustomButton
                        variant="contained"
                        onClick={editItem}
                    >
                        Create
                    </CustomButton>
                }
                <FilterList>
                    <ListQuickSearch onFilterChange={handleFilterChange} />
                    <ClientSelect onChange={handleClientChange} sepClients={true} />
                </FilterList>
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    disableColumnMenu
                    checkboxSelection={checkCreatePermission("InterviewTemplate")}
                    selectionModel={selectedTemplates}
                    onSelectionModelChange={handleSelectedRowChange}
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ConfirmationDialog
                message={
                    `This template will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete Interview Template"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
            <ClientTemplateDialog
                sep
                open={clientTemplateDialog}
                onClose={e => handleClientTemplateDialog(false)}
                onSuccess={handleClientTemplateSuccess}
                selectedUsers={selectedUsers}
                selectedTemplates={selectedTemplates}
            />
            <SEPInviteDialog
                open={!!selectedTemplate}
                template={selectedTemplate}
                onClose={() => setSelectedTemplate(null)}
            />
        </Box>
    );
}
import { useEffect, useState } from "react";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import FormTextField, { FormCopyTextField } from "components/FormTextField";
import ContentHeader from "components/ContentHeader";
import FileUploadInput from "components/FileUploadInput";
import CustomButton from "components/CustomButton";
import { useSnackbar } from "contexts";
import { getUserById, saveClient } from "services";
import { formatDate } from "utils";
import CreditTable from "./CreditTable";
import TransactionsDialog from "components/dialogs/TransactionsDialog";
import { FormSelect } from "components/FormSelect";
import { OrganisationType, SubscriptionModel } from "./constants"


const clientValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    redirectUrl: Yup.string()
        .url()
        .required('Required'),
    tabTitle: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .optional(),
    tabLogoUrl: Yup.object().shape({
          url: Yup.string()
        }).optional(),
    logoUrl: Yup.object().shape({
            url: Yup.string().required()
        }).required(),
    sepWebHookUrl: Yup.string()
        .url()
        .optional(),
    sepRedirectName: Yup.string()
        .optional(),
    sepDynamicDescription: Yup.string()
        .optional(),
    emailSEPReport: Yup.boolean()
        .optional(),
    personalizedInterviewClientLimit: Yup.number()
        .min(0, 'Must be greater than or equal to 0')
        .optional(),
    personalizedInterviewUserLimit: Yup.number()
        .min(0, 'Must be greater than or equal to 0')
        .optional(),
});

const InitialValues = {
    name: '',
    email: '',
    redirectUrl: '',
    userLimit: -99,
    tabTitle: '',
    logoUrl: { url: '', name: '' },
    tabLogoUrl: { url: '', name: '' },
    apiSecret: 'Auto generated',
    createdAt: 'Auto generated',
    newUserSignupUrl: 'Auto generated',
    organisationType: '',
    subscriptionModel: '',
    personalizedInterviewLimit: 0,
};

const useStyles = makeStyles({
    fields: { margin: 20 },
    fieldContainer: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    copyIcon: {
        marginLeft: '8px',
        cursor: 'pointer'
    },
    buttonContainer: {
        margin: "30px 10px"
    }
});

const getFields = (id) => [
    {
        required: true,
        name: "name",
        label: "Name",
    },
    {
        required: true,
        name: "email",
        label: "Email",
        disabled: id !== "create",
    },
    {
        required: true,
        name: "redirectUrl",
        label: "Redirect Url",
    },
    {
        name: "createdAt",
        label: "Registration Date",
        disabled: true,
    },
    {
      name: "tabTitle",
      label: "Tab Title",
      required: false,
    },
    {
      name: "personalizedInterviewUserLimit",
      label: "User Personalized Interview Limit",
      required: false,
    },
    {
      name: "personalizedInterviewClientLimit",
      label: "Client Personalized Interview Limit",
      required: false,
    }
];


export default function ClientForm() {
    const classes = useStyles();
    const { id } = useParams();
    const subscriptionEdit = useMatch('/clients/inprep/create');
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(InitialValues);
    const [transactionClient, setTransactionClient] = useState(null);

    const subscriptionModelOptions = SubscriptionModel;
    const organisationTypeOptions = OrganisationType;
    const handleTranactionClick = (event) => {
        event.preventDefault();
        setTransactionClient(id);
    }
    const handleTractionClose = (event) => {
        event.preventDefault();
        setTransactionClient(null);
    }

    const handleSubmit = async (values) => {
        try {
            const client = { role: "CLIENT" };
            client["name"] = values.name;
            client["email"] = values.email;
            const metadata = {};
            metadata["tabTitle"] = values.tabTitle;
            metadata["redirectUrl"] = values.redirectUrl;
            metadata["logoUrl"] = values.logoUrl?.url;
            metadata["tabLogoUrl"] = values.tabLogoUrl?.url;
            metadata["userLimit"] = (values.userLimit < 0) ? -99 : values.userLimit;
            metadata["subscriptionModel"] = values.subscriptionModel
            metadata["organisationType"] = values.organisationType
            metadata["personalizedInterviewLimit"] = {
              user: parseInt(values.personalizedInterviewUserLimit) || 0,
              client: parseInt(values.personalizedInterviewClientLimit) || 0
            } 

            let clientId = null;

            if (id && id !== 'create') {
                metadata["apiSecret"] = values.apiSecret;
                metadata["newUserSignupUrl"] = values.newUserSignupUrl;
                clientId = id;
            }
            client["metadata"] = metadata;

            const savedClient = await saveClient(clientId, client);

            snackbar.showSnackbar("Client saved successfully !!");

            navigate(`/clients/inprep/${savedClient._id}`);
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (id && id !== 'create') {
                    const client = await getUserById(id, { roles: true });
                    const logoName = client.metadata?.logoName;
                    const tabLogoName = client.metadata?.tabLogoName;
                    const logoUrl = client.metadata?.logoUrl;
                    const tabTitle = client.metadata?.tabTitle;
                    const tabLogoUrl = client.metadata?.tabLogoUrl;

                    let newInitialValues = {
                        name: client.name,
                        email: client.email,
                        ...client?.metadata,
                        createdAt: formatDate(client.createdAt),
                        tabTitle: tabTitle,
                        logoUrl: {
                            url: client.metadata?.logoUrl || "",
                            name: logoName || logoUrl.slice((logoUrl.lastIndexOf("/") + 1)),
                        },
                        tabLogoUrl: {
                            url: client.metadata?.tabLogoUrl || "",
                            name: tabLogoName || tabLogoUrl?.slice((tabLogoUrl.lastIndexOf("/") + 1)),
                        },
                        personalizedInterviewUserLimit: parseInt(client.metadata?.personalizedInterviewLimit?.user) || 0,
                        personalizedInterviewClientLimit: parseInt(client.metadata?.personalizedInterviewLimit?.client) || 0,
                    };
                    if (client?.metadata?.organisationType) {
                        newInitialValues.organisationType = client?.metadata?.organisationType;
                    }
                    if (client?.metadata?.subscriptionModel) {
                        newInitialValues.subscriptionModel = client?.metadata?.subscriptionModel;
                    }
                    setInitialValues(newInitialValues);
                }
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error', false);
            } finally {
                setLoading(false)
            }
        })();
    }, [id]);

    const goBack = () => navigate("/clients/inprep");

    return (<>
        <ContentHeader title="InPrep Client Form" />
        <br />
        {!loading && (
            <Formik
                validationSchema={clientValidationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, errors, setFieldValue }) => (
                    <Form>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '32% 32% 32%',
                                gridColumnGap: "2%"
                            }}
                        >
                            {id && getFields(id).map((field, index) => (
                                <FormTextField key={index} {...field} />
                            ))}

                            <FormCopyTextField
                                disabled
                                name="apiSecret"
                                label="Secret key"
                            />

                            <FormSelect
                                name={"subscriptionModel"}
                                placeholder={"Subscription Model"}
                                label={"Subscription Model"}
                                options={subscriptionModelOptions}
                                defaultValue={initialValues.subscriptionModel}
                                value={initialValues.subscriptionModel}
                                required
                                disabled={!!!subscriptionEdit}
                            />
                            <FormSelect
                                name={"organisationType"}
                                placeholder={"Organisation Type"}
                                label={"Organisation Type"}
                                options={organisationTypeOptions}
                                defaultValue={initialValues.organisationType}
                                value={initialValues.organisationType}
                                required
                            />
                            <br/>
                            <FileUploadInput
                                name="logoUrl"
                                label="upload client logo"
                                file={{
                                    name: values.logoUrl.name,
                                    url: values.logoUrl.url
                                }}
                                onUploadCancelled={() => setFieldValue('logoUrl', {})}
                                onUpload={(file) => setFieldValue('logoUrl', file)}
                                errors={{ file: errors['logoUrl'] || false }}
                                toPublic={true}
                                uploadLocation='logos'
                            />

                            <FileUploadInput
                                name="tabLogoUrl"
                                label="upload tab logo"
                                file={{
                                    name: values.tabLogoUrl.name,
                                    url: values.tabLogoUrl.url
                                }}
                                onUploadCancelled={() => setFieldValue('tabLogoUrl', {})}
                                onUpload={(file) => setFieldValue('tabLogoUrl', file)}
                                toPublic={true}
                                uploadLocation='logos'
                            />
                            
                            <br />
                        </div>

                        <Box className={classes.buttonContainer}>
                            <CustomButton
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </CustomButton>
                            <CustomButton
                                variant="outlined"
                                onClick={goBack}
                            >
                                Cancel
                            </CustomButton>
                            <Divider />
                            <CreditTable client={initialValues} clientId={id}
                                onTransactionClick={handleTranactionClick}
                            />
                        </Box>
                    </Form>)}
            </Formik>

        )}
        <TransactionsDialog
            open={!!transactionClient} clientId={transactionClient} onClose={handleTractionClose}
        />
    </>);
}
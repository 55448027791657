import * as React from 'react';
import { useState } from 'react';
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import FormTextField from 'components/FormTextField';
import ClientSelect from 'components/ClientSelect';
import { makeStyles } from '@mui/styles';
import CustomButton from 'components/CustomButton';
import useStore from './store';
import shallow from 'zustand/shallow';
import { useLoading, useSnackbar } from 'contexts';
import { createPathwayAndLinkTags, updatePathwayAndTags } from 'services/pathwayService';
import { useParams } from 'react-router-dom';
import TagSelect from 'components/TagSelect';
import { getTags } from 'services/tagService';
import { SimpleSelect } from 'components/FormSelect';

const pathWayValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name Required'),
  user: Yup.string().optional("Select a Client"),
  jobRoleType: Yup.string().required("Select job role type"),
  tags: Yup.array().optional("Select Tags"),
});

const useStyles = makeStyles((theme) => ({
  pathwayForm: {
    marginBlock: "1.5rem",
    marginTop: '20px', width: '100%'
  },
  contentRoot: {
    flex: "auto",
    paddingLeft: "216px", paddingRight: '10px'
  }
}))


function Pathway() {
  const { id } = useParams();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const classes = useStyles();

  const pathway = useStore(state => state.pathway, shallow);
  const setPathway = useStore(state => state.setPathway, shallow);
  const init = useStore(state => state.init, shallow);
  const [jobRoleType, setJobRoleType] = useState(null);
  const [enableSave, setEnableSave] = useState(false);

  const handleSubmit = async () => {
    try {
      loading.show(true);
      const valid = await pathWayValidationSchema.isValid(pathway, { abortEarly: false });
      if (!valid) {
        snackbar.error("Seems like you have some invalid data!! Please check the student list");
        return;
      }

      let pathwayData = {
        ...pathway, tags: [...pathway.tags, pathway.jobRoleType]
      }

      delete pathwayData.jobRoleType;

      let pathwayResult = {};
      if (id && id !== 'create') {
        pathwayResult = await
          updatePathwayAndTags(id, { name: pathwayData.name, user: pathwayData?.user, tags: pathwayData.tags });
        
        if(pathwayResult){
          setPathway(pathwayResult);
        }
      }
      else {
        pathwayResult = await createPathwayAndLinkTags(pathwayData);
        init({pathway: pathwayResult, modules: []});
      }

      snackbar.showSnackbar("Pathway saved successfully !!");
    } catch (error) {
      snackbar.showSnackbar(error.message, 'error', false);
    } finally{
      loading.hide();
    }
  };

  React.useEffect(() => {
    (async () => {
        const { tags } = await getTags({category: ['jobRoleType']});
        setJobRoleType(tags);
    })();
}, []);

  React.useEffect(() => {
    try{
      (async () => {
        let valid = await pathWayValidationSchema.isValid(pathway);
        setEnableSave(valid);
      }
      )();
    }catch(err){
      console.log("error--",err);
    }
  }, [pathway]);


  return (
    <>
      <Formik
        initialValues={pathway}
        onSubmit={handleSubmit}
      >
        {
          props => (
            <Form onSubmit={props.handleSubmit}>
              <div className={classes.pathwayForm}>
                <FormTextField name="name" label="Name"
                  inputProps={{
                    value: pathway.name,
                    onChange: (e) => setPathway({ ...pathway, name: e.target.value })
                  }}
                />
                <ClientSelect
                  defaultValue={pathway.user}
                  disabled={pathway?._id}
                  onChange={(e) => setPathway({ ...pathway, user: e?._id || null })}
                />

                <SimpleSelect
                  options={jobRoleType}
                  value={pathway?.jobRoleType}
                  label={'select job role'}
                  onChange={(e) => setPathway({ ...pathway, jobRoleType: e.target.value || null })}
                />

                <TagSelect
                  filters={{category: ["company", "jobRole"]}}
                  defaultValue={pathway?.tags ?? []}
                  onChange={(data)=> {
                    const tags = data.map((item) => item._id);
                    setPathway({ ...pathway, tags: tags });
                  }}
                />

                <CustomButton
                  variant="contained"
                  type="submit"
                  disabled={(!id && pathway?._id) ? true : !enableSave}
                >
                  Save
                </CustomButton>
              </div>
            </Form>
          )
        }
      </Formik>
    </>
  )
}

export default Pathway;
import Session from "../utils/Session";
import Client from "./_client";

export const restoreSession = async () => {
  const result = await new Client({
    path: "/auth/restore",
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to restore you session. Please sign in."
    );
  } else {
    Session.onRestoreSession(result.data);
    return result;
  }
};

export const signOut = async () => {
  Session.clearSession();
};

export const signIn = async ({ email, password }) => {
  const result = await new Client({
    path: '/signin',
    payload: { email, password, role: null, client: null },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to log you in.");
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
};

export const overwriteSession = async () => {

  const result = await new Client({
    path: `/auth/overwrite-session`,
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to log you in.");
  }
};

export const decodePassword = async (userId) => {

  const result = await new Client({
    path: `/auth/decode/password?userId=${userId}`,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to decode the password.");
  } else {
    return result;
  }
};


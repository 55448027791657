import Client from "./_client";

export const getDomains = async (domainFilters = {}) => {

    let url = '/interviewDomain?';
    
    Object.keys(domainFilters).forEach((key) => {
        if (domainFilters[key] !== undefined) url += `${key}=${domainFilters[key]}&`;
    });

    const result = await new Client({
        path: url
    }).get();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to fetch domains!");

    return result.data || [];
}


export const getDomainById = async (domainId) => {
    const url = `/interviewDomain/${domainId}`;

    let result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get domain. Please try again."
        );
    }

    return result.data;
}

export const createDomain = async (domain) => {

    let url = `/interviewDomain`;

    let result = await new Client({
        path: url,
        payload: domain,
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save domain. Please try again."
        );
    }

    return result.data;
}

export const updateDomainById = async (domainId, domain) => {

    let url = `/interviewDomain/${domainId}`;

    let result = await new Client({
        path: url,
        payload: domain,
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to udpate domain. Please try again."
        );
    }

    return result.data;
}

export const deleteDomainById = async (domainId) => {

    let url = `/interviewDomain/${domainId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete domain. Please try again."
        );

    return result.data;
}



import {
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarContainer,
    GridToolbarColumnsButton
} from '@mui/x-data-grid';

export default function GridToolbar({
    density,
    exportToolbar,
    columns = true,
    filter = false,
}) {
    return (
        <GridToolbarContainer>
            {columns && <GridToolbarColumnsButton />}
            {density && <GridToolbarDensitySelector />}
            {filter && <GridToolbarFilterButton />}
            {exportToolbar && <GridToolbarExport />}
        </GridToolbarContainer>
    );
}
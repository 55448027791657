import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import { useLoading, useSnackbar } from 'contexts';
import { sendMailToClient } from 'services';


const useStyles = makeStyles({
    fieldsContainer: {
        display: 'flex',
        marginBottom: "20px",
        justifyContent: "flex-start",
        width: '100%',
    },
    textarea: {
        width: '-webkit-fill-available',
    },
    error: {
        borderColor: "red"
    }
});

function SendEmailDialog({
    open,
    onClose,
    onSuccess,
    client = null,
    title = "Send mail to client",
    primaryAction = "Send Mail",
    secondaryAction = "Cancel",
}) {
    const classes = useStyles();
    const loading = useLoading();
    const snackbar = useSnackbar();
    const formik = useFormik({
        initialValues: {
            to: client ? client.email : "",
            cc: '',
            subject: `${client?.name} <> Languify | Inprep integeration information`
        },
        validationSchema: Yup.object().shape({
            to: Yup.string().required().trim(),
            subject: Yup.string().required().trim()
        }),
        onSubmit: (values) => {
            const emailData = {};
            emailData["to"] = values.to.split(',');
            emailData["cc"] = values.cc.split(',');
            emailData["subject"] = values.subject;
            sendMail(emailData);
        }
    });

    const sendMail = async (emailData) => {
        loading.show();
        try {
            await sendMailToClient(client._id, emailData);

            snackbar.showSnackbar("Mail sent successfully!!", "success");
            onSuccess();
        } catch (error) {
            console.error("DEBUG::useList_.sendMail", error);
            snackbar.showSnackbar(error.message, "error");
        } finally {
            loading.hide();
            formik.resetForm();
        }
    };

    React.useEffect(() => {
        formik.setFieldValue(
            'subject',
            `${client?.name} <> Languify | Inprep integeration information`
        );
        if (client) formik.setFieldValue('to', client.email);
    }, [client]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            fullWidth
            maxWidth="md"
        >
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ minWidth: 380 }}>
                    <Box className={classes.fieldsContainer}>
                        <Typography variant='h2' mr={2}>To: </Typography>
                        <TextareaAutosize
                            id='to'
                            placeholder="any@gmail.com,any@gmail.com"
                            minRows={2}
                            className={`
                            ${classes.textarea} 
                            ${(formik.touched.to && formik.errors.to) ?
                                    classes.error : ''}
                                    `}
                            onChange={formik.handleChange}
                            value={formik.values.to}
                        />
                    </Box>
                    <Box className={classes.fieldsContainer}>
                        <Typography variant='h2' mr={2}>CC: </Typography>
                        <TextareaAutosize
                            id='cc'
                            className={classes.textarea}
                            placeholder="any@gmail.com,any@gmail.com"
                            minRows={2}
                            onChange={formik.handleChange}
                            value={formik.values.cc}
                        />
                    </Box>
                    <Box className={classes.fieldsContainer}>
                        <Typography variant='h2' mr={2}>Subject: </Typography>
                        <TextField
                            size='small'
                            error={formik.touched.subject && formik.errors.subject}
                            id="subject"
                            variant="outlined"
                            fullWidth
                            value={formik.values.subject}
                            spellCheck="false"
                            onChange={formik.handleChange}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        variant='outlined'
                        autoFocus
                    >
                        {secondaryAction}
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        color={'primary'}
                        autoFocus
                    >
                        {primaryAction}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default SendEmailDialog;
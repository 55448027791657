import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import shallow from 'zustand/shallow';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from 'yup';

import CustomButton from 'components/CustomButton';
import useStore from './store';
import { CheckboxOptions, SimpleSelect } from 'components/FormSelect';
import { useLoading, useSnackbar } from 'contexts';
import { getPathwayModules, getPathwayTagMappings, saveModule } from 'services/pathwayService';
import { useNavigate, useParams } from 'react-router-dom';
import FileUploadInput from 'components/FileUploadInput';
import FolderUploadInput from 'components/FolderUploadInput';

const useStyle = makeStyles((theme) => ({
    textFieldRoot: {
      margin: "4px 0px",
      height: '50px',
      borderRadius: "6px",
      fontSize: 12,
      fontWeight: "normal",
      "& .MuiInputLabel-root": {
        fontSize: "12px !important",
        fontWeight: "normal !important",
      },
      "& .MuiFilledInput-root": {
        fontSize: 12,
        fontWeight: "normal",
        borderRadius: "6px",
        backgroundColor: "white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        '&>*': {
          padding: 10
        }
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: "#E4E3E8",
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
        '& fieldset': {
          borderRadius: "6px",
          borderColor: "transparent",
          fontWeight: "normal",
          fontStyle: "normal",
          fontFamily: "'Inter', sans-serif",
          fontSize: 12,
        },
        '&:hover fieldset': {
          borderColor: '#02569D',
          borderRadius: "6px",
          fontSize: 12,
          fontWeight: "normal",
  
        },
        '&.Mui-focused fieldset': {
          borderColor: '#02569D',
          borderRadius: "6px",
          fontSize: 12,
          fontWeight: "normal",
        },
      },
    },
    rootContainer: {
        display: "flex",
        width: "100%",
        padding: '.75rem',
        height: "80vh",
    },
    moduleList: {
        borderRight: "1px solid black",
        marginRight: ".75rem",
        width: '180px',
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    mainContainer: {
      width: '100%',
      height: "100%",
      overflowY: "auto",
      marginTop: '10px'
    },
    moduleCardContainer: {
      marginBottom: '20px',
      width: "100%",
      border: `2px solid gray`,
    },
    accordianSummaryContainer: {
      display: "grid",
        width: "100%",
        gridTemplateColumns: "repeat(4,1fr)",
        paddingBlock: "0.25rem",
        columnGap: ".5rem",
        alignItems: "center",
    },
    assesmentCardInfo: {
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
        alignItems: "center",
        columnGap: ".75rem",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    templateFields: {
      height: '40px', padding: '10px'
    }
}))

const sx = {
  addModule: {
    color: '#fff'
  },
  moduleTab: {
    cursor: 'pointer'
  },
  activeModuleTab: {
    cursor: 'pointer', textDecoration: 'underline', color: '#2DABFF'
  },
  accordianHead: { display: 'flex', justifyContent: 'space-between', width: '100%' },
}

const TemplateTypes = [
  {_id: 'practice', name: 'Practice'},
  {_id: 'assignment', name: 'Assignment'},
];

const defaultModule = {
  name: 'module name',
  domain: null,
  pathway: '',
  user: '',
  introDocumentLinks: [],
  templates: [],
  masterModule: false,
  criteria: { score: 0 },
  isActive: true,
};

const defaultTemplate = {
  name: '',
  user: '',
  domain: '',
  pathway: '',
  tags: [],
  metadata:{ numberOfQuestions: 0 },
  maxAllowedAttempts: 0,
  type: 'pathway',
  isActive: true,
};

const ModuleSchema = Yup.object().shape({
  modules: Yup.array(
    Yup.object().shape({
      name: Yup.string().required(),
      pathway: Yup.string().required(),
      user: Yup.string().required(),
      masterModule: Yup.string().optional(),
      criteria: Yup.object().shape({
        score: Yup.number().min(0).max(100).required(),
      }).required(),
      introDocumentLinks: Yup.array(
        Yup.object().shape({
          docLink: Yup.string().required(),
          thumbnail: Yup.string().required(),
        })
      ).min(1).required(),
      serialNumber: Yup.number().required(),
      isActive: Yup.boolean().required(),
      templates: Yup.array(
        Yup.object().shape({
          name: Yup.string().required(),
          user: Yup.string().required(),
          domain: Yup.string().required(),
          pathway: Yup.string().required(),
          tags: Yup.array().min(1).required(),
          metadata: Yup.object({ numberOfQuestions: Yup.number().min(1).required()}),
          maxAllowedAttempts: Yup.number().min(1).required(),
          type: Yup.string().required(),
          isActive: Yup.boolean().required(),
        })
      ).min(1).required(),
    })
  )
}).transform((originalValue, originalObject) => {
  return {
    modules: originalValue.modules.map((module) => {
      const { domain, createdAt, updatedAt, __v, ...moduleData } = module;

      return moduleData;
  }),
  };
});


const Assessments=({
  modules, activeModule, template, index, handleTemplateChange, deleteTemplateFromModule, tags
})=>{
  const classes = useStyle();

  const totalTagQuestions = React.useMemo(()=>{
    if(template?.tags?.length && tags){
      const [selectedTag] = tags.filter(tag=>tag._id === template.tags[0]);

      const questions = modules[activeModule].masterModule
        ? selectedTag?.masterQuestions
        : selectedTag?.questions;

      return questions ? questions.length : 0; 
    }
  },[tags, template, activeModule, modules]);

  return(
    <Accordion style={{border: '2px solid gray', marginTop: '10px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box style={sx.accordianHead}>
          <Typography>Assessment: {index+1}</Typography>
          <DeleteIcon style={{ color: 'red', marginRight: '20px' }}
            onClick={()=> deleteTemplateFromModule(activeModule, index)}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <TextField
            name='name' label='Assesment Name' variant='outlined' className={classes.textFieldRoot}
            value={template.name}
            onChange={(e)=> handleTemplateChange(activeModule, index, e.target.name, e.target.value)}
          />
          <SimpleSelect
            label="Tags*"
            id={`tags`}
            options={tags || []}
            value={template.tags[0] || null}
            name={`tags`}
            onChange={(e)=>{
                handleTemplateChange(activeModule,index,e.target.name,[e.target.value]);
              }}
          />
          <Typography>Total Questions available: {totalTagQuestions}</Typography> 
        </Box>
        <Box style={{ display: 'flex', gap: '10px', width: '50%', alignItems: 'center' }}>
          <TextField
            name='metadata' type='number' label='No. of Questions' variant='outlined'
            className={classes.textFieldRoot}
            disabled={!totalTagQuestions}
            inputProps={{ min: 0, max: totalTagQuestions }}
            max={5}
            value={template?.metadata?.numberOfQuestions || 0}
            onChange={
              (e)=> handleTemplateChange(activeModule, index, e.target.name, 
                {numberOfQuestions: 
                  e.target.value > totalTagQuestions
                  ? template?.metadata?.numberOfQuestions : e.target.value
                })
            }
          />
          <TextField
            name='maxAllowedAttempts' type='number' label='No. of Attempts' variant='outlined'
            className={classes.textFieldRoot}
            value={template?.maxAllowedAttempts || 0}
            inputProps={{ min: 1}}
            onChange={(e)=> handleTemplateChange(activeModule, index, e.target.name, e.target.value)}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}


const ModuleEditor = () => {
    const classes = useStyle();
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const loading = useLoading();
    const { id } = useParams();
    
    const pathway = useStore(state=> state.pathway, shallow);
    const modules = useStore(state=> state.modules, shallow);
    const setModules = useStore(state=> state.setModules, shallow);
    const domainTags = useStore(state=> state.domainTags, shallow);
    const domains = useStore(state=> state.domains, shallow);
    const questions = useStore(state=> state.questions, shallow);
    const setDomainTags = useStore(state => state.setDomainTags, shallow);
    const setDomains = useStore(state => state.setDomains, shallow);
    
    const [activeModule, setActiveModule] = useState(0);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [tags, setTags] = useState([]);

    const {disableMasterChecks, masterModule}= React.useMemo(()=>{
      const master =  modules.find(module=> module?.masterModule);
      let disabled = true;

      if( master && master?._id ) disabled = true;
      else if(
          !master || 
          (master && !master?._id && master?.serialNumber === modules[activeModule]?.serialNumber)
        ) disabled = false;

      return {disableMasterChecks: disabled, masterModule: master};

    },[modules, activeModule]);

    useEffect(()=>{
      setSelectedDomain(
        modules[activeModule]?.domain
        ? modules[activeModule]?.domain 
        : modules[activeModule]?.templates?.[0]?.domain || null
      )
      
      const currDomain = 
        (modules[activeModule]?.domain || modules[activeModule]?.templates[0]?.domain) ?? [];

      setTags(domainTags[currDomain]);
    },[modules, activeModule, domainTags]);

    React.useEffect(() => {
      if(!pathway) return;
      (async () => {
        try {
          loading.show();
          const { pathwayTagMappings } = await getPathwayTagMappings({
              pathway: pathway?._id, include: ["tag", "domain"]
            });
          
          let domainTagsMap = {};
          let domains = [];
          pathwayTagMappings.forEach(({domain, tag, questions=[], masterQuestions=[]})=>{
            
            if(domain){
              const newData = { ...tag, questions, masterQuestions}
  
              if(domainTagsMap[domain._id]){
                domainTagsMap[domain._id].push(newData);
              }else{
                domains.push(domain);
                domainTagsMap[domain._id] = [newData];
              }
            }
          });
  
          setDomains(domains);
          setDomainTags(domainTagsMap);
        } catch (error) {
          snackbar.showSnackbar(error.message, 'error', false);
        } finally {
          loading.hide();
        }
      })();
    }, [questions, id]);

    const handleModuleSubmit = async () => {
      try {
        loading.show();
        const validData = await ModuleSchema.validate({ modules: modules }, { abortEarly: false});
        if (!validData) {
          snackbar.error(
            "Seems like you have some invalid data!! Please check the student list"
          );
          return;
        }
        
        await saveModule(validData);
        navigate(`/pathways/list`);
        snackbar.showSnackbar("Module saved successfully !!");
      } catch (error) {
        console.error(error.inner || error.message);
        snackbar.showSnackbar(error.inner?.[0]?.errors?.[0] || error.message, "error");
      } finally{
        loading.hide();
      }
    };

    const handleModuleChange = (moduleIndex, field, value) => {
        let updatedModules = modules.map((module, currentIndex) => {
          return currentIndex === moduleIndex ? { ...module, [field]: value } : module;
        });

        if(['domain', 'isActive', 'masterModule'].includes(field)){
          updatedModules = updatedModules.map((module, currentIndex) => {
            if (currentIndex === activeModule) {
              return {
                ...module,
                templates: module.templates.map((template,i) => {
                  const newData = (field !== 'masterModule') ? { [field]: value } : {};
                    return({
                      ...template, ...newData, tags: field!=='isActive' ? [] : template.tags 
                    });
                  }),
                };
            } else {
              return module;
            }
          });
        }

        setModules(updatedModules);
    };

    const handleTemplateChange = (moduleIndex, templateIndex, field, value) => {
      const updatedModules = modules.map((module, currentIndex) => {
        if (currentIndex === moduleIndex) {
          return {
            ...module,
            templates: module.templates.map((template, currentTemplateIndex) => {
                if (currentTemplateIndex !== templateIndex) return template;

                const updatedTemplate = { ...template, [field]: value };
                if (field === 'tags') {
                  updatedTemplate.metadata = { numberOfQuestions: 0 };
                }
                return updatedTemplate;
              }),
            };
        } else {
          return module;
        }
      });

      setModules(updatedModules);
    };

    const addNewModule=()=>{
      setModules([...modules, 
        {
          ...defaultModule, name: `module ${modules.length+1}`,
          pathway: pathway._id, user: pathway?.user, serialNumber: modules.length + 1
        }
      ]);
      setActiveModule(modules.length);
    }

    const addNewTemplate = () => {
      const updatedModules = modules.map((module, currentIndex) => {
        return (currentIndex === activeModule) ?
          { ...module, 
            templates: [
              ...module.templates, 
              { 
                ...defaultTemplate, pathway: pathway._id, user: pathway?.user, 
                domain: modules[activeModule]?.domain || modules[activeModule]?.templates[0]?.domain,
              }
            ] 
          }
          :
          module;
      });

      setModules(updatedModules);
    };

    const deleteTemplateFromModule = (moduleIndex, templateIndex) => {
      if(modules[moduleIndex].templates[templateIndex]?._id){
        handleTemplateChange(moduleIndex, templateIndex, 'isActive', false);
      }
      else{
        const updatedModules = modules.map((module, currentIndex) => {
          return (currentIndex === moduleIndex)?
          {
            ...module,
            templates: module.templates.filter((_, currentTemplateIndex) => {
              return currentTemplateIndex !== templateIndex;
            }),
          }
          :
          module;
        });
      
        setModules(updatedModules);
      }
    };

    const deleteModule = () => {
      if(modules[activeModule]?._id){
        handleModuleChange(activeModule, 'isActive', false);
      }
      else{
        const updatedModules = modules.filter((_, currentIndex) => {
          return currentIndex !== activeModule;
        });
        setModules(updatedModules);
      }
      setActiveModule(activeModule-1)
    };

    React.useEffect(() => {
      (async () => {
          try {
            const filters = {
              pathway: id, 
              orderBy: 'serialNumber', order: 'asc',
              include: ['templates'].toString()
            }
            if (id && id !== 'create') {
              const result = 
                await getPathwayModules(filters);
              setModules(result);
            }
          } catch (error) {
              snackbar.showSnackbar(error.message, 'error', false);
          }
      })();
    }, [id]);

    const goBack = () => navigate("/pathways/list");

    const introDocumentLinks = React.useMemo(()=>{
      return modules[activeModule]?.introDocumentLinks[0];
    },[modules, activeModule]);

    return (
      <Box className={classes.rootContainer}>
        <Box className={classes.moduleList}>
          <Box>
            {
              modules?.map((module,index)=>{
                if(module?.isActive === false) return null;
                return(
                  <Typography 
                    key={index}
                    style={activeModule===index? sx.activeModuleTab : sx.moduleTab}
                    onClick={()=>setActiveModule(index)}
                  >
                    {module.name}
                  </Typography>
                );
              })
            }
            <CustomButton variant='contained' style={{ marginTop: '10px'}} onClick={addNewModule}>
              Add
            </CustomButton>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button variant="contained" type='submit' style={{ color: '#fff' }}
              onClick={handleModuleSubmit}
            >
                Submit
            </Button>
            <CustomButton variant="contained" style={{backgroundColor: 'red'}}
              onClick={goBack}
            >
                Cancel
            </CustomButton>
          </Box>
        </Box>
        {
          modules?.length ?
          <Box className={classes.mainContainer}>
            <Box display='flex' gap='10px' alignItems='center' mt={1}>
              <TextField
                name='name' className={classes.textFieldRoot}
                label='Module Name' variant='outlined'
                value={modules[activeModule]?.name}
                onChange={(e)=>handleModuleChange(activeModule,e.target.name,e.target.value)}
              />
              <SimpleSelect
                label="Domain*"
                id={`domain`}
                options={domains}
                value={selectedDomain || null}
                name={`domain`}
                onChange={(e)=>handleModuleChange(activeModule,e.target.name,e.target.value)}
              />
              <TextField
                name='criteria' type='number' label='Passing Score' variant='outlined'
                className={classes.textFieldRoot}
                inputProps={{ min: 0, max: 100 }}
                max={5}
                disabled={
                  !(modules.length && modules[activeModule]?.serialNumber === masterModule?.serialNumber)
                }
                value={modules[activeModule]?.criteria?.score}
                onChange={
                  (e)=> handleModuleChange(activeModule, e.target.name, 
                    {score: 
                      e.target.value > 100
                      ? modules[activeModule]?.criteria?.score : e.target.value
                    })
                }
              />
              <CheckboxOptions
                name='module type'
                options={['master']}
                disabled={disableMasterChecks}
                value={modules[activeModule]?.masterModule ? 'master' : ''}
                onChange={(e)=>{
                  if(modules[activeModule]?.masterModule){
                    handleModuleChange(activeModule,'masterModule',false)
                  }else{
                    handleModuleChange(activeModule,'masterModule',true)
                  }
                  }}
              />
              <DeleteIcon style={{ color: 'red', marginLeft: '10px', cursor: 'pointer'}}
                onClick={deleteModule}
              />
            </Box>
            <Box display='flex' gap='10px' alignItems='center'>
              <FileUploadInput
                name="document"
                label="Module document"
                file={{
                    name: introDocumentLinks?.docLink,
                    url: introDocumentLinks?.docLink,
                }}
                onUploadCancelled={() => {}}
                onUpload={(file) => {
                  handleModuleChange(
                    activeModule, 'introDocumentLinks',
                    [{
                      'thumbnail': introDocumentLinks?.thumbnail,
                      'assets': introDocumentLinks?.assets,
                      'docLink': file.url
                    }]
                    )
                }}
                toPublic={true}
                uploadLocation='uploads/htmls'
              />
              <FileUploadInput
                name="thumbnail"
                label="Thumbnail"
                file={{
                    name: introDocumentLinks?.thumbnail,
                    url: introDocumentLinks?.thumbnail,
                }}
                onUploadCancelled={() => {}}
                onUpload={(file) => {
                  handleModuleChange(
                    activeModule, 'introDocumentLinks',
                    [{
                      'docLink': introDocumentLinks?.docLink,
                      'assets': introDocumentLinks?.assets,
                      'thumbnail': file.url
                    }]
                    )
                }}
                toPublic={true}
                uploadLocation='images'
              />
              <FolderUploadInput
                name="assets"
                label="Document Assets"
                folder = {introDocumentLinks?.assets}
                
                onUploadCancelled={() => {}}
                onUpload={(file) => {
                  handleModuleChange(
                    activeModule, 'introDocumentLinks',
                    [{
                      'thumbnail': introDocumentLinks?.thumbnail,
                      'docLink': introDocumentLinks?.docLink,
                      'assets': file.url
                    }]
                    )
                }}
                toPublic={true}
                uploadLocation='uploads/htmls'
              />
              
            </Box>
            {
              modules[activeModule]?.templates.map((assessment,index)=>{
                if(assessment?.isActive === false) return null;
                return(
                  <Assessments 
                    modules={modules} activeModule={activeModule} template={assessment} 
                    index={index} handleTemplateChange={handleTemplateChange}
                    deleteTemplateFromModule={deleteTemplateFromModule}
                    tags={tags}
                  />
                )
              })
            }
            <Box style={{ textAlign: 'center', marginTop: '20px'}}>
              <Button variant='contained' style={{ color: '#fff', height: '50px' }}
                onClick={addNewTemplate}
              >
                Add Assessment
              </Button>
            </Box>
          </Box>
          : null
        }
      </Box>
    )
}

export default ModuleEditor;
import React from 'react';
import * as Yup from 'yup';
import GSheetReader from "g-sheets-api";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormikForm from 'components/FormikForm';
import { useLoading, useSnackbar } from "contexts";
import CustomButton from 'components/CustomButton';
import { Tooltip } from '@mui/material';

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const container = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  overflow: 'auto',
  minWidth: 650,
};

function GoogleSheetImport({
  title = "Import from Google Sheets",
  open,
  onSuccess,
  onClose = () => { },
  sampleSheetUrl,
  tagCreation
}) {
  const snackbar = useSnackbar();
  const loading = useLoading();
  const [error, setError] = React.useState(false);

  const handleSave = async ({ sheetUrl, sheetName }) => {
    try {
      loading.show();

      setError(false);

      const sheetId = sheetUrl.substring(
        sheetUrl.indexOf("/d/") + 3, sheetUrl.lastIndexOf("/")
      );

      const options = {
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId,
        sheetName,
        sheetNumber: 1,
        returnAllResults: true,
      };

      GSheetReader(
        options,
        results => {
          loading.hide();

          if(!tagCreation){
            snackbar.showSnackbar("Imported sheet data successfully", "success", true);
          }

          if (onSuccess && typeof onSuccess === 'function') onSuccess(results);
        },
        error => {
          loading.hide();

          setError(true);

          console.error(error);

          snackbar.showSnackbar(
            "Unable to import your data! Something went wrong!", "error", true
          );
        },
      );
    } catch (error) {
      console.log(error);
      snackbar.showSnackbar(
        "Uh Oh! Unable to import data from sheet", "error", true
      );
      setError(true);
      loading.hide();
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth='lg'>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent sx={container}>
        <Typography variant="body2" fontWeight={"bold"} color="error">
          Setup Google Sheet
        </Typography>
        <Typography variant="body2">
        </Typography>
        <ol style={{ fontSize: 12 }}>
          <li>You'll need to make the sheet access available to import questions.</li>
          <li>In the top right of the screen, hit the <b>Share</b> button.</li>
          <li>Change the dropdown option to <b>Anyone with the link</b>.</li>
          <li>Make sure to leave the right hand drop down on <b>Viewer</b>, this way your sheet will remain read-only to the internet.</li>
          <li>Click <b>Done</b></li>
        </ol>

        <FormikForm
          primaryLabel="Import"
          initialValues={{
            sheetUrl: "",
            sheetName: "",
          }}
          fields={[
            {
              name: 'sheetUrl',
              type: 'text',
              label: 'Google Sheet URL',
              xs: 12,
              md: 12,
            },
            {
              name: 'sheetName',
              type: 'text',
              label: 'Sheet Name',
              xs: 12,
              md: 12,
            },
          ]}
          validationSchema={Yup.object().shape({
            sheetUrl: Yup.string().trim().url().required(),
            sheetName: Yup.string().trim().optional(),
          })}
          onSubmit={handleSave}
          onCancel={onClose}
        />

        {error && (
          <Box mt={1}>
            <Typography variant="subtitle2" color="error">
              Unable to import the data from the sheet,
              It could be possible that:
            </Typography>
            <ol style={{ fontSize: 12 }}>
              <li>{`You've entered an incorrect Sheet URL or 
                Sheet Name (Default is 'Sheet1').`}</li>
              <li>{`The Sheet isn't publicly available yet 
              (it's not an instant publish process from Google).`}</li>
              <li>Some other in-transit error</li>
            </ol>
          </Box>
        )}
        {sampleSheetUrl && (
          <Tooltip
            title="Open and Select 'File > Make a copy', to create a copy and add the questions"
          >
            <CustomButton
              href={sampleSheetUrl}
              target="_blank"
              rel="noreferrer"
              referrerPolicy='origin'
              component="a"
              variant="outlined"
              color="success"
              width="fit-content"
              sx={{ my: 2 }}
            >
              Sample Sheet
            </CustomButton>
          </Tooltip>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default GoogleSheetImport;
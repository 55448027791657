import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from 'utils';
import { getTransactionOfClient } from 'services';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: 562,
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    clientButton: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        borderRadius: '5px',
        marginBottom: '10px'
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    creditRow: {
        color: theme.palette.success.main,

    },
    debitRow: {
        color: theme.palette.error.main,
    }
}));

export default function TransactionsDialog({ clientId, open = true, onClose }) {

    const classes = useStyles();
    const [rowData, setRowData] = React.useState([]);
    const columns = React.useMemo(() => {
        return [
            {
                field: 'id',
                headerName: "Transaction Id",
                width: 200,
                headerClassName: classes.tableHeader
            },
            {
                field: 'type',
                headerName: "Type",
                width: 150,
                headerClassName: classes.tableHeader,
            },
            {
                field: 'createdAt',
                headerName: "Date",
                width: 150,
                headerClassName: classes.tableHeader,
                valueFormatter: (value) => {
                    return formatDate(value.value);
                }
            },
            {
                field: 'amount',
                headerName: "Amount",
                width: 150,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const type = params?.row?.type;
                    return (type === 'credit' ? `+${params.row.amount}` : `- ${params.row.amount}`)
                },
                cellClassName: (params) => {
                    return params.row.type === 'credit' ? classes.creditRow : classes.debitRow
                },
            },
            { field: 'status', headerName: "Status", width: 150, headerClassName: classes.tableHeader, },
        ]
    }, [open])

    useEffect(() => {
        (async () => {
            if (!clientId) return;

            const data = await getTransactionOfClient(clientId);

            const modifiedData = data.map((el) => {
                return { ...el, id: el._id };
            })
            setRowData(modifiedData);
        })()
    }, [clientId]);

    return (
        <Dialog fullScreen={false}
            open={open}
            maxWidth={"1300px"}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Transactions
            </DialogTitle>
            <DialogContent >
                <DataGrid columns={columns}
                    rows={rowData}
                    sx={{
                        width: "802px",
                        minHeight: "450px"
                    }} />
                <DialogActions><Button variant="outlined" color='error' onClick={onClose}>
                    Close
                </Button></DialogActions>
            </DialogContent>
        </Dialog>
    )
}

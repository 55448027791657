import Client from "./_client";

export const getBatches = async (batchFilters = {}) => {
    let url = "/batch?";

    Object.keys(batchFilters).forEach((key) => {
        if (batchFilters[key]) {
            url += `${key}=${batchFilters[key]}&`;
        }
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get batches. Please try again."
        );
    }

    return result?.data;
};

export const getBatchById = async (batchId) => {
    const url = `/batch/${batchId}`;

    let result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get batch. Please try again."
        );
    }

    return result.data;
}

export const saveBatch = async (batchId, batch) => {
    let result;
    if (batchId) {
        result = await new Client({
            path: `/batch/${batchId}`,
            payload: batch,
        }).put();
    } else {
        result = await new Client({
            path: `/batch`,
            payload: batch,
        }).post();
    }
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save batch. Please try again."
        );
    }

    return result.data;
}
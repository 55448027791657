import React from "react";
import ReactDOM from "react-dom";
import Screen from "./screens/index";
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Screen />
  </ React.StrictMode>,
  document.getElementById("root")
);


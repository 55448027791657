import Button from '@mui/material/Button';

function CustomButton(props) {
  const {disabled}=props;
  return (
    <Button {...props} 
      disabled={disabled}
    sx={{
      width: `${props.width}`,
      ...(props.variant === 'contained' ? { color: "#FFFFFF" } : {}),
      cursor: 'pointer',
      fontFamily: 'Open sans',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontSize: 14,
      textTransform: 'none',
      margin: '4px',
    }}
    />
  );
}

export default CustomButton;
import * as React from 'react';
import throttle from 'lodash/throttle';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getUsers } from "services/users";
import { ROLES } from "config";

const useStyles = makeStyles((theme) => ({
  error: {
    marginLeft: 16,
  },
  root: {
    margin: "8px 0",
    borderRadius: "6px",
    fontSize: 12,
    fontWeight: "normal",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "6px",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#E4E3E8",
      borderRadius: "6px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "6px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Inter', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",

      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  },
}));

const ApplicationRolesMap = {
  "extension": [ROLES.EXTENSION_USER],
  "interview": [ROLES.BETA_USER, ROLES.STUDENT, ROLES.INSTRUCTOR, ROLES],
  "admin": [ROLES.ADMIN, ROLES.SALES],
};

function UserAutoComplete({ application, field = "email", onSelect = () => { } }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      throttle((value, callback) => {
        getUsers({ field: value, roleNames: ApplicationRolesMap[application] })
          .then(({ users }) => callback(users))
          .catch((error) => {
            console.log(error)
          });
      }, 1000),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    if (value) onSelect(value);
  }, [value]);

  return (
    <Autocomplete
      size="small"
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={(option) => option.name || option.email}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Search User"
          placeholder={`Type ${field} here...`}
          className={classes.root}
          fullWidth
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Box >
            <Typography variant="body2">{option.name || option.email}</Typography>
            <Typography variant="subtitle2">{option.email}</Typography>
          </Box>
        </li>
      )}
    />
  );
}

export default UserAutoComplete;
import React from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import ContentShell from "components/ContentShell";
import InterviewTemplateEditor from "components/forms/TemplateEditor";
import InterviewTemplates from "components/lists/Templates";
import SEPTemplateEditor from "components/forms/SEPTemplateEditor";
import SEPTemplates from "components/lists/SEPTemplates";
import StarTemplateEditor from "components/forms/StarTemplateEditor";
import MockSections from "components/lists/MockSections";
import MockSectionCreator from "components/forms/MockSectionEditor";

export default function Templates() {
    return (
        <Routes>
            <Route path="/" element={<TemplatesLayout />}>
                <Route index element={<Navigate to='inprep' />} />
                <Route path='inprep' element={<InterviewTemplates />} />
                <Route
                    path="inprep/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <InterviewTemplateEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path='sep'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SEPTemplates />
                        </React.Suspense>
                    }
                />
                <Route
                    path="sep/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SEPTemplateEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path="mock"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <MockSections />
                        </React.Suspense>
                    }
                />
                <Route
                    path="mock/create"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <MockSectionCreator />
                        </React.Suspense>
                    }
                />
                <Route
                    path="mock/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <MockSections />
                        </React.Suspense>
                    }
                />
                <Route
                    path='star'
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <StarTemplateEditor />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

const SideBarLinks = [
    {
        label: "Inprep",
        path: 'inprep',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "SEP",
        path: 'sep',
        allowedRoles: ["ADMIN", "SALES", "PRODUCT"],
    },
    {
        label: "Mock Sections",
        path: 'mock',
        allowedRoles: ["ADMIN", "SALES", "PRODUCT"],
    },
    {
        label: "STAR",
        path: 'star',
        allowedRoles: ["ADMIN", "SALES", "PRODUCT"],
    },
];

function TemplatesLayout() {
    return (
        <ContentShell sideBarLinks={SideBarLinks}>
            <Outlet />
        </ContentShell>
    );
}
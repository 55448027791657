import React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function FilterList({children,width}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="basic-button"
                variant='outlined'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Add Filter
            </Button>
            <Menu
                anchorOrigin={{
                    horizontal:'left',
                    vertical:"bottom"
                }}
                transformOrigin={{
                    horizontal:"right"
                }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {children.map((el)=>{
                    return <MenuItem sx={
                        {
                        width:width ? width :"100%"
                        }
                    }
                    onKeyDown={event=>event.stopPropagation()}
                    >
                        {el}
                    </MenuItem>
                })}
            </Menu>
        </div>
    )
}

export default FilterList;
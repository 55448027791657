import React from 'react';
import { useSearchParams } from 'react-router-dom';
import CustomButton from './CustomButton';
import { SimpleTextField } from './FormTextField';

function IFrame({ initialUrl = process.env.REACT_APP_INTERVIEW_URL }) {
  const [searchParams] = useSearchParams();

  const [value, setValue] = React.useState(initialUrl);
  const [url, setUrl] = React.useState(initialUrl);

  const handleSubmit = (e) => {
    e.preventDefault();
    setUrl(value);
  };

  React.useEffect(() => {
    const messageHandler = (e) => {
      const data = e.data;
      if (["CLIENT_REDIRECT_INVOKE", "ASSESSMENT_SUBMITTED"].includes(data)) {
        console.log("EVENT_FROM_SEP: ", e);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => window.removeEventListener('message', messageHandler);
  });

  React.useEffect(() => {
    const _url = searchParams.get("url");
    setValue(_url);
    setUrl(_url);
  }, [searchParams]);

  const IframeComponent = React.useCallback(() => {
    return url ? (
      <div style={{ width: "100%", height: "80vh", boxSizing: "border-box", border: "1px solid green" }}>
        <iframe
          title="Inprep"
          src={url}
          style={{ width: "100%", height: '80vh', border: "4px solid red" }}
          allow="camera;microphone;fullscreen;"
          frameBorder={0}
        />
      </div>
    ) : null;
  }, [url]);

  return (
    <div
      style={{
        height: "-webkit-fill-available",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <form onSubmit={handleSubmit} noValidate style={{ display: "flex", alignItems: "center" }}>
        <SimpleTextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="Paste the url here to view Iframe"
        />
        <CustomButton type="submit" variant="contained" >
          View
        </CustomButton>
      </form>
      <IframeComponent />
    </div>
  );
}

export default IFrame;
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import CustomButton from 'components/CustomButton';
import { useSnackbar, useLoading } from "contexts";
import { TextField } from '@mui/material';
import ClientSelect from 'components/ClientSelect';
import { duplicatePathway } from 'services/pathwayService';

const duplicatePathwaySchema = Yup.object().shape({
  name: Yup.string().required('Name Required'),
  user: Yup.string().required("Select a Client"),
})

function DuplicatePathway({
    open,
    item,
    onClose = () => { },
    onSuccess = () => { },
    type
}) {
    const snackbar = useSnackbar();
    const loading = useLoading();

    const [duplicatePathwayData, setDuplicatePathway] = React.useState({});

    const handleDuplicate = async () => {
        try {
          const valid = await duplicatePathwaySchema.isValid(duplicatePathwayData, { abortEarly: false});
          if (!valid) {
            snackbar.error("Seems like you have some invalid data!! Please check the student list");
            return;
          }
            let result= await duplicatePathway(item._id, duplicatePathwayData);
            if(result) {
              onSuccess();
              onClose();
            }
        } catch (error) {
            snackbar.showSnackbar(error.message, "error", true);
        }
        finally {
            loading.hide();
        }
    }

    React.useEffect(()=>{
      setDuplicatePathway({name: `copy ${item.name}`});
    },[open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle >
                Duplicate Pathway
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 500, maxWidth: 900, overflow: "hidden" }}>
              <Box mb='20px'>
                <TextField name="name" label="Name" value={ duplicatePathwayData?.name}
                  onChange={(e)=> setDuplicatePathway({...duplicatePathwayData, name: e.target.value})}
                />
                <ClientSelect
                  defaultValue={duplicatePathwayData.user} 
                  filter={[item.user]}
                  onChange={(e)=>{setDuplicatePathway({...duplicatePathwayData, user: e._id})}}
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <CustomButton
                  variant="outlined"
                  onClick={handleDuplicate}
                >
                  Duplicate
                </CustomButton>
                <CustomButton
                  style={{color: 'red', borderColor: 'red'}}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </CustomButton>
              </Box>
            </DialogContent>
        </Dialog>
    );
}

export default DuplicatePathway;
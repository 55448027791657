import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import ContentShell from "components/ContentShell";
import Clients from "components/lists/Clients";
import SEPClients from "components/lists/SEPClients";
import ClientForm from "components/forms/ClientForm";
import SEPClientForm from "components/forms/SEPClientForm";
import MockClients from "components/lists/MockClients";
import MockClientForm from "components/forms/MockClientForm";

export default function ClientUsers() {
    return (
        <Routes>
            <Route path="/" element={<ClientUsersLayout />}>
                <Route index element={<Navigate to="inprep" />} />
                <Route path='inprep' element={<Clients />} />
                <Route path='inprep/:id' element={<ClientForm />} />

                <Route
                    path="sep"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SEPClients />
                        </React.Suspense>
                    }
                />
                <Route path='sep/:id' element={<SEPClientForm />} />

                <Route
                    path="mock"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <MockClients />
                        </React.Suspense>
                    }
                />
                <Route path='mock/:id' element={<MockClientForm />} />
            </Route>
        </Routes>
    );
}

const SideBarLinks = [
    {
        label: "InPrep",
        path: 'inprep',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "SEP",
        path: 'sep',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Mock",
        path: 'mock',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
];

function ClientUsersLayout() {
    return (
        <ContentShell sideBarLinks={SideBarLinks}>
            <Outlet />
        </ContentShell>
    );
}
import React from 'react';
import * as Yup from 'yup';
import GSheetReader from "g-sheets-api";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useLoading, useSnackbar } from "contexts";
import CustomButton from 'components/CustomButton';
import { Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';

const sx = {
  fieldBox: { display: 'flex', flexDirection: 'column', marginBottom: '20px', gap: '10px'},
  fieldBtn: { display: 'flex', gap: '10px', justifyContent: 'flex-end'}
}

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const container = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  overflow: 'auto',
  minWidth: 650,
};

function GoogleSheetImport({
  title = "Import from Google Sheets", handleImport, sampleSheetUrl
}) {
  const snackbar = useSnackbar();
  const loading = useLoading();
  const [error, setError] = React.useState(false);

  const handleSave = async ({ sheetUrl, sheetName }) => {
    try {
      loading.show();

      setError(false);

      const sheetId = sheetUrl.substring(
        sheetUrl.indexOf("/d/") + 3, sheetUrl.lastIndexOf("/")
      );

      const options = {
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId, sheetNumber: 1, sheetName, returnAllResults: true,
      };

      GSheetReader(
        options,
        results => {
          loading.hide();
          if (handleImport && typeof handleImport === 'function') handleImport(results);
        },
        error => {
          loading.hide();
          setError(true);
          snackbar.showSnackbar(
            "Unable to import your data! Something went wrong!", "error", true
          );
        },
      );
    } catch (error) {
      console.log(error);
      snackbar.showSnackbar(
        "Uh Oh! Unable to import data from sheet", "error", true
      );
      setError(true);
      loading.hide();
    }
  };

  const initialValues = {
    sheetUrl: "",
    sheetName: "",
  };
  const validationSchema = Yup.object().shape({
    sheetUrl: Yup.string().trim().url().required(),
    sheetName: Yup.string().trim().required(),
  });
  
  const handleCancel = (formik) => {
    formik.resetForm();
  };

  return (
    <Box>
      <Box sx={container}>
        <Typography style={{ marginTop: '10px'}}>{title}</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {(formik) => (
            <Form>
              <Box style={{ padding: '10px'}}>

              <Box style={sx.fieldBox}>
                <Field type="text" name="sheetUrl" label="Google Sheet URL" 
                  placeholder="Google Sheet URL" style={{padding: '5px'}} 
                />
                <Field type="text" name="sheetName" label="Sheet Name" 
                  placeholder="Sheet Name" style={{padding: '5px'}} 
                />
              </Box>

              <Box style={sx.fieldBtn}>
                <Button variant='contained' style={{ color: '#fff'}} type="submit" 
                  disabled={!formik.dirty || !formik.isValid}
                >
                  Import
                </Button>
                <Button variant='contained' style={{ color: '#fff', backgroundColor: '#c22121'}} 
                  type="button" onClick={() => handleCancel(formik)}
                >
                  Cancel
                </Button>
              </Box>
              </Box>
            </Form>
          )}
        </Formik>

        {error && (
          <Box mt={1}>
            <Typography variant="subtitle2" color="error">
              Unable to import the data from the sheet,
              It could be possible that:
            </Typography>
            <ol style={{ fontSize: 12 }}>
              <li>{`You've entered an incorrect Sheet URL or 
                Sheet Name (Default is 'Sheet1').`}</li>
              <li>{`The Sheet isn't publicly available yet 
              (it's not an instant publish process from Google).`}</li>
              <li>Some other in-transit error</li>
            </ol>
          </Box>
        )}
        {sampleSheetUrl && (
          <CustomButton
            href={sampleSheetUrl}
            target="_blank"
            rel="noreferrer"
            referrerPolicy='origin'
            component="a"
            variant="outlined"
            color="success"
            width="fit-content"
            sx={{ my: 2 }}
          >
            Sample Sheet
          </CustomButton>
        )}
      </Box>
    </Box>
  );
}

export default GoogleSheetImport;
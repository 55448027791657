import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import ContentHeader from 'components/ContentHeader';
import FormikForm from "components/FormikForm";
import { useSnackbar } from "contexts";
import { createAmbassadorUser } from "services";

const phoneRegExp = /^([\\+]*)((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const fieldOptionsValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required').min(3),
    email: Yup.string().email().required("Required"),
    contact: Yup.string()
        .matches(phoneRegExp, 'Invalid phone number')
        .required('Required'),
    city: Yup.string().optional(),
    application: Yup.string().required('Required').trim().oneOf(['mock', 'sep']),
    referralCode: Yup.string().trim().min(3).max(50),
});

const fields = [
    {
        name: 'name',
        type: 'text',
        label: 'Name'
    },
    {
        name: 'email',
        type: 'email',
        label: 'Email'
    },
    {
        name: 'contact',
        type: 'text',
        label: 'Contact'
    },
    {
        name: 'city',
        type: 'text',
        label: 'City'
    },
    {
        name: 'application',
        type: 'select',
        label: 'Application'
    },

    {
        name: 'referralCode',
        type: 'text',
        label: 'Referral Code (Leave blank for auto-generation)'
    }
];

export const formOptions = {
    application: [{ "_id": 'mock', name: "IELTS/TOEFL" }, { _id: 'sep', name: "SEP Resume" }]
};

export default function CAPEditor() {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = React.useState({
        name: '', email: '', contact: '', city: '', referralCode: '',
        application: 'mock'
    });

    const { id } = useParams();

    async function createCAPUser(values) {
        try {
            if (id === "create") {
                await createAmbassadorUser({
                    name: values.name,
                    email: values.email,
                    contact: values.contact,
                    metadata: { city: values.city || 'N/A' },
                    referralCode: values.referralCode || '',
                    application: values.application,
                });

                snackbar.showSnackbar(
                    "Campus Ambassador created successfully", 'success'
                );
            }
            else
                snackbar.showSnackbar("You can view only!!", 'error');

            navigate("/users/cap");
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error');
        }
    }

    function goBack() {
        navigate("/users/cap");
    }

    return (
        <>
            <ContentHeader title={"Campus Ambassador"} />
            {((id !== "create" && initialValues.option !== "") || id === "create") &&
                <FormikForm
                    initialValues={initialValues}
                    fields={fields}
                    formOptions={formOptions}
                    validationSchema={fieldOptionsValidationSchema}
                    onSubmit={createCAPUser}
                    onCancel={goBack}
                />}
        </>
    );
}
import * as React from 'react';
import { useField } from "formik";
import { makeStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles((theme) => ({
  radioGroupContainer: {
    padding: "0 8px",
  },
  radioGroupRoot: {
    padding: "8px",
    margin: "0",
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    width: "-webkit-fill-available",
    display: "flex",
    flexDirection: "row",
  },
  formControlLabel: {
    fontSize: 8
  },
}));

export function SimpleRadio(props) {
  return (
    <Radio
      size='small'
      sx={{
        '&.MuiRadio-root': {
          padding: 0,
          margin: 0,
        },
      }}
      {...props}
    />
  );
}

export function FormRadioButtonGroup({
  direction = "row",
  options = [],
  ...props
}) {
  const classes = useStyles();
  const [field, meta] = useField(props);

  const isError = !!(meta.touched && meta.error);

  return (
    <div className={classes.radioGroupContainer}>
      <FormControl
        className={classes.radioGroupRoot}
        style={{
          borderColor: isError ? "#f55446" : "#c4c4c4"
        }}
      >
        <FormLabel
          id="form-radio-buttons-group-label"
          fontSize="small"
        >
          <Typography
            fontSize={16}
            fontWeight="light"
            color="black"
            display="flex"
            alignItems="flex-start"
          >
            Gender
          </Typography>
        </FormLabel>
        <RadioGroup
          row={direction === "row"}
          aria-labelledby="form-radio-buttons-group-label"
          name={props.name}
          {...field}
          sx={{ marginLeft: 2 }}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              componentsProps={{ typography: { fontSize: 12, pl: 1 } }}
              value={option._id}
              label={option.label}
              className={classes.formControlLabel}
              control={<SimpleRadio size='small' disabled={props.readOnly} />}
            />
          ))}
        </RadioGroup>
      </FormControl >
      {isError && (
        <FormHelperText
          error
          component="span"
        >
          {meta.error}
        </FormHelperText>
      )}
    </div>
  );
}
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormHelperText, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    error: {
        marginLeft: 16,
    },
    root: {
        margin: "8px 0",
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
        "& .MuiInputLabel-root": {
            fontSize: "12px !important",
            fontWeight: "normal !important",
        },
        "& .MuiFilledInput-root": {
            fontSize: 12,
            fontWeight: "normal",
            borderRadius: "6px",
            backgroundColor: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            '&>*': {
                padding: 10
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "#E4E3E8",
            borderRadius: "6px",
            fontSize: 12,
            fontWeight: "normal",
            '& fieldset': {
                borderRadius: "6px",
                borderColor: "transparent",
                fontWeight: "normal",
                fontStyle: "normal",
                fontFamily: "'Inter', sans-serif",
                fontSize: 12,
            },
            '&:hover fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: "normal",

            },
            '&.Mui-focused fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: "normal",
            },
        },
    },
}));

const typeList = [
    { name: "Toefl", value: "toefl" },
    { name: "ielts", value: "ielts" }
];
const nameList = [
    { name: "Reading", value: "Reading" },
    { name: "Writing", value:  "Writing" },
    { name: "Listening", value:  "Listening" },
];
const formList = [
    { name: "Short", value: true },
    { name: "Long", value:  false },
];

function MockSelect({
     onChange = (value,type) => { }, error = "", type = "mockType",label
}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [list, setList] = React.useState([]);
    React.useEffect(()=>{
        switch(type){
            case "name":
                setList(nameList)
                break;
            case "short":
                setList(formList)
                break;
            default:
                setList(typeList)
        }
    },[type])
    

    const handleRoleChange = (event, newValue) => {
        setValue(newValue);
        onChange(newValue.value,type);
    }

    return (
        <>
            <Autocomplete
                fullWidth
                size="small"
                options={list}
                value={value}
                onChange={handleRoleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={`Choose a ${label 
                        }`}
                        className={classes.root}
                        error={!!error}
                    />
                )}
                getOptionLabel={(role) => `${role.name}`}
                renderOption={(props, option) => (
                    <Typography
                        {...props}
                        component="li"
                        sx={{ fontSize: 14, fontWeight: "normal", color: "inherit" }}
                    >
                        {option.name}
                    </Typography>
                )}
            />
            {!!error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
        </>
    );


}

export default MockSelect
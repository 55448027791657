import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import DescriptionIcon from '@mui/icons-material/Description';

import CustomButton from 'components/CustomButton';
import GSheetImportDialog from './GSheetImportDialog';
import { useLoading, useSnackbar } from 'contexts';
import CSVReaderInput from 'components/CSVReaderInput';
import { SimpleTextField } from 'components/FormTextField';
import { sendBulkInvites } from "services";

function Invites({
  values = [],
  onAdd = () => { },
  onDelete = () => { },
}) {
  const [value, setValue] = React.useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
  }

  const handleAddItemAndResetText = () => {
    onAdd(value);
    setValue("");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAddItemAndResetText();
  }

  return (
    <fieldset style={{
      paddingTop: 0, padding: 4, background: "#e4e3e8", borderRadius: 4,
      borderColor: '#C4C4C4',
    }}
    >
      <legend style={{ fontSize: 12 }}>Add Invites</legend>
      <Box
        display="flex"
        alignItems="center"
        component={"form"}
        onSubmit={handleSubmit}
        autocomplete="on"
      >
        <SimpleTextField
          fullWidth
          required
          size="small"
          placeholder="Type Name<email@address.com> to add..."
          value={value}
          onChange={handleChange}
          sx={{ m: 0, border: "1px solid grey" }}
        />
        <CustomButton
          type="submit"
          variant="outlined"
          disabled={value.trim() === ""}
          size="small"
        >
          Add
        </CustomButton>
      </Box>
      <Stack direction="row" flexWrap="wrap" pt={1}>
        {values.map(({ email, name }, index) => (
          <Chip
            size="small"
            variant="outlined"
            key={index}
            label={`${name}<${email}>`}
            sx={{ m: 0.2 }}
            onDelete={() => onDelete(index)}
          />
        ))}
      </Stack>
    </fieldset>
  );
}

export function InviteListAction({ template, onSelect }) {
  return (
    <GridActionsCellItem
      icon={<AttachEmailIcon color='primary' />}
      label="Invite"
      onClick={() => onSelect(template)}
    />
  );
}

function SEPInviteDialog({ open, onClose, template }) {
  const loading = useLoading();
  const snackbar = useSnackbar();
  const [fromSheet, setFromSheet] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [error, setError] = React.useState(null);

  const sendInvites = async () => {
    loading.show();
    try {
      const { unsuccessful, successful } = await sendBulkInvites(template?._id, usersData);

      if (unsuccessful.length) {
        setError({ unsuccessful });
        snackbar.showSnackbar(
          `${successful.length} invitations sent successfully, but ${unsuccessful.length} not sent!`,
          "info"
        );
      } else {
        snackbar.showSnackbar("Invitations sent successfully!!", "success");
      }

    } catch (error) {
      console.error("DEBUG::sendMail", error);
      snackbar.showSnackbar(error.message, "error");
    } finally {
      loading.hide();
    }
  };

  const handleImportSuccess = (rows) => {
    setUsersData([...usersData, ...rows]);
    setFromSheet(false);
  };

  const handleValueAdd = (invite) => {
    let [name, email] = invite.split("<");

    name = name.trim();

    email = email.split(">")[0].trim();

    if (!usersData.find(x => x.email === email)) {
      setUsersData([...usersData, { email, name }]);
    }
  };

  const handleValueDelete = (index) => {
    const newUsersData = [...usersData];
    newUsersData.splice(index, 1);
    setUsersData(newUsersData);
  };

  React.useEffect(() => {
    if (open) {
      setUsersData([]);
      setError(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title">
        Send Email Invites for - {template?.name}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: 380 }}>

      </DialogContent>
      <Invites
        values={usersData}
        onAdd={handleValueAdd}
        onDelete={handleValueDelete}
      />
      <Box display="flex" justifyContent="space-evenly" alignItems="center" pr={2}>
        <CustomButton
          size="medium"
          onClick={() => setFromSheet(true)}
          variant='outlined'
          startIcon={<DescriptionIcon color="primary" />}
        >
          Import from Google Sheets(name,email)
        </CustomButton>
        <div>
          <CSVReaderInput
            onData={handleImportSuccess}
            label="Import from CSV(name,email)"
          />
        </div>
      </Box>
      <Box px={2}>
        {error && (
          <details>
            <summary>
              <Typography color="error" component="span" variant="body2">
                Following invites not sent:
              </Typography>
            </summary>
            <ul>
              {error?.unsuccessful?.map((data, index) => (
                <details key={index}>
                  <summary>{data.email}</summary>
                  <span>Error: {data.reason.errorMessage}</span>
                </details>
              ))}
            </ul>
          </details>
        )}
      </Box>
      <DialogActions>
        <CustomButton
          onClick={onClose}
          variant='outlined'
        >
          Cancel
        </CustomButton>
        <CustomButton
          type='submit'
          variant='contained'
          color={'primary'}
          disabled={!usersData.length}
          onClick={sendInvites}
        >
          Send Invites
        </CustomButton>
      </DialogActions>
      <GSheetImportDialog
        open={fromSheet}
        onClose={() => setFromSheet(false)}
        onSuccess={handleImportSuccess}
        title={"Import invite data (email,name)"}
      />
    </Dialog>
  );
}

export default SEPInviteDialog;
import React from 'react';
import { useField } from "formik";
import { makeStyles } from "@mui/styles";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import { useSnackbar } from "contexts";
import { copyToClipboard } from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "8px 0",
    borderRadius: "6px",
    fontSize: 12,
    fontWeight: "normal",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "6px",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#E4E3E8",
      borderRadius: "6px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "6px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Inter', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",

      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  },
}));

export function ShadowTextField(props) {
  const classes = useStyles();

  return (<Box width="100%">
    <Typography variant="body1" marginTop="1em">
      {props.title}
    </Typography>
    <TextField
      className={classes.root}
      variant="filled"
      fullWidth
      InputProps={{ disableUnderline: true }}
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        ...(props.sx ? { ...props.sx } : {})
      }}
      {...props}
    />
  </Box>
  );
}

export function SimpleTextField(props) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        minWidth: 180,
        ...(props.sx ? { ...props.sx } : {}),
      }}
      // inputProps={{ style: { fontSize: "1rem", fontWeight: 200 } }}
      InputLabelProps={{ style: { fontSize: "1rem", fontWeight: 200 } }}
      {...props}
    />
  );
}

export default function FormTextField({ label, ...props }) {
  const classes = useStyles();

  const [field, meta] = useField(props);

  return (
    <TextField
      className={classes.root}
      {...props}
      InputProps={{ ...field, ...props.InputProps }}
      size={props.size || 'small'}
      variant="outlined"
      fullWidth
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        ...(props.sx ? { ...props.sx } : {})
      }}
      label={label}
      error={!!(meta.touched && meta.error)}
      helperText={meta.error}
    />
  );
};


export function FormCopyTextField({ InputProps, ...props }) {
  const snackbar = useSnackbar();

  const [field] = useField(props);

  return (
    <FormTextField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: (
          < InputAdornment position="end" >
            <IconButton
              title="copy content"
              onClick={() => {
                copyToClipboard(field.value);
                snackbar.showSnackbar(`${field.name} copied to clipboard!`, 'info', true);
              }}
            >
              <ContentCopyOutlinedIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

import Client from "./_client";

export const createPathway = async (pathway) => {
  let url = `/pathway`;

  let result = await new Client({
      path: url,
      payload: pathway,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to create pathway. Please try again."
      );
  }

  return result.data;
}

export const createPathwayAndLinkTags = async (pathway) => {
  let url = `/pathway/create-and-link-tags`;

  let result = await new Client({
      path: url,
      payload: pathway,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to create pathway. Please try again."
      );
  }

  return result.data;
}

export const duplicatePathway = async (pathwayId, pathway) => {
  let url = `/pathway/duplicate/${pathwayId}`;

  let result = await new Client({
      path: url,
      payload: pathway,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to create pathway. Please try again."
      );
  }

  return result.data;
}

export const updatePathway = async (pathwayId, pathway) => {
  let url = `/pathway/${pathwayId}`;

  let result = await new Client({
      path: url,
      payload: pathway,
  }).put();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to update pathway. Please try again."
      );
  }

  return result.data;
}

export const publishPathway = async (pathwayId, unPublish = false) => {
    let url = `/pathway/publish/${pathwayId}`;
    if (unPublish) {
        url += `?unPublish=true`;
    }

    let result = await new Client({
        path: url,
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            unPublish 
                ? result.message || "Uh Oh! Unable to unpublish pathway. Please try again." 
                : result.message || "Uh Oh! Unable to publish pathway. Please try again."
        );
    }

    return result.data;
};

export const updatePathwayAndTags = async (pathwayId, pathway) => {
  let url = `/pathway/update-pathway-tags/${pathwayId}`;

  let result = await new Client({
      path: url,
      payload: pathway,
  }).put();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to update pathway and tags. Please try again."
      );
  }

  return result.data;
}

export const deletePathway = async (pathwayId) => {
  let url = `/pathway/${pathwayId}`;

  let result = await new Client({
      path: url,
  }).delete();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to delete pathway. Please try again."
      );
  }

  return result.data;
}

export const getPathways = async (pathwayFilters = {}) => {
  let url = "/pathway?";

  Object.keys(pathwayFilters).forEach((key) => {
      if (pathwayFilters[key] !== undefined) url += `${key}=${pathwayFilters[key]}&`;
  });

  const result = await new Client({
      path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get pathways. Please try again."
      );
  }
  return result?.data;
};

export const getPathwayById = async (pathwayId) => {

  let url = `/pathway/${pathwayId}`;

  const result = await new Client({
      path: url
  }).get();
  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get pathway. Please try again."
      );
  }
  return result.data;
}

export const getPathwayModules = async (filters) => {
  let url = "/module/filter?";

  Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
      path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get pathways. Please try again."
      );
  }
  return result?.data;
}

export const saveModule = async (module) => {
  let url = `/module/upsert/modules-n-assessments`;

  let result = await new Client({
      path: url,
      payload: module,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to create Module. Please try again."
      );
  }

  return result.data;
};




// pathway questions services
export const createQuestions = async (data) => {
  let url = `/interviewQuestion/bulk-pathway-questions`;

  let result = await new Client({
      path: url,
      payload: data,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to create questions. Please try again."
      );
  }

  return result.data;
}

export const getPathwayQuesitons = async (filters = {}) => {
  let url = "/interviewQuestion/pathway-questions?";

  Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
      path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get questions. Please try again."
      );
  }
  return result?.data;
};


export const exportPathwayAnalytics = async (id, client, filters={}) => {
  let url = client ? `/pathway/export/client/${client}?` : `/pathway/export/${id}?`;

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
});

  const result = await new Client({
      path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to export pathway analytics. Please try again."
      );
  }

  return result?.data;
}

export const getPathwayRequests = async (filters = {}) => {
  let url = "/resourceRequest?";

  Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
      path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get pathway requests. Please try again."
      );
  }
  return result?.data;
};

export const getPathwayRequestById = async (id) => {
  let url = `/resourceRequest/${id}?`;

  const result = await new Client({
      path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get pathway request. Please try again."
      );
  }
  return result?.data;
};

export const updateResourceRequest = async (id, data) => {
  let url = `/resourceRequest/${id}`;

  let result = await new Client({
      path: url,
      payload: data,
  }).put();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to update Resource Request. Please try again."
      );
  }

  return result.data;
}

export const getPathwayTagMappings = async (tagFilters = {}) => {
  let url = "/pathwayTagMapping?";

  Object.keys(tagFilters).forEach((key) => {
      if (tagFilters[key]) url += `${key}=${tagFilters[key]}&`;
  });

  const result = await new Client({
      path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get pathwayTagMapping. Please try again."
      );
  }

  return result?.data;
};
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import CustomButton from 'components/CustomButton';
import CustomChipArray from 'components/CustomChipArray';
import { useSnackbar, useLoading } from "contexts";
import { copyToClipboard } from "utils";
import {exportSubscribers } from "services";

function ExportSubscribersDialog({
    open,
    onClose = () => { },
}) {
    const snackbar = useSnackbar();
    const loading = useLoading();
    const [emails, setEmails] = React.useState([]);
    const [responseUrl, setResponseUrl] = React.useState(false);

    const handleEmailsChange = (newEmails) => {
        setEmails(newEmails);
    }

    React.useEffect(() => {
        setEmails([]);
        setResponseUrl(false);
    }, [open]);

    const handleExportSubscribers = async () => {
        try {
            loading.show();
            let { url } = await exportSubscribers(emails);
            setResponseUrl(url);
        } catch (error) {
            snackbar.showSnackbar(error.message, "error", true);
        }
        finally {
            loading.hide();
        }
    }

    const handleCopyUrlClick = () => {
        copyToClipboard(responseUrl);
        snackbar.showSnackbar("URL copied to clipboard", "success", true);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle >
                Export Subscribers
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 500, maxWidth: 900, overflow: "hidden" }}>
                {
                    !responseUrl ? (
                        <>
                            <CustomChipArray
                                InputProps={{
                                    autoFocus: true,
                                    type: 'email',
                                    name: "email",
                                    placeholder: "Add emails for sheet permissions...",
                                }}
                                onValuesChange={handleEmailsChange}
                            />
                            <br />
                            <br />
                            <CustomButton
                                variant="contained"
                                disabled={emails.length <= 0}
                                onClick={handleExportSubscribers}
                            >
                                Export
                            </CustomButton>
                        </>
                    ) : (
                        <Box
                            border="1px solid lightgrey"
                            my="1em"
                            p="1em"
                            display="flex"
                            alignItems="center"
                        >
                            <Box flexGrow={1} overflow="hidden">
                                <Typography
                                    variant="subtitle1"
                                    color="blue"
                                    component="a"
                                    sx={{ wordWrap: "break-word" }}
                                    href={responseUrl}
                                    target={"_blank"}
                                >
                                    {responseUrl}
                                </Typography>
                            </Box>
                            <Box ml={1}>
                                <IconButton variant="outlined">
                                    <ContentCopyIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={handleCopyUrlClick}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    )
                }
                <CustomButton
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </CustomButton>
            </DialogContent>
        </Dialog>
    );
}

export default ExportSubscribersDialog;
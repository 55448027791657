import React from 'react'
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ExportTemplatetAnalyticsDialog
    from "components/dialogs/ExportTemplateAnalyticsDialog";
import ClientSelect from 'components/ClientSelect';
import ListPagination from 'components/ListPagination';
import {
    getAnalytics,
} from "services/analyticsService";
import Session from "utils/Session";
import FilterList from './FilterList';

const useStyles = makeStyles((theme) => ({
    row: {
        minHeight: "100px !important",
    },
    clickable: {
        cursor: "pointer",
        minHeight: "100px !important",
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

function Ratings({ ratings = {} }) {
    return (
        <ul>
            {Object.keys(ratings).map((key, index) => (
                <li key={index}>
                    <b>{key}:</b> {ratings[key]}
                </li>
            ))}
        </ul>
    );
}

function InterviewAnalytics() {
    const classes = useStyles();

    const [rows, setRows] = React.useState([]);
    const [exportAnalyticsDialog, setExportAnalyticsDialog] = React.useState(false);
    const [clientSelected, setClientSelected] = React.useState('');

    const handleExportClick = () => {
        setExportAnalyticsDialog(true);
    }

    const closeExportAnalyticsDialog = () => {
        setExportAnalyticsDialog(false);
    }

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerClassName: classes.tableHeader,
        },
        {
            field: 'communication',
            headerName: 'Communication',
            flex: 1,
            headerClassName: classes.tableHeader,
            filterable: false,
            renderCell: function (params) {
                return <Ratings ratings={params?.row?.communication} />;
            }
        },
        {
            field: 'content',
            headerName: 'Content',
            flex: 1,
            headerClassName: classes.tableHeader,
            filterable: false,
            renderCell: function (params) {
                return <Ratings ratings={params?.row?.content} />;
            }
        },
        {
            field: 'mocksTaken',
            headerName: 'Total mocks taken',
            flex: 1,
            type: 'number',
            headerClassName: classes.tableHeader,
        },
        {
            field: 'totalTemplatesCreated',
            headerName: 'Total Templates Created',
            flex: 1,
            type: 'number',
            headerClassName: classes.tableHeader,
        },
    ];


    React.useEffect(() => {
        (async () => {
            if (!clientSelected || clientSelected == 'client') {
                setRows([]);
                return;
            };
            
            if(clientSelected?._id){
                const rows = await getAnalytics(clientSelected?._id);
                setRows(rows);
            }
            else{
                setRows([]);
                return;
            }
        })()
    }, [clientSelected])

    const handleClientChange = (client) => {
        if (!client || client == 'client') {
            setClientSelected('client');
            return;
        };
        setClientSelected(client);
    };

    return (
        <Box mt={2} position="relative">
            <ContentHeader
                title="Interview Analytics"
                clearFilter
                setFilters={setClientSelected}
                filters={clientSelected}
                BaseFilters={{}}>
                {Session.permissions["InterviewAnswer"].READ && (
                    <CustomButton
                        variant="contained"
                        onClick={handleExportClick}
                    >
                        Export
                    </CustomButton>
                )}
                <FilterList width={"340px"}>
                    {[<ClientSelect onChange={handleClientChange} defaultValue={clientSelected._id} />]}
                </FilterList>
            </ContentHeader>
            <Box style={{ height: "75vh", width: '100%' }} >
                <DataGrid
                    autoPageSize
                    rows={rows}
                    columns={columns}
                    rowHeight={150}
                    disableSelectionOnClick
                    density='compact'
                    getRowClassName={() => classes.row}
                    components={{
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: rows.length
                        }
                    }}
                />
            </Box>
            <ExportTemplatetAnalyticsDialog
                open={exportAnalyticsDialog}
                onClose={closeExportAnalyticsDialog}
                onSuccess={closeExportAnalyticsDialog}
                selectedClient={clientSelected}
            />
        </Box>
    );
}

export default InterviewAnalytics;
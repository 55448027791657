import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import FormTextField from "components/FormTextField";
import ContentHeader from "components/ContentHeader";
import FileUploadInput from "components/FileUploadInput";
import CustomButton from "components/CustomButton";
import { useSnackbar } from "contexts";
import { Typography } from "@mui/material";
import TextEditor from "components/TextEditor";
import { createBlog, getBlogById, updateBlogById } from "services/blog";

const blogValidationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Required'),
    category: Yup.string()
        .required("Required"),
    description: Yup.string()
        .required('Required'),
    content: Yup.string()
        .required('Required'),
    minimumScore: Yup.number()
        .min(0, "Should be greater than or equal to zero")
        .required('Required'),
    maximumScore: Yup.number()
        .min(0, "Should be greater than or equal to zero")
        .required('Required'),
    imageUrl: Yup.string().required(),
});

const InitialValues = {
    title: '',
    category: '',
    description: '',
    content: '',
    minimumScore: 0,
    maximumScore: 0,
    imageUrl: '',
};

const useStyles = makeStyles({
    fields: { margin: 20 },
    fieldContainer: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    copyIcon: {
        marginLeft: '8px',
        cursor: 'pointer'
    },
    buttonContainer: {
        margin: "30px 10px"
    }
});

const getFields = (id) => [
    {
        required: true,
        name: "title",
        label: "Blog title",
    },
    {
        required: true,
        type: "number",
        name: "minimumScore",
        label: "Min Score",
    },
    {
        required: true,
        type: "number",
        name: "maximumScore",
        label: "Max Score",
    },
];

const Categories = [
    { name: 'VOCABULARY' },
    { name: 'FLUENCY' },
    { name: 'DELIVERY' },
    { name: 'ENGAGEMENT' },
    { name: 'WORD_CHOICE' },
    { name: 'CONFIDENCE' },
    { name: 'SIMILARITY' },
    { name: 'RELEVANCE' },
    { name: 'LOGIC_FLOW' },
];

export default function BlogEditor() {
    const classes = useStyles();
    const { id } = useParams();

    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(InitialValues);

    const handleSubmit = async (values) => {
        try {
            const blog = {};
            blog["title"] = values.title;
            blog["category"] = values.category;
            blog["description"] = values.description;
            blog["content"] = values.content;
            blog["minimumScore"] = values.minimumScore;
            blog["maximumScore"] = values.maximumScore;
            blog["imageUrl"] = values.imageUrl;

            if (id && id !== 'create') {
                await updateBlogById(id, blog);
            } else {
                await createBlog(blog);
            }

            snackbar.showSnackbar("Blog saved successfully !!");
            navigate(`/general/blogs`);
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (id && id !== 'create') {
                    const blog = await getBlogById(id);

                    const newInitialValues = {
                        title: blog.title,
                        category: blog.category,
                        description: blog.description,
                        content: blog.content,
                        minimumScore: blog.minimumScore,
                        maximumScore: blog.maximumScore,
                        imageUrl: blog.imageUrl,
                    };

                    setInitialValues(newInitialValues);
                }
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error', false);
            } finally {
                setLoading(false)
            }
        })();
    }, [id]);

    const goBack = () => navigate("/general/blogs");

    return (<>
        <ContentHeader title="Blog Form" />
        <br />
        {!loading && (
            <Formik
                validationSchema={blogValidationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, errors, setFieldValue }) => (

                    <Form>
                        <div
                            style={{
                                display: 'flex', flexDirection: 'column',
                                gridTemplateColumns: '32% 32% 32%',
                                gridColumnGap: "2%"
                            }}
                        >

                            <Box display='flex' gap={5}>

                                {id && getFields(id).map((field, index) => (
                                    <FormTextField key={index} {...field} />
                                ))}

                            </Box>

                            <Box display='flex' gap={5}>

                                <div>
                                    <label>Choose Category :</label>
                                    <select
                                        value={values.category}
                                        onChange={(e) => setFieldValue('category', e.target.value)}
                                    >
                                        <option value="">Select...</option>
                                        {
                                            Categories.map(({ name }) =>
                                                <option value={name}>{name}</option>
                                            )
                                        }
                                    </select>
                                </div>

                                <textarea
                                    name="description"
                                    placeholder="Write a blog description.."
                                    cols={200}
                                    value={values.description}
                                    onChange={(e, v) => setFieldValue('description', e.target.value)}
                                />

                                <FileUploadInput
                                    name="imageUrl"
                                    label="upload blog image"
                                    file={{
                                        name: '',
                                        url: values.imageUrl
                                    }}
                                    onUploadCancelled={() => setFieldValue('imageUrl', '')}
                                    onUpload={(file) => { setFieldValue('imageUrl', file.url) }}
                                    errors={{ file: errors['imageUrl'] || false }}
                                    toPublic={true}
                                    uploadLocation='blogImages'
                                />
                            </Box>

                            <br /> <br />

                            <Box width='100%'>
                                <Typography
                                    sx={{ fontSize: 14, fontWeight: "bold", color: "inherit" }}
                                    pb={2}
                                >
                                    Write a blog here :
                                </Typography>
                                <TextEditor
                                    content={values.content}
                                    updateContent={(v) => setFieldValue('content', v)}

                                />
                            </Box>

                        </div>

                        <br />

                        <Box className={classes.buttonContainer}>
                            <CustomButton
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </CustomButton>
                            <CustomButton
                                variant="outlined"
                                onClick={goBack}
                            >
                                Cancel
                            </CustomButton>
                        </Box>
                    </Form>)}
            </Formik>
        )}
    </>);
}
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import CustomButton from 'components/CustomButton';
import { SimpleTextField } from 'components/FormTextField';
import { getScaleByUserId, saveScale } from 'services';
import { useLoading, useSnackbar } from 'contexts';

const DefaultBreakpoints = [25, 50, 75];
const DefaultLabels = ["B2", "B1", "A2", "A1"];

function SEPScaleEditor({ clientId }) {
  const loading = useLoading();
  const snackbar = useSnackbar();

  const [scale, setScale] = React.useState(null);
  const [breakpoints, setBreakpoints] = React.useState(DefaultBreakpoints)
  const [labels, setLabels] = React.useState(DefaultLabels);

  const handleAddPoint = (index) => () => {
    const nextPoint = breakpoints[index + 1] || 100;

    setBreakpoints([
      ...breakpoints.slice(0, index + 1),
      nextPoint - 1,
      ...breakpoints.slice(index + 1),
    ]);
    setLabels([
      ...labels.slice(0, index + 1),
      "",
      ...labels.slice(index + 1),
    ]);
  };

  const deleteHandler = (index) => () => {
    setBreakpoints([
      ...breakpoints.slice(0, index),
      ...breakpoints.slice(index + 1),
    ]);
    setLabels([
      ...labels.slice(0, index),
      ...labels.slice(index + 1),
    ]);
  };

  const pointChangeHandler = React.useCallback((index) => (e) => {
    if (index === breakpoints.length) {
      setBreakpoints([
        ...breakpoints.slice(0, index - 1),
        +e.target.value
      ]);
    } else {
      setBreakpoints([
        ...breakpoints.slice(0, index),
        +e.target.value,
        ...breakpoints.slice(index + 1),
      ]);
    }
  }, [breakpoints]);

  const labelChangeHandler = React.useCallback((index) => (e) => {
    setLabels([
      ...labels.slice(0, index),
      e.target.value.toUpperCase(),
      ...labels.slice(index + 1),
    ]);
  }, [labels]);

  const onSave = async () => {
    try {
      loading.show();

      let breakpoint = 0;
      const value = {};

      for (let i = 0; i <= 100; i++) {
        if (i > breakpoints[breakpoint]) breakpoint += 1;

        value[i.toString()] = labels[breakpoint];
      }

      const savedScale = await saveScale(scale?._id, {
        breakpoints,
        labels,
        value,
        user: clientId,
      });

      setScale(savedScale);

      snackbar.showSnackbar("Saved scale for the client!!");
    } catch (error) {
      console.log(error);
      snackbar.showSnackbar("Unable to save scale, Please try again..!!")
    } finally {
      loading.hide();
    }
  };

  const onDiscard = () => {
    setBreakpoints(
      scale?.breakpoints?.length ? scale?.breakpoints : DefaultBreakpoints
    );
    setLabels(scale?.labels?.length ? scale?.labels : DefaultLabels);
  };

  React.useEffect(() => {
    (async () => {
      let fetchedScale;

      try {
        fetchedScale = await getScaleByUserId(clientId);

        setScale(fetchedScale);

      } catch (error) {
        console.error(error);
        snackbar.showSnackbar(error.message, "error");
      }

      setBreakpoints(
        fetchedScale?.breakpoints?.length ? fetchedScale?.breakpoints : DefaultBreakpoints
      );
      setLabels(
        fetchedScale?.labels?.length ? fetchedScale?.labels : DefaultLabels
      );
    })();

  }, [clientId]);

  return (
    <section>
      <Typography variant="h6">
        SEP Scale For Webhook
      </Typography>
      {new Array(breakpoints.length + 1).fill(0).map((point, i) => (
        <div key={i} style={{ display: "flex", alignItems: "center" }}>
          <Box display="flex">
            <SimpleTextField
              label="From (Excluded)"
              disabled={i === 0}
              type="number"
              name="start"
              value={i === 0 ? 0 : breakpoints[i - 1]}
              onChange={pointChangeHandler(i - 1)}
              inputProps={{
                max: i === 0 ? 0 : breakpoints[i + 1]?.start || 100,
                min: (breakpoints[i - 2] || 0) + 1,
              }}
            />
            <Box display="flex" alignItems="center">
              <HorizontalRuleIcon />
            </Box>
            <SimpleTextField
              label="To (Included)"
              type="number"
              name="end"
              value={i === breakpoints.length ? 100 : breakpoints[i]}
              onChange={pointChangeHandler(i)}
              disabled={i === breakpoints.length}
              inputProps={{ max: (breakpoints[i + 1] || 100) - 1 }}
            />

            <Box display="flex" alignItems="center">
              <DragHandleIcon />
            </Box>
            <SimpleTextField
              label="Scale"
              type="alphanum"
              name="label"
              value={labels[i] || ""}
              onChange={labelChangeHandler(i)}
            />
          </Box>
          <CustomButton
            onClick={handleAddPoint(i)}
            variant="outlined"
            startIcon={<>&#8617;</>}
            disabled={breakpoints.length >= 5}
          >
            Add
          </CustomButton>
          <CustomButton
            onClick={deleteHandler(i)}
            disabled={i === 0 || i === breakpoints.length}
            variant="outlined"
            color="error"
          >
            Delete
          </CustomButton>
        </div>
      ))}
      <Box>
        <CustomButton
          onClick={onSave}
          variant="contained"
        >
          Save Changes
        </CustomButton>
        <CustomButton
          onClick={onDiscard}
          variant="contained"
          color="error"
        >
          Discard
        </CustomButton>
      </Box>
    </section>
  );
}

export default SEPScaleEditor;
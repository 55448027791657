import React from 'react';
import { debounce } from 'lodash';

import { SimpleTextField } from 'components/FormTextField';

function ListQuickSearch({
  field = "name",
  label = "Search by name",
  placeholder = "Type here...",
  onFilterChange,
  value
}) {
  const handleChange = (e) => {
    onFilterChange({ items: [{ columnField: [field], value: e.target.value }] });
  }

  const handleInput = debounce(handleChange, 500);

  return (
    <>
      &nbsp;
      <SimpleTextField
        sx={{ maxWidth: 200 }}
        placeholder={placeholder}
        label={label}
        onInput={handleInput}
        defaultValue={value}
      />
      &nbsp;
    </>
  );
}

export default ListQuickSearch;
import Session from "utils/Session";
import Client from "./_client";

export const getAnalytics = async (clientId) => {
    const result = await new Client({
        path: `/analytics/${clientId}/interview`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get the analytics. Please refresh again."
        );
    }
    return result?.data;
};

export const getOverallAnalytics = async () => {
    const result = await new Client({
        path: `/analytics/${Session.userId}/overall`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get the analytics. Please refresh again."
        );
    }
    return result?.data;
}

export const exportAnalytics = async (type, emails) => {
    let url = `/analytics/${type}`;
    let headers = [];
    let title = "";
    if (type === "extension") {
        title = "Extension Analytics";
        headers = ["UserId", "meetingSession", "fluency", "confidence", "wordchoice", "vocabulary", "delivery", "engagement", "overall"];
    }
    if (type === "interview") {
        title = "Interview Analytics";
        headers = ["TemplateId", "QuestionId", "overall", "word-choice", "fluency", "delivery", "confidence", "similarity", "relevance", "logic-flow", "overall"];
    }

    const result = await new Client({
        path: url,
        payload: {
            emails,
            headers,
            title
        }
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to export analytics. Please refresh again."
        );
    }
    return result?.data;
}

export const exportTemplateAnalytics = async (data) => {
    let url = `/analytics/template-analytics/export`;
    const result = await new Client({
        path: url,
        payload: data,
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to export template analytics. Please try again."
        );
    }

    return result?.data;
}

export const exportSEPTemplateAnalytics = async (templateId, emails = [], createdAtStart, createdAtEnd) => {
    if (!templateId || !emails.length) throw new Error("Invalid data provided");

    let url = `/analytics/sep/${templateId}?emails=${emails}`;

    if (createdAtStart) {

        url += `&createdAtStart=${createdAtStart}`;
        const end = createdAtEnd ? createdAtEnd : createdAtStart;
        url += `&createdAtEnd=${end}`;
    }

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to export template analytics. Please try again."
        );
    }

    return result?.data;
}

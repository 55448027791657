import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import ClientSelect from 'components/ClientSelect';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import { SimpleTextField } from 'components/FormTextField';

import { useSnackbar } from "contexts";
import { copyToClipboard } from "utils";
import { BatchSingleSelect } from 'components/BatchSelect';
import { onBoardUserAndGrantAccess } from 'services';
import { SimpleSelect } from 'components/FormSelect';
import Paper from "@mui/material/Paper"
import ReactJson from 'react-json-view';

function OnboardUser(props) {

    const snackbar = useSnackbar();
    const [client, setClient] = React.useState();
    const [batch, setBatch] = React.useState("");
    const [error, setError] = React.useState("");
    const [inputs, setInputs] = React.useState({});
    const [link, setLink] = React.useState("");
    const [localLink, setLocalLink] = React.useState("");
    const [jsonData, setJsonData] = React.useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!client) {
            setError("Select client")
            return false;
        }
        if (!batch) {
            setError("Select batch")
            return false;
        }
        onBoardUserAndGrantAccess(client?._id, {
            email: client?.email,
            user: {
                name: inputs.name,
                email: inputs.email,
                accessPeriod: inputs.accessPeriod,
            },
            batch: batch?._id || ""
        }).then((data) => {
            setJsonData(data);
            snackbar.success("User onboarded SuccessFully");
        }).catch((err) => {
            snackbar.error(err.message)
        })


    }
    const handleBatchChange = (batch) => {
        setInputs(values => ({ ...values, "batch": batch }));
        setBatch(batch);
    }

    return (
        <>
            <ContentHeader title="Onboard New User" />
            <br />
            <br />
            <Box width="50%" m="auto">
                <form onSubmit={handleSubmit}>
                    <Typography variant="subtitle1">Fill user details</Typography>
                    <SimpleTextField
                        placeholder="John wick"
                        name="name"
                        label="Student Name"
                        type="text"
                        value={inputs.name || ""}
                        onChange={handleChange}
                        required
                    />
                    <SimpleTextField
                        placeholder="john@gmail.com"
                        name="email"
                        label="Student Email"
                        type="email"
                        value={inputs.email || ""}
                        onChange={handleChange}
                        required
                    />
                    <SimpleSelect
                        options={Array.from({ length: 12 }, (x, i) => ({ _id: i + 1, name: i + 1 }))}
                        required
                        name="accessPeriod"
                        label="Access Period"
                        value={inputs.accessPeriod || ""}
                        onChange={handleChange}
                    />
                    <br />
                    <br />
                    <Typography variant="subtitle1">Fill Client details</Typography>
                    <ClientSelect
                        error={error === "Select client" ? error : ""}
                        defaultValue={client?._id}
                        onChange={(client) => setClient(client)}
                        required
                    />
                    <br />
                    <BatchSingleSelect
                        error={error === "Select batch" ? error : ""}
                        client={client?._id}
                        onChange={handleBatchChange}
                        required
                    />
                    <br />
                    <br />
                    {
                        jsonData && (
                            <Paper sx={{
                                width: "480px",
                                marginInline: "auto",
                                marginBlock: "3rem",
                                minWidth: "240px"
                            }}>
                                <Typography variant="subtitle1" sx={{ padding: '.5rem' }}>Response</Typography>
                                <ReactJson src={JSON.parse(JSON.stringify(jsonData))} />
                            </Paper>
                        )
                    }

                    <CustomButton variant="contained" type="submit" name="sep">
                        Add User
                    </CustomButton>
                </form>
                <br />

            </Box>
        </>
    );

}
export default OnboardUser;
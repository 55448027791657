import * as React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

export function FormCheckbox({ checked, onChange, name, color = "primary", label }) {
    const handleCheckboxChange = (event) => {
        onChange(event.target.checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    name={name}
                    color={color}
                />
            }
            label={label}
        />
    );
}

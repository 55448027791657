import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ContentHeader from "components/ContentHeader";
import UserAutoComplete from "components/UserAutoComplete";
import CustomButton from "components/CustomButton";
import ListPagination from "components/ListPagination";
import ExportAnalyticsDialog from "components/dialogs/ExportAnalyticsDialog";
import { useSnackbar } from "contexts";
import { getMeetingSessions, getMeetingSummary } from "services";
import Session from "utils/Session";
import { getExportAnalyticsListActions, formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    selectedCell: {
        backgroundColor: "#2dabff6e",
    },
    meetingSummaryContainer: {
        border: "1px solid lightgrey",
        borderRadius: 8,
        marginLeft: 8,
        width: "30%",
        overflow: "auto",
        paddingBottom: 16,
    },
    meetingSummaryHeader: {
        marginBottom: 8,
        borderBottom: "1px solid lightgrey",
        padding: 8,
        backgroundColor: theme.palette.primary.main,
    },
}));

function SearchBar({ onSearch }) {
    return (
        <Box display="flex" alignItems="center" >
            <UserAutoComplete
                onSelect={onSearch}
                field="email"
                application="extension"
            />
        </Box>
    );
}

function MeetingSessionSummary({ meetingSession }) {
    const classes = useStyles();
    const [meetingSummary, setMeetingSummary] = React.useState({});

    React.useEffect(() => {
        (async () => {
            if (meetingSession) {
                const { data } = await getMeetingSummary(meetingSession._id);
                setMeetingSummary(data);
            } else {
                setMeetingSummary({});
            }
        })();

    }, [meetingSession]);

    const ratingsKey = Object.keys(meetingSummary?.ratings || {});
    const tags = meetingSummary?.tags || [];

    return meetingSession ? (
        <Box className={classes.meetingSummaryContainer}>
            <Typography
                variant="body1"
                className={classes.meetingSummaryHeader}
            >
                Ratings
            </Typography>
            {ratingsKey.length ? (
                <Stack direction="column" spacing={1} p={1}>
                    {ratingsKey.map((key, index) => (
                        <Chip
                            label={`${key} : ${meetingSummary?.ratings[key]}`}
                            color="default"
                            variant="outlined"
                        />
                    ))}
                </Stack>
            ) : (
                <Chip
                    label={"Not available"}
                    color="error"
                    variant="outlined"
                />
            )}
            <Typography
                variant="body1"
                className={classes.meetingSummaryHeader}
                mt={1}
            >
                Tags:
            </Typography>
            {tags.length ? (
                <Stack direction="column" spacing={1} p={1}>
                    {tags.map((tag, index) => {
                        const key = Object.keys(tag)[0];

                        return (
                            <Chip
                                label={`${key} : ${tag[key]}`}
                                color="default"
                                variant="outlined"
                            />
                        )
                    })}
                </Stack>
            ) : (
                <Chip
                    label={"Not available"}
                    color="error"
                    variant="outlined"
                />
            )}
        </Box>
    ) : null;
}

export default function ExtensionAnalytics() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [meetingSessions, setMeetingSessions] = React.useState([]);
    const [meetingSession, setMeetingSession] = React.useState(null);
    const [exportAnalyticsDialog, setExportAnalyticsDialog] = React.useState(false);

    const handleExportClick = () => {
        setExportAnalyticsDialog(true);
    }

    const closeExportAnalyticsDialog = () => {
        setExportAnalyticsDialog(false);
    }

    const handleSearch = async (value) => {
        try {
            const result = await getMeetingSessions({
                user: value._id,
            });

            setMeetingSessions(result.meetingSessions);
        } catch (error) {
            console.log(error);
            snackbar.showSnackbar(
                "Unable to fetch data!! Please try again",
                "error"
            );
        }
    };

    const showAnalysis = (newMeetingSession) => {
        setMeetingSession(newMeetingSession);
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: 'startedAt',
                headerName: 'Started At',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: [],
                valueGetter: (params) => {
                    return formatDate(params.row.startedAt, "DD-MM-YYYY HH:mm:ss A");
                },
            },
            {
                field: 'finishedAt',
                headerName: 'Finished At',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: [],
                valueGetter: (params) => {
                    return params?.row?.finishedAt ?
                        formatDate(params.row.finishedAt, "DD-MM-YYYY HH:mm:ss A") :
                        "N/A";
                },
            },
            {
                field: 'mode',
                headerName: 'Mode',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: [],
                valueGetter: ({ row }) => row.mode.toUpperCase(),
            },
            {
                field: 'actions',
                type: "actions",
                headerClassName: classes.tableHeader,
                headerName: "View Analysis",
                getActions: (params) =>
                    getExportAnalyticsListActions(params?.row, showAnalysis)
            }
        ];
    }, []);

    return (
        <>
            <ContentHeader title={"Extension Analytics"}>
                {Session.permissions["MeetingAnalysis"].READ &&
                    <CustomButton
                        variant="contained"
                        onClick={handleExportClick}
                    >
                        Export
                    </CustomButton>
                }
                <SearchBar onSearch={handleSearch} />
            </ContentHeader>
            <Box
                display="flex"
                height='76vh'
                width='100%'
                marginTop={1}
            >
                <DataGrid
                    pagination
                    autoPageSize
                    disableColumnFilter
                    rows={meetingSessions}
                    getRowId={(row) => row._id}
                    columns={columns}
                    getCellClassName={(params) => {
                        if (params.hasFocus && params.field === "actions") return classes.selectedCell;
                    }}
                    components={{
                        Pagination: ListPagination
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: meetingSessions.length
                        }
                    }}

                />
                <MeetingSessionSummary meetingSession={meetingSession} />
            </Box>
            <ExportAnalyticsDialog
                open={exportAnalyticsDialog}
                onClose={closeExportAnalyticsDialog}
                onSuccess={closeExportAnalyticsDialog}
                type="extension"
            />
        </>
    );
};
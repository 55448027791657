import React from 'react';
import Cancel from '@mui/icons-material/Cancel';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutline';
import Done from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import CustomButton from 'components/CustomButton';

export default function DeleteButton({ index, onDelete, text = "Delete ?" }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton
        sx={{ height: "fit-content", alignSelf: "flex-end" }}
        aria-describedby={id} variant="contained" onClick={handleClick}
      >
        <DeleteOutlinedIcon
          className="secondary"
          color="error"
          sx={{ cursor: "pointer" }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { padding: 1 } }}
      >
        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
          {text}
        </Typography>
        <CustomButton
          startIcon={<Done />}
          onClick={() => {
            handleClose();
            onDelete(index);
          }}
          variant="contained"
          color="secondary" size="small"
        >
          Confirm
        </CustomButton>
        <CustomButton
          startIcon={<Cancel />} onClick={handleClose} variant="outlined"
          color="primary" size="small"
        >
          Cancel
        </CustomButton>
      </Popover>
    </div>
  );
}

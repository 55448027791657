import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormHelperText, Typography } from '@mui/material';
import { getRoles } from 'services';

const useStyles = makeStyles((theme) => ({
    error: {
      marginLeft: 16,
    },
    root: {
      margin: "8px 0",
      borderRadius: "6px",
      fontSize: 12,
      fontWeight: "normal",
      "& .MuiInputLabel-root": {
        fontSize: "12px !important",
        fontWeight: "normal !important",
      },
      "& .MuiFilledInput-root": {
        fontSize: 12,
        fontWeight: "normal",
        borderRadius: "6px",
        backgroundColor: "white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        '&>*': {
          padding: 10
        }
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: "#E4E3E8",
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
        '& fieldset': {
          borderRadius: "6px",
          borderColor: "transparent",
          fontWeight: "normal",
          fontStyle: "normal",
          fontFamily: "'Inter', sans-serif",
          fontSize: 12,
        },
        '&:hover fieldset': {
          borderColor: '#02569D',
          borderRadius: "6px",
          fontSize: 12,
          fontWeight: "normal",
  
        },
        '&.Mui-focused fieldset': {
          borderColor: '#02569D',
          borderRadius: "6px",
          fontSize: 12,
          fontWeight: "normal",
        },
      },
    },
  }));


function Select({
    defaultValue, onChange= (client)=>{}, error="", label='choose', options=[]
}){
    const classes = useStyles();
    const [value,setValue] = React.useState(null);
    const [datas,setData] = React.useState([]);
    
    const handleFieldChange= (event,newValue)=>{
        setValue(newValue);
        onChange(newValue);
    }

    React.useEffect(() => {
        setData(options);
      }, [options, defaultValue]);

    React.useEffect(() => {
        const data = datas.find(r=>r._id===defaultValue);

        if(data) setValue(data);
    },[datas,defaultValue]);

    return (
        <>
          <Autocomplete
            fullWidth
            size="small"
            options={datas}
            value={value}
            onChange={handleFieldChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                className={classes.root}
                error={!!error}
              />
            )}
            getOptionLabel={(data) => `${data.name}`}
            renderOption={(props, option) => (
              <Typography
                {...props}
                component="li"
                sx={{ fontSize: 14, fontWeight: "normal", color: "inherit" }}
              >
                {option.name}
              </Typography>
            )}
          />
          {!!error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
        </>
      );


}

export default Select;
import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CustomChipArray from 'components/CustomChipArray';

export default function SCQ({ index, question, answer, setAnswer }) {
  const [answerOptions, setAnswerOptions] = React.useState([]);

  return (
    <>
      <TextField
        required
        fullWidth
        inputProps={{ minlen: 0, maxlen: 1000 }}
        size="small"
        label={"Q".concat(index + 1)}
        placeholder="Add question content..."
        onInput={(e) => question.content = e.target.value}
      />
      <br />
      <br />
      <CustomChipArray
        label={"Options"}
        buttonText="Add"
        InputProps={{ placeholder: "Add option and hit enter" }}
        onValuesChange={(options) => {
          question.options = options;
          setAnswerOptions(options);
        }}
      />
      <br />
      <br />
      <FormControl size="small" required disabled={answerOptions.length <= 0} fullWidth>
        <InputLabel id="mock-type-select-label">
          Answer
        </InputLabel>
        <Select
          label="Mock Type"
          labelId='mock-type-select-label'
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
        >
          {answerOptions.map((option, i) => (
            <MenuItem value={i} key={i}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

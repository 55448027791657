import * as React from 'react';
import {
    DataGrid,
} from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import { useList } from "hooks/useList";
import LinearProgress from '@mui/material/LinearProgress';
import { formatDate } from 'utils/date';
import { Button } from '@mui/material';

import ContentHeader from 'components/ContentHeader';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import { getSubscribers} from "services/subscriberService";
import ShowCommentDialog from 'components/dialogs/ShowCommentDialog';
import ExportSubscribersDialog from 'components/dialogs/ExportSubscribersDialog';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    include: ["user"],
    includeTotal: true
};


export default function Subscribers() {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openExportDialog, setOpenExportDialog] = React.useState(false);
    const [comment, setComment] = React.useState('');

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getSubscribers,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "subscribers",
    });

    const handleSortModelChange = (newModel) => {
        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleCellClick = (girdParam) => {
        if (girdParam.field === 'comment') {
            setComment(girdParam.formattedValue);
            setOpenDialog(true);
        }
    }

    const columns = React.useMemo(() => {
        return [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                headerClassName: classes.tableHeader,
            },
            {
                field: 'phoneNumber',
                headerName: 'Phone-number',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    return params?.row?.metadata?.phoneNumber
                },
            },
            {
                field: 'reference',
                headerName: 'Reference',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    return params?.row?.metadata?.reference
                },
            },
            {
                field: 'community',
                headerName: 'Community',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    return params?.row?.metadata?.community
                },
            },
            {
                field: 'createdAt',
                headerName: 'Date',
                flex: 1,
                type: 'dateTime',
                headerClassName: classes.tableHeader,
                filterable: false,
                valueGetter: (params) => {
                    const date = params?.row?.createdAt
                    return formatDate(date);
                },
            }
        ];
    }, []);

    return (
        <Box>
            <ContentHeader
                title={"Subscribers"}
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
            >
                <Button variant='contained'
                    style={{ marginRight: '20px' }}
                    onClick={() => setOpenExportDialog(true)}
                >
                    Export
                </Button>
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onCellClick={handleCellClick}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ShowCommentDialog
                open={openDialog} comment={comment}
                onClose={() => setOpenDialog(false)}
            />
            <ExportSubscribersDialog type='subscriber'
                onClose={() => setOpenExportDialog(false)} open={openExportDialog}
            />
        </Box>
    );
}

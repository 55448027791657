import React from 'react';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import { sendCrashReport } from 'services/errorReportingService';

const sx = {
  'container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundImage: 'url(https://assets.languify.in/images/error-bg-img.png)',
    backgroundSize: 'cover',
  },
  'leftbox': {
    width: '54%',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
  },
  'rightbox': {
    width: '46%',
    height: 'fit-content',
  },
  img: {},
  title: {
    fontSize: '44px',
    fontWeight: '500',
    letterSpacing: 0.8
  },
  subtitle: {
    fontSize: '28px',
    fontWeight: '500',
  },
  response: {
    fontSize: '15px',
    fontWeight: '500',
  },
  content: {
    fontSize: '24px',
    fontWeight: '500',
  },
  btn: {
    fontSize: '15px',
    fontWeight: '600',
    textTransform: 'none',
    letterSpacing: 0.7,
    color: 'white',
    backgroundColor: '#02569D',
    width: 200
  },
  disableBtn: {
    fontSize: '15px',
    fontWeight: '600',
    textTransform: 'none',
    letterSpacing: 0.7,
    color: 'white',
    backgroundColor: '#02569D',
    width: 200,
    opacity: 0.6,
    cursor: 'not-allowed',
  },
}

const ReportErrorContent = <>Could you please share the issue with us? <br /> Because we don’t want to loose you again  <br /> :(</>;
const ReportedErrorContent = <>We appreciate your effort helping us in <br /> improvement   <br /> :)</>;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, reported: false };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  reportError = async () => {
    try {
      const _error = {
        error: JSON.stringify(this.state.error.message),
        stack: JSON.stringify(this.state.error.stack)
      };

      sendCrashReport(_error, this.state.errorInfo);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ reported: true });
    }
  };

  navigateToHomePage = () => {
    window.open('/', '_self');
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Box sx={sx.container}>
          <Box sx={sx.leftbox}>
            <img
              src="https://assets.languify.in/images/maze.png"
              alt="Something went wrong"
              style={sx.img}
            />
          </Box>
          <Box sx={sx.rightbox}>
            <Typography sx={sx.title} mb={1}>
              {(this.state.reported) ? 'Thanksss!' : 'WHOOPS!'}
            </Typography>
            <Typography sx={sx.subtitle} mb={1}>
              {(this.state.reported) ? 'You are awesomee!!' : 'We lost you!'}
            </Typography>
            <Typography sx={sx.content} mb={3}>
              {(this.state.reported) ?
                ReportedErrorContent :
                ReportErrorContent
              }
            </Typography>
            <Button
              variant="contained"
              onClick={(this.state.reported) ? this.navigateToHomePage : this.reportError}
              style={sx.btn}
            >
              {(this.state.reported) ?
                'Home Page' :
                'Report Problem'
              }
            </Button>
          </Box>
        </Box>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
export const Sections = [
  { id: "reading", label: "Reading" },
  { id: "writing", label: "Writing" },
  { id: "listening", label: "Listening" },
  { id: "speaking", label: "Speaking" },
];

export const MockSectionQuestionTypeMapping = {
  "ielts": {
    "reading": [
      { id: "scq", label: "SCQ" },
      { id: "mcq", label: "MCQ" },
      { id: "fib", label: "Fill in the blanks" },
    ],
    "listening": [
      { id: "scq", label: "SCQ" },
      { id: "mcq", label: "MCQ" },
      { id: "fib", label: "Fill in the Blanks" },
      { id: "mark_the_para", label: "Mark the paragraph" },
    ],
    "writing": [{ id: "written", label: "Written" },],
    "speaking": [{ id: "spoken", label: "Spoken" }],
  },
  "toefl": {
    "reading": [
      { id: "scq", label: "SCQ" },
      { id: "mcq", label: "MCQ" },
      { id: "drag_and_drop", label: "Drag and Drop" }
    ],
    "listening": [{ id: "scq", label: "SCQ" }, { id: "mcq", label: "MCQ" }],
    "speaking": [{ id: "spoken", label: "Spoken" }],
    "writing": [{ id: "written", label: "Written" },],
  },
};

export const TOEFLWritingSubSections = [
  { id: "BRIEF", label: "Brief" },
  { id: "AUDIO", label: "Audio Passage" },
  { id: "PASSAGE", label: "Passage" },
  { id: "QUESTION", label: "Question" }
];

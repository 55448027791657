import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CustomButton from 'components/CustomButton';
import FileUploadInput from 'components/FileUploadInput';
import ContentEditableDiv from './ContentEditableDiv';
import DeleteButton from './DeleteButton';
import DND from './DND';
import FIB from './FIB';
import MCQ from './MCQ';
import SCQ from './SCQ';


export default function Question({ index, type, question, group, options, onDelete }) {
  const [answer, setAnswer] = React.useState(type === 'mcq' ? [] : "");
  const [fibType, setFIBType] = React.useState('start');
  const [dndOptions, setDndOptions] = React.useState([]);

  React.useEffect(() => {
    question.answer = answer;
  }, [answer, question]);

  React.useEffect(() => {
    question.options = dndOptions;
  }, [dndOptions, question]);

  return (
    <Box border="1px solid grey" borderRadius={1} my={1} ml={1} p={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography >Q{index + 1}</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          {type === "fib" && (
            <>
              {["Start", "Middle", "End"].map((type, i) => (
                <CustomButton
                  key={i} size="small" variant="outlined" style={{ padding: "0px 8px" }}
                  onClick={() => setFIBType(type.toLowerCase())}
                  disabled={type.toLowerCase() === fibType}
                >
                  {type}
                </CustomButton>
              ))}
            </>
          )}
          {type === "drag_and_drop" && (
            <>
              <CustomButton
                size="small" variant="outlined" style={{ padding: "0px 8px" }}
                onClick={() => setDndOptions(opts => [...opts, ""])}
              >
                Add Option
              </CustomButton>
            </>
          )}
          <DeleteButton
            index={index}
            onDelete={onDelete}
            text={`Delete question ${index + 1}?`}
          />
        </Stack>
      </Box>
      <Box my={1}>
        {type === "scq" && (
          <SCQ
            index={index}
            question={question}
            answer={answer}
            setAnswer={setAnswer}
          />
        )}
        {type === "mcq" && (
          <MCQ
            index={index}
            question={question}
            answer={answer}
            setAnswer={setAnswer}
          />
        )}
        {type === "written" && (
          <>
            <TextField
              required
              fullWidth
              inputProps={{ min: 0, max: 2000 }}
              size="small"
              type="number"
              label={"Expected words"}
              placeholder="300"
              onInput={(e) => question.expectedWords = e.target.value}
            /><br /><br />
            <ContentEditableDiv
              required
              label={"Question"}
              onInput={e => question.content = e.target.innerHTML}
            />
          </>
        )}
        {type === "spoken" && (
          <>
            <TextField
              required
              fullWidth
              inputProps={{ min: 0, max: 2000 }}
              size="small"
              type="number"
              label={"Recording Time(secs)"}
              placeholder="30"
              onInput={(e) => question.time = +e.target.value}
            /><br /><br />
            <FileUploadInput
              toPublic
              uploadLocation={"audio/mock"}
              accept={["audio/*"]}
              onUpload={({ url, name }) => question.audio = url}
            />
          </>
        )}
        {type === "mark_the_para" && (
          <>
            <TextField
              required
              fullWidth
              inputProps={{ minlen: 0, maxlen: 1000 }}
              size="small"
              label={"Q".concat(index + 1)}
              placeholder="Add question..."
              onInput={(e) => question.content = e.target.value}
            />
            <br /><br />
            <FormControl size="small" required disabled={group.options.length <= 0}>
              <InputLabel id="mock-type-select-label">
                Answer
              </InputLabel>
              <Select
                label="Mock Type"
                labelId='mock-type-select-label'
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
              >
                {options.map((option, i) => (
                  <MenuItem value={option} key={i}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {type === "drag_and_drop" && (
          <DND
            index={index}
            answer={answer}
            setAnswer={setAnswer}
            question={question}
            options={dndOptions}
            setOptions={setDndOptions}
          />
        )}
        {type === "fib" && (<>
          <FIB
            index={index}
            answer={answer}
            setAnswer={setAnswer}
            question={question}
            fibType={fibType}
          />
        </>)}
      </Box>
    </Box>
  );
}

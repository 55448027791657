import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomLink from './CustomLink';
import { areRolesAllowed } from 'utils/roles';

const useStyles = makeStyles(theme => ({
    sideBarRoot: {
        bottom: "0px",
        left: "0px",
        position: "fixed",
        top: "56px",
        zIndex: 200,
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 0px 4px rgb(0 0 0 / 15%)',
        borderBottom: '1px solid rgb(0 0 0 / 15%)',
    },
    sideBar: {
        boxSizing: 'border-box',
        border: 'none',
        minWidth: 200,
        paddingTop: "20px",
    },
    menuItem: {
        width: "100%",
        textDecoration: 'unset',
        color: 'black',
        display: "flex",
        alignItems: "center",
        padding: "8px 0",
    },
    menuItemText: {
        transition: "all 1s ease",
        transformOrigin: "0 50% 0",
        overflow: "hidden",
        height: "1.6em",
        marginLeft: '1em',
    },
    activeMenuItem: {
        borderLeft: "4px solid #2DABFF",
        background: "#2DABFF1C",
        width: "100%",
        textDecoration: 'unset',
        color: 'black',
        display: "flex",
        alignItems: "center",
        padding: "8px 0",
    },
}));

function MenuItem({ menu }) {
    const classes = useStyles();

    return areRolesAllowed(...menu.allowedRoles) ?
        (
            <CustomLink
                className={classes.menuItem}
                activeClassName={classes.activeMenuItem}
                to={menu.path}
            >
                <Typography
                    variant='body2'
                    className={classes.menuItemText}
                    color="black"
                >
                    {menu.label}
                </Typography>
            </CustomLink>
        ) : null;
}

export default function Sidebar({ sideBarLinks = [] }) {
    const classes = useStyles();
    return sideBarLinks.length ? (
        <Box className={classes.sideBarRoot}>
            <Box className={classes.sideBar}>
                {sideBarLinks.map((sideBarLink, index) => (
                    <MenuItem key={index} menu={sideBarLink} />
                ))}
            </Box>
        </Box>
    ) : null;
}

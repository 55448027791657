import React from 'react'
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box'
import {
    DataGrid,
    GridActionsCellItem
} from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentHeader from 'components/ContentHeader';
import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import ListPagination from 'components/ListPagination';
import { getMockAttempts } from "services/attemptService";
import { formatDate } from 'utils';
import { useList } from 'hooks/useList';
import FilterList from './FilterList';
import MockSelect from 'components/MockSelect';

const useStyles = makeStyles((theme) => ({
    clickable: {
        cursor: "pointer",
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        height: 'fit-content',
        width: "100%",
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
            height: "fit-content",
            width: "100%",
            "& .MuiDataGrid-columnHeaderTitleContainer": {
                height: "fit-content",
                width: "100%",
                "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                    height: "fit-content",
                    width: "100%",
                }
            }
        }
    },
}));

const BaseFilters = {
    include: ["user"], includeTotal: true,
};

function MockAnalytics() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);

    const {
        loading, items, total, filters, setFilters, setPageNo, setPageSize
    } = useList({
        getItems: getMockAttempts,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "mockAttempts",
        deleteItemById: () => { },
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value)
            setFilters({
                ...BaseFilters,
                ...filters,
                [columnField]: value
            });
        else
            setFilters(BaseFilters);
    };

    const handleUserChange = React.useCallback((user) => {
        if (!user) return;

        handleFilterChange({
            items: [{
                columnField: 'user',
                value: user._id
            }]
        });
    }, []);
    const handleSelectionChange = (value,type)=>{
        if(!value) return;
        handleFilterChange({
            items:[{columnField:type,value:value}]
        })

    }

    const columns = [
        {
            field: 'contact',
            headerName: 'Phone',
            flex: 1,
            headerClassName: classes.tableHeader,
            valueGetter: (params) => params?.row?.user?.contact || 'Guest',
            filterable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2,
            headerClassName: classes.tableHeader,
            valueGetter: (params) => params?.row?.user?.email || '-',
            filterable: false,
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: (params) => params?.row?.type,
        },
        {
            field: 'referralCode',
            headerName: 'Referral Code',
            flex: 1,
            headerClassName: classes.tableHeader,
            valueGetter: (params) => params?.row?.referralCode || "N/A",
            filterable: true,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 2,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: (params) => formatDate(params?.row?.createdAt, 'DD-MM-YYYY')
        },
        {
            field: 'finishedAt',
            headerName: 'Finished at',
            flex: 2,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: (params) => (params?.row?.finishedAt) ?
                formatDate(params?.row?.finishedAt, 'DD-MM-YYYY') :
                'null'
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: "actions",
            width: 130,
            headerClassName: classes.tableHeader,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<VisibilityIcon />}
                    label="View"
                    onClick={(e) => setActiveItemId(params.row._id)}
                />
            ]
        },
    ];

    return (
        <Box mt={2} position="relative">
            <ContentHeader
                title="Mock (TOEFL & IELTS) Analytics"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <FilterList width={"10rem"}>
                   {[<MockSelect onChange={handleSelectionChange} type='type' label={"Type"}/>]} 
                </FilterList>
            </ContentHeader>

            <Box style={{ height: "75vh", width: '100%' }} mt={2} >
                <DataGrid
                    loading={loading}
                    pagination
                    autoPageSize
                    paginationMode='server'
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    columns={columns}
                    disableSelectionOnClick
                    density='compact'
                    getRowClassName={() => classes.row}
                    getRowId={(row) => row._id}
                    components={{
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </Box>
            <EntityViewDialog
                open={!!activeItemId}
                getEntity={(id) => items.find(item => item._id === id)}
                title="TOEFL Attempt"
                entityId={activeItemId}
                onClose={() => setActiveItemId(null)}
            />
        </Box>
    );
}

export default MockAnalytics;
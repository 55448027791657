import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FileUploadInput from 'components/FileUploadInput';
import AddButton from './AddButton';
import { MockSectionQuestionTypeMapping, TOEFLWritingSubSections } from './constants';
import ContentEditableDiv from './ContentEditableDiv';
import DeleteButton from './DeleteButton';
import Group from './Group';
import Question from './Question';

function TextArea(props) {
  return (
    <TextField
      fullWidth
      multiline
      required
      maxRows={2}
      minRows={2}
      size="small"
      {...props}
    />
  );
}


export function IELTSPart({
  index, part, expanded, onExpandChange, section, onDelete, mockType
}) {
  const [description, setDescription] = React.useState("");
  const [groups, setGroups] = React.useState(mockType === "toefl" ? [{}] : []);

  const addNewGroup = (type) => {
    const newGroups = [...groups, { part: index, type, options: [] }];
    part.groups = newGroups;
    setGroups(newGroups);
  };

  const deleteGroup = (index) => {
    const newGroups = [...groups.slice(0, index), ...groups.slice(index + 1)]
    part.groups = newGroups;
    setGroups(newGroups);
  };

  const onPassageChange = (e) => {
    part.passage = e.target.innerHTML;
  };

  React.useEffect(() => {
    part.description = description;
  }, [description, part]);

  return (
    <Accordion
      square
      elevation={0}
      expanded={expanded === index}
      onChange={onExpandChange}
      style={{ border: "1px solid grey", marginTop: 8 }}
    >
      <AccordionSummary>
        <Typography>Part {index + 1}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="fit-content"
          pb={1}
          position="absolute"
          top={8}
          right={8}
        >
          <AddButton
            onClick={addNewGroup}
            menuItems={MockSectionQuestionTypeMapping[mockType][section]}
          >
            Add Group
          </AddButton>
          <DeleteButton
            index={index}
            onDelete={onDelete}
            text={`Delete part ${index + 1}?`}
          />
        </Box>
        <Box my={1}>
          <TextArea
            label="Description"
            type={"text"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {section === "reading" && (
            <ContentEditableDiv
              required
              label="Passage"
              placeholder="Add the Passage here..."
              onInput={onPassageChange}
            />
          )}
          {section === "listening" && (
            <FileUploadInput
              label="Upload Audio"
              toPublic
              uploadLocation={"audio/mock"}
              accept={["audio/*"]}
              onUpload={({ url, name }) => part.audio = url}
            />
          )}
        </Box>
        {groups.map((group, i) => (
          <Group
            key={i}
            index={i}
            group={group}
            section={section}
            onDelete={deleteGroup}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export function TOEFLPart({
  index, part, expanded, onExpandChange, section, onDelete, mockType
}) {
  const [questions, setQuestions] = React.useState([]);

  const addNewQuestion = (type) => {
    const newQuestions = [...questions, { type, options: [] }]

    setQuestions(newQuestions);

    part.questions = newQuestions;
  };

  const deleteQuestion = (index) => {
    const newQuestions = [
      ...questions.slice(0, index), ...questions.slice(index + 1)
    ];

    setQuestions(newQuestions);

    part.questions = newQuestions;
  };

  const onPassageChange = (e) => {
    part.passage = e.target.innerHTML;
  };

  return (
    <Accordion
      square
      elevation={0}
      expanded={expanded === index}
      onChange={onExpandChange}
      style={{ border: "1px solid grey", marginTop: 8 }}
    >
      <AccordionSummary>
        <Typography>Part {index + 1}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="fit-content"
          pb={1}
          position="absolute"
          top={8}
          right={8}
        >
          {section !== 'writing' && (
            <AddButton
              onClick={addNewQuestion}
              menuItems={MockSectionQuestionTypeMapping[mockType][section]}
            >
              Add Question
            </AddButton>
          )}
          <DeleteButton
            index={index}
            onDelete={onDelete}
            text={`Delete part ${index + 1}?`}
          />
        </Box>

        <Box my={1}>
          {section === "listening" && (
            <TextField
              required
              inputProps={{ min: 0, max: 20000 }}
              size="small"
              type="number"
              label={"Time for Questions(Secs)"}
              placeholder={"120 seconds"}
              onInput={(e) => part.timeForQuestions = +e.target.value}
              sx={{ width: 250, mx: 1 }}
            />
          )}
          <TextField
            required
            inputProps={{ min: 0, max: 2000 }}
            size="small"
            label={"Passage Type"}
            placeholder={"e.g. Derivative"}
            onInput={(e) => part.type = e.target.value}
            sx={{ width: 200, mx: 1 }}
          />
          <ContentEditableDiv
            required
            label="Passage"
            placeholder="Add the Passage here..."
            onInput={onPassageChange}
            sx={{ mx: 1 }}
          />
          {["speaking", "writing", "listening"].includes(section) && (
            <FileUploadInput
              label="Upload Audio"
              toPublic
              uploadLocation={"audio/mock"}
              accept={["audio/*"]}
              onUpload={({ url, name }) => part.audio = url}
            />
          )}
        </Box>
        {questions.map((question, i) => (
          <Question
            key={i}
            index={i}
            type={question.type}
            question={question}
            onDelete={deleteQuestion}
            options={[]}
            group={question}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export function TOEFLPartWS({
  index, part, expanded, onExpandChange, section, onDelete, mockType
}) {
  const [subSections, setSubSections] = React.useState([]);

  const addSubSection = (type) => {
    switch (type) {
      case 'AUDIO':
        part.audio = {};
        break;
      case 'PASSAGE':
        part.passage = {};
        break;
      case 'QUESTION':
        part.question = {};
        break;
      case 'BRIEF':
        part.brief = {};
        break;
      default:
    }

    const newSubSections = [...subSections, type]

    setSubSections(newSubSections);

    part.subSections = newSubSections;
  };

  const deleteSubSection = (type) => {
    switch (type) {
      case 'AUDIO':
        delete part.audioPassage;
        break;
      case 'PASSAGE':
        delete part.passage;
        break;
      case 'QUESTION':
        delete part.question;
        break;
      case 'BRIEF':
        delete part.brief;
        break;
      default:
    }

    const newSubSections = subSections.filter(ss => ss !== type);

    setSubSections(newSubSections);

    part.subSections = newSubSections;
  };

  return (
    <Accordion
      square
      elevation={0}
      expanded={expanded === index}
      onChange={onExpandChange}
      style={{ border: "1px solid grey", marginTop: 8 }}
    >
      <AccordionSummary>
        <Typography>Part {index + 1}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="fit-content"
          pb={1}
          position="absolute"
          top={8}
          right={8}
        >
          {subSections.length !== TOEFLWritingSubSections.length && (
            <AddButton
              onClick={addSubSection}
              menuItems={TOEFLWritingSubSections.filter(ss => !subSections.includes(ss.id))}
            >
              Add Sub Section
            </AddButton>
          )}
          <DeleteButton
            index={index}
            onDelete={onDelete}
            text={`Delete part ${index + 1}?`}
          />
        </Box>
        {subSections.map((subSection, i) => (
          <Box key={i}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="-webkit-fill-available"
              mb={1}
              borderBottom="1px solid lightgrey"
            >
              <Typography>{subSection}</Typography>
              <DeleteButton
                index={index}
                onDelete={() => deleteSubSection(subSection)}
                text={`Delete ${subSection}?`}
              />
            </Box>
            {subSection !== "AUDIO" && (
              <>
                <TextField
                  required
                  inputProps={{ min: 0, max: 2000 }}
                  size="small"
                  type="number"
                  label={"Time(secs)"}
                  placeholder={"30"}
                  onInput={(e) => part[subSection.toLowerCase()].time = +e.target.value}
                  sx={{ width: 200, mx: 1 }}
                />
                {subSection === "QUESTION" && (
                  <>
                    {section === "speaking" && <TextField
                      required
                      inputProps={{ min: 0, max: 2000 }}
                      size="small"
                      type="number"
                      label={"Preparation Time(secs)"}
                      placeholder={"60"}
                      onInput={(e) => part["question"].preparationTime = +e.target.value}
                      sx={{ width: 250, mx: 1 }}
                    />}
                    {section === "writing" && <TextField
                      required
                      inputProps={{ min: 0, max: 2000 }}
                      size="small"
                      type="number"
                      label={"Expected Words"}
                      placeholder={"10"}
                      onInput={(e) => part.question.expectedWords = +e.target.value}
                      sx={{ width: 200, mx: 1 }}
                    />}
                  </>
                )}
                {subSection === "PASSAGE" && (
                  <TextField
                    required
                    inputProps={{ min: 0, max: 2000 }}
                    size="small"
                    label={"Passage Type"}
                    placeholder={"Derivative"}
                    onInput={(e) => part.passage.type = e.target.value}
                    sx={{ width: 200, mx: 1 }}
                  />
                )}
                <ContentEditableDiv
                  required
                  label={"Content"}
                  placeholder="Add the content here..."
                  onInput={(e) =>
                    part[subSection.toLowerCase()].content = e.target.innerHTML
                  }
                  sx={{ mx: 1 }}
                />
              </>
            )}
            {subSection === "AUDIO" && (
              <FileUploadInput
                label="Upload Audio"
                toPublic
                uploadLocation={"audio/mock"}
                accept={["audio/*"]}
                onUpload={({ url, name }) => part.audio.audioUrl = url}
              />
            )}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
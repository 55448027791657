export const BasePermissions = {
    InternalUser: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    Client: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    Feedback: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    FieldOption: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    InterviewAnswer: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    InterviewAttempt: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    InterviewDomain: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    InterviewJobDescription: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    InterviewQuestion: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    InterviewResume: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    InterviewTemplate: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    MeetingAnalysis: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    MeetingSession: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    Role: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    SurveyQuestion: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    User: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    UserSurvey: {
        CREATE: false,
        DELETE: false,
        READ: false,
        UPDATE: false
    },
    SEPAttempt: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    SEPTemplate: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    Scale: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    Draft: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    TOEFLAttempt: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    TemplateTag: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    Batch: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    TOEFLTemplate: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    BatchUserMapping: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    Referral: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    BatchTemplateMapping: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    IELTSAttempt: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    IELTSTemplate: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
    MockSection: {
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        READ: false,
    },
};

export default BasePermissions;
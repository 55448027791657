import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import ContentShell from "components/ContentShell";
import ExtensionUsers from "components/lists/ExtensionUsers";
import InterviewUsers from "components/lists/InterviewUsers";
import InterviewForm from "components/forms/InterviewForm";
import SEPUsers from "components/lists/SEPUsers";
import InternalUsers from "components/lists/InternalUsers";
import InternalUserEditor from "components/forms/InternalUserEditor";
import MockUsers from "components/lists/MockUsers";
import CAPUsers from "components/lists/CAPUsers";
import CAPEditor from "components/forms/CAPEditor";
import UserSearch from "components/lists/UserSearch";
import SEPResumeUsers from "components/lists/SEPResumeUsers";

export default function Users() {
    return (
        <Routes>
            <Route path="/" element={<UsersLayout />}>
                <Route index element={<Navigate to="inprep" />} />

                <Route path="inprep" element={<InterviewUsers />} />
                <Route path='inprep/:id' element={<InterviewForm />} />

                <Route
                    path="extension"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <ExtensionUsers />
                        </React.Suspense>
                    }
                />

                <Route
                    path="resume"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SEPResumeUsers />
                        </React.Suspense>
                    }
                />

                <Route
                    path="sep"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SEPUsers />
                        </React.Suspense>
                    }
                />


                <Route
                    path="mock"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <MockUsers />
                        </React.Suspense>
                    }
                />
                <Route
                    path="cap"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <CAPUsers />
                        </React.Suspense>
                    }
                />
                <Route
                    path="cap/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <CAPEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path="internal"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <InternalUsers />
                        </React.Suspense>
                    }
                />

                <Route
                    path="internal/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <InternalUserEditor />
                        </React.Suspense>
                    }
                />
                <Route
                    path="user-search"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <UserSearch />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

const SideBarLinks = [
    {
        label: "InPrep",
        path: 'inprep',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Extension",
        path: 'extension',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "SEP",
        path: 'sep',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "SEP Resume",
        path: 'resume',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Campus Ambassador",
        path: 'cap',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Mock",
        path: 'mock',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
    },
    {
        label: "Super Admin",
        path: 'internal',
        allowedRoles: ["ADMIN"],
    },
    {
        label: "Search Users",
        path: 'user-search',
        allowedRoles: ["ADMIN", "PRODUCT", "SALES"],
    },
];

function UsersLayout() {
    return (
        <ContentShell sideBarLinks={SideBarLinks}>
            <Outlet />
        </ContentShell>
    );
}
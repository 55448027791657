import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import ContentHeader from 'components/ContentHeader';
import FormikForm from "components/FormikForm";
import { useSnackbar } from "contexts";
import { saveApplication, getApplicationById } from "services";

const applicationsValidationSchema = Yup.object().shape({
    name: Yup.string().lowercase().required('Required'),
});

const fields = [{ name: 'name', type: 'text', label: 'Name' }];

export default function ApplicationEditor() {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = React.useState({ name: '' });
    const { id } = useParams();

    React.useEffect(() => {
        if (id !== "create") {
            (async () => {
                try {
                    let application = await getApplicationById(id);
                    setInitialValues({ name: application.name });
                } catch (error) {
                    snackbar.showSnackbar(
                        "Unable to get application", "error", true
                    );
                }
            })();
        }
    }, [id]);

    async function createOrUpdateApplication(values) {
        try {
            if (id === "create")
                await saveApplication(null, values.name);
            else
                await saveApplication(id, values.name);
            snackbar.showSnackbar(
                "Field Option saved successfully", 'success'
            );
            navigate("/configs/applications");
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', true);
        }
    }

    function goBack() {
        navigate("/configs/applications");
    }

    return (
        <>
            <ContentHeader title={"Application Form"} />
            {((id !== "create" && initialValues.option !== "") || id === "create") &&
                <FormikForm
                    initialValues={initialValues}
                    fields={fields}
                    validationSchema={applicationsValidationSchema}
                    onSubmit={createOrUpdateApplication}
                    onCancel={goBack}
                />}
        </>
    );
}
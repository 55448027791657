import * as React from 'react';
import {
    DataGrid,
    getGridSingleSelectOperators,
    getGridStringOperators
} from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import UserAutoComplete from 'components/UserAutoComplete';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { useList } from "hooks/useList";
import { getFieldOptions, deleteFieldOptionById } from "services/fieldOption";
import { getListActions, checkCreatePermission } from "utils/listActions";
import { formOptions } from 'components/forms/OptionEditor';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    include: ["user"],
    includeTotal: true,
};

export default function Options() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);

    const {
        loading,
        items,
        total,
        editItem,
        deleteItem,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getFieldOptions,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 20,
        itemName: "fieldOptions",
        deleteItemById: deleteFieldOptionById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value) {
            setFilters({ ...BaseFilters, [columnField]: value });
        } else {
            setFilters(BaseFilters);
        }
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleConfirmationSuccess = () => {
        deleteItem(activeItemId);
        setActiveItemId(null);
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: 'field',
                headerName: 'Field',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: true,
                type: "singleSelect",
                valueOptions: formOptions.field.map(f => ({ value: f._id, label: f.name })),
                filterOperators: getGridSingleSelectOperators().filter(
                    (operator) => operator.value === 'is',
                ),
            },
            {
                field: 'option',
                headerName: 'Option',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const { email } = params?.row?.user || 'Not Available';
                    return email;
                },
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) =>
                    getListActions(params.id, editItem, setActiveItemId, "FieldOption")
            },
        ];
    }, []);

    const handleUserChange = React.useCallback((user) => {
        if (!user) return;

        handleFilterChange({
            items: [{
                columnField: 'user',
                value: user._id
            }]
        });
    }, []);

    return (
        <Box>
            <ContentHeader
                title="Dropdown Field Options"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <UserAutoComplete
                    onSelect={handleUserChange}
                    application="interview"
                    field="email"
                />
                &nbsp;
                {checkCreatePermission("FieldOption") &&
                    <CustomButton
                        variant="contained"
                        onClick={editItem}
                    >
                        Create
                    </CustomButton>
                }
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ConfirmationDialog
                message={
                    `This field option will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete Field Option"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
        </Box>
    );
}
import React, { useEffect, useMemo } from 'react';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CustomButton from './CustomButton';
import Chip from '@mui/material/Chip';
import { uploadFile } from "services/uploadService";
import { useSnackbar } from "contexts";
import classNames from 'classnames';

const useStyles = makeStyles({
  fileDiv: {
    position: 'relative',
    maxWidth: 400,
    backgroundColor: '#F4F5F5',
    borderRadius: 8,
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1em',
  },
  fileDivError: {
    border: '1px solid red'
  },
  fileLabel: {
    position: 'absolute',
    top: -10, backgroundColor: '#ffffff',
    padding: '2px 10px', textTransform: 'capitalize',
    fontSize: '12px'
  },
  fileSubDiv: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    height: 48,
  },
  fileName: {
    fontFamily: 'Sora',
    fontWeight: 600,
    fontSize: 18,
    color: '#2C3840',
    flexGrow: 1,
  },
  uploadingDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'fit-content',
    paddingRight: 8,
  },
  uploadingText: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: 16,
    color: '#858C90'
  },
  cancelIcon: {
    width: 25,
    height: 25,
    alignSelf: 'center',
    cursor: 'pointer',
    backgroundColor: '#ffffff', borderRadius: '50%', padding: '2px',
  },
});

const ChooseFileButton = ({ name='btn-upload', onFileSelected, accept = [], disabled, label }) => {
  return (
    <label htmlFor={name}>
      <input
        id={name}
        name={name}
        style={{ display: 'none' }}
        type="file"
        onChange={onFileSelected}
        accept={accept.join(",")}
        disabled={disabled}
      />
      <CustomButton
        variant="contained"
        component="span"
        disabled={disabled}
      >
        {label}
      </CustomButton>
    </label>
  );
};

export default function FileUploadInput({
  name,
  uploadLocation,
  file = { name: undefined, url: "" },
  toPublic = false,
  accept = [],
  onUpload = () => { },
  onUploadCancelled = () => { },
  errors = {},
  setErrors = () => { },
  readOnly = false,
  label = 'Choose file'
}) {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [state, setState] = React.useState(file.name ? "LOADED" : "NO_FILE");
  const [uploading, setUploading] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(file);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [fileData, setFileData] = React.useState();

  const selectFile = (event) => {
    if (event.target.files.length) {
      setState("NEW_FILE_SELECTED");
      setSelectedFile(event.target.files[0]);

      let reader = new FileReader();
      reader.onload = (e) => {
        setFileData(e.target.result);
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const handleUploadSuccess = (error, uploadedFile) => {
    setUploading(false);
    if (error) {
      setState('FAILED');
      setMessage("Upload failed");
    } else {
      setState('UPLOADED');
      setMessage("Uploaded");
      snackbar.showSnackbar("File uploaded successfully!!", "success", true);
      setSelectedFile(uploadedFile);
      if (onUpload && typeof onUpload === 'function') onUpload(uploadedFile);
    }
  };

  useEffect(() => {
    if (fileData && selectedFile && !selectedFile.url) {
      setState("UPLOADING");
      setUploading(true);
      setMessage("Uploading...");
      uploadFile(selectedFile, fileData, uploadLocation, toPublic).then((url) => {
        const uploadedFile = {
          name: selectedFile.name,
          url,
        };
        handleUploadSuccess(null, uploadedFile);
        setUploading(false);
        setMessage("Uploaded");
        setState("UPLOADED");
        setErrors({ ...errors, file: false });
      }).catch((e) => {
        setState('FAILED');
        setMessage("Upload failed");
        setErrors({ ...errors, file: true });
        console.error(e);
      });
    }
  }, [fileData, selectedFile]);

  const handleCancelClick = () => {
    if (uploading) {
      setUploading(false);
    }
    setState("NO_FILE");
    setSelectedFile({ name: undefined, url: "" });
    setFileData();
    if (onUploadCancelled && typeof onUploadCancelled === 'function')
      onUploadCancelled();
  };

  const onFileClick = () =>
    state === 'LOADED' && file.url && window.open(file.url, '_blank');

  useEffect(() => {
    if (file.name) {
      setSelectedFile(file);
      setState('UPLOADED');
    }
    else {
      setSelectedFile(file);
      setState('NO_FILE');
    }
  }, [file])

  const hasUploadLocation = useMemo(() => {
    return !!uploadLocation;
  }, [uploadLocation]);

  return (
    <Box
      className={classNames([
        (errors?.file || !hasUploadLocation) && classes.fileDivError,
        classes.fileDiv
      ])}
    >
      <Typography className={classes.fileLabel}>
        {label}
      </Typography>
      <Box className={classes.fileSubDiv}>
        <Typography
          variant="caption"
          onClick={onFileClick}
          color={hasUploadLocation ? "default" : "error"}
        >
          {
            hasUploadLocation ?
              selectedFile.name || "No file choosen" :
              "Upload location not provided"
          }
        </Typography>
        {state === 'UPLOADING' && (
          <>
            <Box className={classes.uploadingDiv} alignItems="center">
              <Chip
                className={classes.uploadingText}
                color={error ? 'error' : uploading ? 'info' : 'success'}
                label={message}
                variant="outlined"
              />
            </Box>
          </>
        )}
        {(state === 'NO_FILE' || state === 'FAILED') && (
          <ChooseFileButton
            onFileSelected={selectFile}
            accept={accept}
            disabled={readOnly || !hasUploadLocation}
            label={label}
            name={name}
          />
        )}
        {(['LOADED', 'UPLOADED'].includes(state)) && (
          <IconButton
            style={{ position: 'absolute', right: 0, fontSize: '20px' }}
            onClick={handleCancelClick}
            disabled={readOnly || !hasUploadLocation}
          >
            <CloseIcon className={classes.cancelIcon} />
          </IconButton>
        )
        }
      </Box>
    </Box>
  );
} 
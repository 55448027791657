import * as React from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { useList } from "hooks/useList";
import { getApplications, deleteApplicationById } from "services/applications";
import { getListActions, checkCreatePermission } from "utils/listActions";

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main, color: "white",
    }
}));

const BaseFilters = {
    includeTotal: true,
};

export default function Applications() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);

    const {
        loading,
        items,
        total,
        editItem,
        deleteItem,
        filters,
        setFilters,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getApplications,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "applications",
        deleteItemById: deleteApplicationById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value) {
            setFilters({ ...BaseFilters, [columnField]: value });
        } else {
            setFilters(BaseFilters);
        }
    };

    const handleConfirmationSuccess = () => {
        deleteItem(activeItemId);
        setActiveItemId(null);
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: '_id',
                headerName: 'ID',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) =>
                    getListActions(params.id, editItem, setActiveItemId, "Application")
            },
        ];
    }, []);

    return (
        <Box>
            <ContentHeader
                title="Applications"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                {/* {checkCreatePermission("Application") && */}
                <CustomButton
                    variant="contained"
                    onClick={editItem}
                >
                    Create
                </CustomButton>
                {/* } */}
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ConfirmationDialog
                message={
                    `This application will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete Application"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
        </Box>
    );
}
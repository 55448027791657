import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import AddButton from './AddButton';
import { Sections } from './constants';
import { IELTSPart, TOEFLPart, TOEFLPartWS } from './Part';
import { createIELTSMockSection, createTOEFLMockSection } from 'services';
import { useLoading, useSnackbar } from 'contexts';

// Section-wise timing in seconds
const SectionTimeMap = {
  ielts: {
    short: {
      speaking: 5 * 60, writing: 5 * 60, listening: 11 * 60, reading: 9 * 60,
    },
    full: {
      speaking: 15 * 60, writing: 30 * 60, listening: 30 * 60, reading: 60 * 60,
    },
  },
  toefl: {
    short: {
      speaking: 5 * 60, writing: 8 * 60, listening: 7 * 60, reading: 10 * 60,
    },
    full: {
      speaking: 20 * 60, writing: 60 * 60, listening: 45 * 60, reading: 45 * 60,
    },
  }
};

function MockSectionCreator(props) {
  const snackbar = useSnackbar();
  const loading = useLoading();

  const [expanded, setExpanded] = React.useState(0);
  const [mockType, setMockType] = React.useState('');
  const [short, setShort] = React.useState('');
  const [section, setSection] = React.useState('');
  const [duration, setDuration] = React.useState('');
  const [parts, setParts] = React.useState([]);

  const addNewPart = () => {
    setParts([...parts, { time: {} }]);
  };

  const deletePart = (index) => {
    setParts([...parts.slice(0, index), ...parts.slice(index + 1)]);
  };

  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const saveSection = async () => {
    try {
      loading.show();

      if (mockType === "ielts") {
        await createIELTSMockSection({
          mockType, duration: duration * 60, parts, short,
          name: section.charAt(0).toUpperCase().concat(section.slice(1)),
        });
      } else {
        await createTOEFLMockSection({
          mockType, duration: duration * 60, parts, short,
          name: section.charAt(0).toUpperCase().concat(section.slice(1)),
        });
      }
    } catch (error) {
      console.error(error);
      snackbar.showSnackbar(
        error.message || "Uh Oh! Something went wrong", "error"
      );
    } finally {
      loading.hide();
    }
  };

  React.useEffect(() => {
    if (mockType && section && short !== '') {
      setDuration(SectionTimeMap[mockType][short ? 'short' : 'full'][section] / 60);
    }
  }, [mockType, section, short]);

  return (
    <>
      <ContentHeader title={"Add Mock Section"}>
        {(mockType && section && duration) && (
          <AddButton onClick={addNewPart}>
            Add Part
          </AddButton>
        )}
      </ContentHeader>
      <Box
        py={2}
        display="flex"
        alignItems="center"
        gap={2}
      >
        <FormControl size="small" required disabled={parts.length > 0}>
          <InputLabel id="mock-type-select-label">
            Mock Type
          </InputLabel>
          <Select
            label="Mock Type"
            labelId='mock-type-select-label'
            value={mockType}
            onChange={(e) => setMockType(e.target.value)}
            style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
          >
            <MenuItem value="toefl">TOEFL</MenuItem>
            <MenuItem value="ielts">IELTS</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" required disabled={parts.length > 0}>
          <InputLabel id="section-select-label">
            Section Name
          </InputLabel>
          <Select
            label="Section Name"
            labelId="section-select-label"
            value={section}
            onChange={(e) => setSection(e.target.value)}
            style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {Sections.map((section, i) => (
              <MenuItem key={i} value={section.id}>
                {section.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" required disabled={parts.length > 0}>
          <InputLabel id="mock-type-select-label">
            Test Length
          </InputLabel>
          <Select
            label="Test Length"
            labelId='test-length-select-label'
            value={short}
            onChange={(e) => setShort(e.target.value)}
            style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
          >
            <MenuItem value={true}>Short</MenuItem>
            <MenuItem value={false}>Full</MenuItem>
          </Select>
        </FormControl>
        <TextField
          required
          inputProps={{ min: 0, max: 7200 }}
          size="small"
          label="Total Duration(min)"
          type={"number"}
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          style={{ width: 200, margin: 0 }}
        />
        <CustomButton variant="contained" onClick={saveSection}>
          Save
        </CustomButton>
      </Box>
      <Typography variant='caption' color="CaptionText">
        *Mock Type & Section cannot be changed after adding parts.
        You need to remove all the parts if you want to edit these fields.
      </Typography>

      {mockType === "ielts" && parts.map((part, i) => (
        <IELTSPart
          key={i}
          index={i}
          onDelete={deletePart}
          expanded={expanded}
          onExpandChange={handleExpandChange(i)}
          part={part}
          section={section}
          mockType={mockType}
        />
      ))}
      {mockType === "toefl" && parts.map((part, i) => ["writing", "speaking"].includes(section) ? (
        <TOEFLPartWS
          key={i}
          index={i}
          onDelete={deletePart}
          expanded={expanded}
          onExpandChange={handleExpandChange(i)}
          part={part}
          section={section}
          mockType={mockType}
        />
      ) : (
        <TOEFLPart
          key={i}
          index={i}
          onDelete={deletePart}
          expanded={expanded}
          onExpandChange={handleExpandChange(i)}
          part={part}
          section={section}
          mockType={mockType}
        />
      ))}
    </>
  );
}

export default MockSectionCreator;
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import ContentHeader from 'components/ContentHeader';
import FormikForm from "components/FormikForm";
import { useSnackbar } from "contexts";
import { saveFieldOption, getFieldOptionById } from "services";

const fieldOptionsValidationSchema = Yup.object().shape({
    field: Yup.string()
        .required('Required'),
    option: Yup.string()
        .required("Required")
});

const fields = [
    {
        name: 'field',
        type: 'select',
        label: 'Field'
    },
    {
        name: 'option',
        type: 'text',
        label: 'Option'
    }
];

export const formOptions = {
    field: [
        {
            _id: "company",
            name: "Company"
        },
        {
            _id: "domain",
            name: "Domain"
        },
        {
            _id: "jobRole",
            name: "Job Role"
        },
        {
            _id: "designation",
            name: "Designation"
        },
        {
            _id: "qualification",
            name: "Qualification"
        },
        {
            _id: "interviewAccent",
            name: "Interview Accent"
        },
    ]
};

export default function OptionsEditor() {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = React.useState({
        field: '',
        option: ''
    });
    const { id } = useParams();

    React.useEffect(() => {
        if (id !== "create") {
            (async () => {
                try {
                    let tempFieldOption = await getFieldOptionById(id);
                    setInitialValues({
                        field: tempFieldOption.field,
                        option: tempFieldOption.option
                    });
                } catch (error) {
                    snackbar.showSnackbar("Unable to get field option", "error", true);
                }
            })();
        }
    }, []);

    async function createOrUpdateFieldOption(values) {
        let fieldOptionData = {
            field: values.field,
            option: values.option
        };
        try {
            if (id === "create")
                await saveFieldOption(null, fieldOptionData);
            else
                await saveFieldOption(id, fieldOptionData);
            snackbar.showSnackbar("Field Option saved successfully", 'success', true);
            navigate("/general/options");
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', true);
        }
    }

    function goBack() {
        navigate("/general/options");
    }

    return (
        <>
            <ContentHeader title={"Field Options Form"} />
            {((id !== "create" && initialValues.option !== "") || id === "create") &&
                <FormikForm
                    initialValues={initialValues}
                    fields={fields}
                    formOptions={formOptions}
                    validationSchema={fieldOptionsValidationSchema}
                    onSubmit={createOrUpdateFieldOption}
                    onCancel={goBack}
                />}
        </>
    );
}
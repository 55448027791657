import Client from "./_client";

export const getScales = async (scaleFilters = {}) => {
    let url = "/scale?";

    Object.keys(scaleFilters).forEach((key) => {
        if (scaleFilters[key]) url += `${key}=${scaleFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get scales. Please try again.');
    }

    return result.data;
}

export const getScaleById = async (scaleId) => {
    const result = await new Client({
        path: `/scale/${scaleId}`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get scale. Please try again.');
    }

    return result.data;
}

export const saveScale = async (scaleId, data) => {
    let result;

    if (scaleId) {
        result = await new Client({
            path: `/scale/${scaleId}`,
            payload: data,
        }).put();
    } else {
        result = await new Client({
            path: `/scale`,
            payload: data,
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save scale. Please try again."
        );
    } else {
        return result.data;
    }
}

export const deleteScaleById = async (scaleId) => {

    let url = `/scale/${scaleId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete scale. Please try again."
        );

    return result.data;
}


export const getScaleByUserId = async (userId) => {
    const result = await new Client({
        path: `/scale/user/${userId}`
    }).get();


    if (result.name === "NotFoundError") {
        throw new Error("Scale not been added yet for the client!");
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get scale. Please try again.');
    }

    return result.data;
}

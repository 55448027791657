import React from "react";
import shallow from "zustand/shallow";
import { FieldConfigs } from "./constants";
import useEditorStore from "./editorStore";

const useField = ({ index, name }) => {
  const FieldConfig = React.useMemo(() => FieldConfigs[name], [name]);
  const updateQuestion = useEditorStore(state => state.updateQuestion, shallow);

  const [error, setError] = React.useState("");

  const handleChange = (e) => {
    let value = e.target.value;
  
    if (name === "allottedTime") {
      value = parseInt(value, 10); 
  
      if (value > 20) {
        setError("Value cannot exceed 20 mins");
        return;
      }
    }

    if (name !== "allottedTime" && value === "") {
      value = "";  
    }
  
    setError(""); 
    updateQuestion(index, { target: { name, value } });  
  };

  return {
    handleChange,
    config: FieldConfig,
    error,
  };
};

export default useField;
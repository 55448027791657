import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from "@mui/icons-material/Add";

import ContentHeader from "components/ContentHeader";
import CustomButton from "components/CustomButton";
import { MultiSelect } from "components/FormMultiSelect";

import { useLoading, useSnackbar } from "contexts";
import { getRoleById, saveRole } from "services";
import { BasePermissions } from "config/BasePermissions";
import { getApplications } from "services/applications";

const useStyles = makeStyles({
    buttonContainer: {
        margin: "30px 10px"
    }
});

const PermissionContainer = ({
    entityIndex,
    entity,
    permissions,
    handleEntityOperationChange = () => { }
}) => {
    return (
        <Box
            key={entityIndex}
            border="1px solid grey"
            maxWidth={450}
            mb="1em"
            p="0.5em 1em"
        >
            <Typography variant="subtitle1" mt="0.5em">
                {entity}:
            </Typography>
            {
                Object.keys(permissions[entity]).map((operation, operationIndex) => (
                    <FormControlLabel
                        key={entityIndex + operationIndex}
                        control={<Checkbox
                            checked={permissions[entity][operation]}
                            onChange={e =>
                                handleEntityOperationChange(e, entity, operation)}
                        />}
                        label={<Typography variant="body2" >
                            {operation}
                        </Typography>}
                    />
                ))}
        </Box>
    );
}

export default function RoleEditor() {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { id } = useParams();
    const classes = useStyles();
    const loading = useLoading();
    const [name, setName] = React.useState("");
    const [newPermission, setNewPermission] = React.useState("");
    const [permissions, setPermissions] = React.useState({});
    const [applications, setApplications] = React.useState([]);
    const [applicationOptions, setApplicationOptions] = React.useState([]);

    useEffect(() => {
        if (applicationOptions.length > 0) {
            (async () => {
                try {
                    loading.show();
                    if (id && id !== 'create') {
                        const role = await getRoleById(id);
                        setPermissions({
                            ...BasePermissions, ...role.permissions
                        });
                        setName(role.name);
                        setApplications(role.applications);
                    }
                    if (id && id === "create")
                        setPermissions({ ...BasePermissions });
                } catch (error) {
                    snackbar.showSnackbar(error.message, 'error', false);
                } finally {
                    loading.hide();
                }
            })();
        }
    }, [id, applicationOptions]);

    useEffect(() => {
        (async () => {
            try {
                loading.show();

                const { applications } = await getApplications();

                setApplicationOptions(applications.map(({ name }) => name));
            } catch (error) {
                console.error(error);
            } finally {
                loading.hide();
            }
        })();
    }, [])

    function goBack() {
        navigate("/configs/roles");
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleEntityOperationChange = (e, entity, operation) => {
        setPermissions({
            ...permissions,
            [entity]: {
                ...permissions[entity],
                [operation]: e.target.checked
            }
        });
    }

    const handleSaveClick = async () => {
        try {
            if (id === "create")
                await saveRole(
                    null, name, permissions, applications
                );
            else
                await saveRole(
                    id, name, permissions, applications
                );

            snackbar.showSnackbar("Role saved successfully", "success", true);
            navigate("/configs/roles");
        } catch (error) {
            snackbar.showSnackbar(error.message, "error", true);
        }
    }

    const addNewPermission = () => {
        const _permissionsName = newPermission.trim();
        if (permissions[_permissionsName]) {
            snackbar.showSnackbar("This permissions already exists!", "error");
            return;
        }
        setPermissions(ps => ({
            ...ps,
            [_permissionsName]: {
                CREATE: false, UPDATE: false, DELETE: false, READ: false
            }
        }));
    };

    return (
        <>
            <ContentHeader title="Role Form" />
            <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={handleNameChange}
                size="small"
                sx={{ margin: "1em 0 2em 0" }}
                InputProps={{
                    readOnly: id !== "create",
                }}
            />

            <MultiSelect
                label="Applications"
                options={applicationOptions}
                selected={applications}
                setSelected={setApplications}
            />
            <details >
                <summary>
                    <Typography variant="body1" component="span">
                        Permissions
                    </Typography>
                </summary>
                {
                    Object.keys(permissions).map((entity, entityIndex) => (
                        <PermissionContainer
                            entity={entity}
                            entityIndex={entityIndex}
                            permissions={permissions}
                            handleEntityOperationChange={handleEntityOperationChange}
                        />
                    ))
                }
            </details>
            <br />
            <Typography>Add New Permission</Typography>
            <TextField
                value={newPermission}
                onChange={e => setNewPermission(e.target.value)}
                size="small"
            />
            <CustomButton
                startIcon={<AddIcon />} color="secondary" variant="outlined"
                disabled={newPermission.length < 3}
                onClick={addNewPermission}
            >
                Add
            </CustomButton>

            <Box className={classes.buttonContainer}>
                <CustomButton
                    type="submit"
                    variant="contained"
                    onClick={handleSaveClick}
                >
                    Save
                </CustomButton>
                <CustomButton
                    variant="outlined"
                    onClick={goBack}
                >
                    Cancel
                </CustomButton>
            </Box>
        </>
    );
}
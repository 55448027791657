import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { SimpleTextField } from 'components/FormTextField';
import { SimpleSelect } from 'components/FormSelect';
import { FieldLabelSmall } from './FieldLabel';
import { InterviewQuestionTypes } from "./constants";

function SmallField({
    index,
    type,
    fieldName,
    label,
    placeholder,
    readOnly,
    templateId,
    question = {},
    handleChange = () => { },
    setQuestionUpdated = () => { },
    formik,
    column
}) {
    return (
        <Box
            display="flex"
            sx={{
                [column ? "justifyContent" : "alignItems"]: "center",
                flexDirection: column ? "column" : "row",
            }}
        >
            <FieldLabelSmall width={50}>{label}</FieldLabelSmall>
            <SimpleTextField
                variant="outlined"
                name={`questions.${index}.${fieldName}`}
                placeholder={placeholder}
                inputProps={{
                    readOnly,
                    value: question[fieldName],
                    onChange: (e) => {
                        handleChange(e);
                        if (templateId && !question.hasOwnProperty('added'))
                            setQuestionUpdated(e, index, "question");
                    },
                }}
                error={Boolean(formik.errors?.questions?.[index]?.[fieldName])}
                helperText={formik.errors?.questions?.[index]?.[fieldName]}
                type={type || "text"}
            />
        </Box>
    );
}

function QuestionSection({
    index,
    readOnly,
    question = {},
    templateId,
    handleChange = () => { },
    setQuestionUpdated = () => { },
    handleDeleteQuestion = () => { },
    removeQuestion = () => { },
    formik
}) {
    return (
        <Box
            display="flex"
            alignItems="strech"
            p="1em"
            backgroundColor="#F4F5F5"
            my={1}
        >
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <SmallField
                    index={index}
                    fieldName="question"
                    label={`Q${index + 1}*`}
                    placeholder="Type your Question Here...e.g. tell me about yourself"
                    readOnly={readOnly}
                    templateId={templateId}
                    question={question}
                    handleChange={handleChange}
                    setQuestionUpdated={setQuestionUpdated}
                    formik={formik}
                />

                <SmallField
                    index={index}
                    fieldName="hint"
                    label="Hint:"
                    placeholder="Add a hint... (remember the day we met)"
                    readOnly={readOnly}
                    templateId={templateId}
                    question={question}
                    handleChange={handleChange}
                    setQuestionUpdated={setQuestionUpdated}
                    formik={formik}
                />

                <SmallField
                    index={index}
                    fieldName="resources"
                    label="Res:"
                    placeholder="Link to the resources... (Add resources, seperated by ',')"
                    readOnly={readOnly}
                    templateId={templateId}
                    question={question}
                    handleChange={handleChange}
                    setQuestionUpdated={setQuestionUpdated}
                    formik={formik}
                />

                <SmallField
                    index={index}
                    fieldName="expectedAnswer"
                    label="Ans*:"
                    placeholder="Write your ideal answer here..."
                    readOnly={readOnly}
                    templateId={templateId}
                    question={question}
                    handleChange={handleChange}
                    setQuestionUpdated={setQuestionUpdated}
                    formik={formik}
                    multiline
                    minRows={4}
                />



            </Box>
            <Box display="flex" flexDirection="column" ml={4} width={"35%"}>
                <FieldLabelSmall mt={1}>Type of question*</FieldLabelSmall>
                <SimpleSelect
                    id="third-create-interview-tip"
                    options={InterviewQuestionTypes}
                    name={`questions.${index}.type`}
                    value={question.type}
                    onChange={e => {
                        handleChange(e)
                        if (templateId && !question.hasOwnProperty('added'))
                            setQuestionUpdated(e, index, "type")
                    }
                    }
                    error={Boolean(formik.errors?.questions?.[index]?.type)}
                    helperText={formik.errors?.questions?.[index]?.type}
                />

                <br />

                <SmallField
                    id="fifth-create-interview-tip"
                    type="number"
                    index={index}
                    fieldName="allottedTime"
                    label="Time (mins)*"
                    placeholder="Add time required for answer..."
                    readOnly={readOnly}
                    templateId={templateId}
                    question={question}
                    handleChange={handleChange}
                    setQuestionUpdated={setQuestionUpdated}
                    formik={formik}
                    column
                />

                <IconButton
                    disabled={!!readOnly}
                    onClick={() => handleDeleteQuestion(index, removeQuestion)}
                    sx={{ height: "fit-content", alignSelf: "flex-end" }}
                >
                    <DeleteOutlinedIcon
                        className="secondary"
                        color="error"
                        sx={{ cursor: "pointer", fontSize: "2rem" }} />
                </IconButton>
            </Box>
        </Box>);
}

export default QuestionSection;
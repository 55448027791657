import React from 'react';

import Box from "@mui/material/Box";
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import SendEmailDialog from 'components/dialogs/SendEmailDialog';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import ListQuickSearch from 'components/ListQuickSearch';
import { useLoading, useSnackbar } from 'contexts';
import { useList } from 'hooks/useList';
import {
    deleteUserById, getClients, getUserById, updateProfileStatus
} from 'services';
import { checkCreatePermission, formatDate, getClientListActions } from "utils";
import FilterList from './FilterList';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: 562,
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    clientButton: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        borderRadius: '5px',
        marginBottom: '10px'
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    includeTotal: true, role: "MOCK_CLIENT", orderBy: "updatedAt", order: 'desc',
    deactivated: false,
};

const FieldsMap = {
    "usersCount": "metadata.usersCount",
    "userLimit": "metadata.userLimit",
    "name": "name",
    "email": 'email',
    "createdAt": "createdAt",
    "deactivated": "deactivated"
};

export default function MockClients() {
    const [activeItem, setActiveItem] = React.useState(null);
    const [activeAction, setActiveAction] = React.useState(null);

    const loadingOverlay = useLoading();
    const snackbar = useSnackbar();
    const classes = useStyles();

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
        editItem,
        refresh,
    } = useList({
        getItems: getClients,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "clients",
        deleteItemById: deleteUserById,
    });

    const handleConfirmationSuccess = async () => {
        loadingOverlay.show();
        try {
            const newStatus = (activeItem?.deactivated) ?
                'Active' :
                'Deactive';
            await updateProfileStatus(activeItem?.id, newStatus);
            refresh();

            snackbar.showSnackbar("Profile status updated successfully!!", "success");
        } catch (error) {
            console.error("DEBUG::Clients.updateProfileStatus", error);
            snackbar.showSnackbar(error.message, "error");
        } finally {
            loadingOverlay.hide();
        }
        setActiveItem(null)
    };

    const handleMailSuccess = () => {
        setActiveItem(null)
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: FieldsMap[field], order: sort });
        }
    };

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value !== undefined) {
            setFilters({ ...BaseFilters, ...filters, [FieldsMap[columnField]]: value });
        } else {
            const newFilters = { ...BaseFilters, ...filters };
            delete newFilters[[FieldsMap[columnField]]];
            setFilters(BaseFilters);
        }
    };

    const handleDeactivatedChange = (e, value) => handleFilterChange({
        items: [{ columnField: "deactivated", value }]
    });

    return (
        <>
            <ContentHeader
                title="Clients"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <FilterList>
                    <ListQuickSearch onFilterChange={handleFilterChange} value={filters["name"]} />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by email"
                        field="email"
                        value={filters["email"]}
                    />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by No. of User"
                        field="usersCount"
                        value={filters["usersCount"]}
                    />
                    <FormControlLabel
                        sx={{ marginInline: '10px' }}
                        componentsProps={{ typography: { fontSize: 14 } }}
                        control={(<Switch
                            size='small'
                            checked={filters.deactivated}
                            onChange={handleDeactivatedChange}
                        />)}
                        label="Show Deactivated"
                    />
                </FilterList>
                &nbsp;
                {checkCreatePermission("Client") && <CustomButton
                    variant='contained'
                    className={classes.clientButton}
                    onClick={editItem}
                >
                    Create Client
                </CustomButton>}
            </ContentHeader>
            <Box className={classes.tableContainer}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Name',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                        },
                        {
                            field: 'email',
                            headerName: 'Email',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                        },
                        {
                            field: 'usersCount',
                            headerName: 'No. of Users',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                            valueGetter: (params) => {
                                const usersCount = params?.row?.metadata?.usersCount;
                                if (usersCount) return usersCount;
                                return "0";
                            }
                        },
                        {
                            field: 'createdAt',
                            headerName: 'Registration date',
                            flex: 1,
                            headerClassName: classes.tableHeader,
                            valueGetter: (params) => {
                                const date = params?.row?.createdAt;
                                return formatDate(date);
                            }
                        },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            type: "actions",
                            width: 160,
                            headerClassName: classes.tableHeader,
                            getActions: (params) =>
                                getClientListActions(params, editItem, setActiveItem, setActiveAction)
                        },
                    ]}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
                <ConfirmationDialog
                    message={
                        `Do you want to ${activeItem?.deactivated ?
                            "activate" : "deactivate"} this user ?`
                    }
                    open={!!activeItem?.id && activeAction === 'UPDATE_STATUS'}
                    title="Update User Status"
                    primaryActionVariant="error"
                    primaryAction="Yes"
                    secondaryAction="Cancel"
                    onClose={() => setActiveItem(null)}
                    onSuccess={handleConfirmationSuccess}
                />
                <SendEmailDialog
                    open={!!activeItem?.id && activeAction === 'SEND'}
                    client={activeItem}
                    onClose={() => setActiveItem(null)}
                    onSuccess={handleMailSuccess}
                />
                <EntityViewDialog
                    open={!!activeItem?.id && activeAction === "VIEW"}
                    getEntity={getUserById}
                    title="Client"
                    entityId={activeItem?.id}
                    onClose={() => setActiveItem(null)}
                />
            </Box>
        </>
    );
};
import * as React from 'react';
import {
    DataGrid,
    getGridStringOperators,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import ContentHeader from 'components/ContentHeader';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import { useList } from "hooks/useList";
import { getFeedbacks, getFeedbacksUsingAggregation } from "services/feedback";
import LinearProgress from '@mui/material/LinearProgress';
import { formatDate } from 'utils/date';
import { ApplicationOptions, RatingOptions } from './constants';
import AllClientSelect from 'components/AllClientSelect';
import ShowCommentDialog from 'components/dialogs/ShowCommentDialog';
import ExportAnalyticsDialog from 'components/dialogs/ExportAnalyticsDialog';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    include: ["user"],
    includeTotal: true
};


export default function Feedbacks() {
    const classes = useStyles();

    const [client, setClient] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openExportDialog, setOpenExportDialog] = React.useState(false);
    const [comment, setComment] = React.useState('');

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: (client) ? getFeedbacksUsingAggregation : getFeedbacks,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "feedbacks",
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value)
            (client) ?
                setFilters({ ...BaseFilters, [columnField]: value, client }) :
                setFilters({ ...BaseFilters, [columnField]: value })
        else
            (client) ? setFilters({ ...BaseFilters, client }) : setFilters(BaseFilters)
    };

    const handleSortModelChange = (newModel) => {
        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleClientSelect = (client) => {
        setClient(client?._id);
        (client) ?
            setFilters({ ...BaseFilters, client: client._id }) :
            setFilters({ ...BaseFilters });
    }

    const handleCellClick = (girdParam) => {
        if (girdParam.field === 'comment') {
            setComment(girdParam.formattedValue);
            setOpenDialog(true);
        }
    }

    const columns = React.useMemo(() => {
        return [
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const { email } = params?.row?.user || 'Not Available'
                    return email;
                },
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'rating',
                headerName: 'Rating',
                flex: 1,
                type: "singleSelect",
                align: "center",
                filterable: true,
                valueOptions: RatingOptions,
                headerClassName: classes.tableHeader,
                filterOperators: getGridSingleSelectOperators().filter(
                    (operator) => operator.value === 'is',
                ),
            },
            {
                field: 'comment',
                headerName: 'Comment',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'application',
                headerName: 'Application',
                flex: 1,
                type: "singleSelect",
                align: "center",
                filterable: true,
                valueOptions: ApplicationOptions,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const application = params?.row?.application
                    return application ? application : "extension";
                },
                filterOperators: getGridSingleSelectOperators().filter(
                    (operator) => operator.value === 'is',
                ),
            },
            {
                field: 'feature',
                headerName: 'Feature',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    return params?.row?.feature || "-";
                },
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'createdAt',
                headerName: 'Date',
                flex: 1,
                type: 'dateTime',
                headerClassName: classes.tableHeader,
                filterable: false,
                valueGetter: (params) => {
                    const date = params?.row?.createdAt
                    return formatDate(date);
                },
            }
        ];
    }, []);

    return (
        <Box>
            <ContentHeader
                title={"Feedbacks"}
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
            >
                <Button variant='contained'
                    style={{ marginRight: '20px' }}
                    onClick={() => setOpenExportDialog(true)}
                >
                    Export
                </Button>
                <AllClientSelect onChange={handleClientSelect} />
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onCellClick={handleCellClick}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ShowCommentDialog
                open={openDialog} comment={comment}
                onClose={() => setOpenDialog(false)}
            />
            <ExportAnalyticsDialog type='feedback'
                onClose={() => setOpenExportDialog(false)} open={openExportDialog}
            />
        </Box>
    );
}
import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import ClientSelect from 'components/ClientSelect';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import { SimpleTextField } from 'components/FormTextField';
import { SimpleRadio } from 'components/FormRadio';
import { SEPTemplateSelect } from 'components/TemplateSelect';

import { useSnackbar } from "contexts";
import { generateInprepMagicLink, generateMagicLink } from 'services';
import { copyToClipboard } from "utils";
import { useEffect } from 'react';

function MagicLinks(props) {
  const snackbar = useSnackbar();
  const [client, setClient] = React.useState();
  const [batches, setBatches] = React.useState([]);
  const [error, setError] = React.useState("");
  const [inputs, setInputs] = React.useState({});
  const [link, setLink] = React.useState("");
  const [localLink, setLocalLink] = React.useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  useEffect(() => {
    const queryUrl = link?.split('//')[1]?.split('/')[1];
    setLocalLink(`http://localhost:3000/${queryUrl}`);
  }, [link]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setError("")
    if (!client) {
      setError("Select client")
      return false;
    }
    localStorage.setItem("ml-generation-data", JSON.stringify({
      client,
      inputs
    }));

    if (inputs.for !== 'sep') {

      return generateInprepMagicLink(client._id, inputs.email, client.email)
        .then((data) => {
          setLink(data.link);
        })
        .catch((err) => snackbar.showSnackbar(err.message, "error"));
    }
    generateMagicLink({
      id: client._id,
      email: client.email,
      userName: inputs.name,
      userEmail: inputs.email,
      sep: inputs.for === "sep",
      templateId: inputs.templateId,
      batches
    })
      .then((data) => {
        setLink(data.link);
      })
      .catch((err) => snackbar.showSnackbar(err.message, "error"));
  };

  const handleTemplateChange = React.useCallback(({ _id }) => handleChange({
    target: { name: "templateId", value: _id }
  }), []);

  React.useEffect(() => {
    const cached = JSON.parse(localStorage.getItem("ml-generation-data"));
    if (cached) {
      setInputs(cached.inputs);
      setClient(cached.client);
    }
  }, []);


  return (
    <>
      <ContentHeader title="Generate Magic Links" />
      <br />
      <br />
      <Box width="50%" m="auto">
        <form onSubmit={handleSubmit}>
          <Typography variant="subtitle1">
            Select Application
          </Typography>
          <FormControl required sx={{ mx: 1 }}>
            <RadioGroup
              row
              required
              name={"for"}
              onChange={handleChange}
              value={inputs.for}
            >
              <FormControlLabel
                required
                name="for"
                value="interview"
                control={<SimpleRadio required />}
                label="Interview"
                checked={inputs.for === "interview"}
              />
              :&nbsp;:&nbsp;
              <FormControlLabel
                name="for"
                value="sep"
                control={<SimpleRadio required />}
                label="SEP"
                checked={inputs.for === "sep"}
              />
            </RadioGroup>
          </FormControl >
          <br />
          <br />
          <SimpleTextField
            placeholder="john@gmail.com"
            name="email"
            label="Student Email"
            type="email"
            value={inputs.email || ""}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          {(inputs.for === 'sep') && <><SimpleTextField
            placeholder="John wick"
            name="name"
            label="Name"
            type="text"
            value={inputs.name || ""}
            onChange={handleChange}
            required
          />
            <br />
            <br />
          </>}
          {inputs.for && <ClientSelect
            error={error === "Select client" ? error : ""}
            defaultValue={client?._id}
            onChange={(client) => setClient(client)}
            sepClients={inputs?.for === "sep"}
          />}
          <br />
          {(client && inputs.for === "sep") && (
            <SEPTemplateSelect
              onChange={handleTemplateChange}
              filters={{ user: client?._id }}
            />)}
          <br />
          <br />
          <CustomButton variant="contained" type="submit" name="sep">
            Generate Link
          </CustomButton>
        </form>
        <br />

      </Box>
      {link && (
        <>
          <Box display="flex" alignItems="center" >
            <SimpleTextField
              readOnly
              size='small'
              variant="outlined"
              fullWidth
              value={link}
              spellCheck="false"
              disabled
            >
            </SimpleTextField>
            &nbsp;&nbsp;
            <ContentCopyOutlinedIcon
              fontSize="medium"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                copyToClipboard(link);
                snackbar.showSnackbar('copied!', 'info', true);
              }}
            />
            <Link to={`/integrations/iframe?url=${link}`} style={{ textDecoration: "none", }} >
              <Button
                style={{ width: 160 }}
                variant="contained"
              >
                View in IFrame
              </Button>
            </Link>
          </Box>

          <Box display="flex" alignItems="center" >
            <SimpleTextField
              readOnly
              size='small'
              variant="outlined"
              fullWidth
              value={localLink}
              spellCheck="false"
              disabled
            >
            </SimpleTextField>
            &nbsp;&nbsp;
            <ContentCopyOutlinedIcon
              fontSize="medium"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                copyToClipboard(localLink);
                snackbar.showSnackbar('copied!', 'info', true);
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default MagicLinks;
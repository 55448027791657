import Session from "../utils/Session";
import Client from "./_client";

export const getFieldOptionById = async (fieldOptionId) => {
    const result = await new Client({
        path: `/fieldOption/${fieldOptionId}`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get field option. Please try again."
        );
    }

    return result.data;
};

export const saveFieldOption = async (
    fieldOptionId,
    fieldOptionData
) => {
    let result;

    if (fieldOptionId) {
        // Invoke API to update template
        result = await new Client({
            path: `/fieldOption/${fieldOptionId}`,
            payload: {
                field: fieldOptionData.field,
                option: fieldOptionData.option,
                user: fieldOptionData.user || Session.userId,
            },
        }).put();
    } else {
        // Invoke API to create template
        result = await new Client({
            path: `/fieldOption`,
            payload: {
                field: fieldOptionData.field,
                option: fieldOptionData.option,
                user: fieldOptionData.user || Session.userId,
            },
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save field option. Please try again."
        );
    }

    return result.data;
};

export const getFieldOptions = async (fieldOptionsFilters = {}) => {
    let url = "/fieldOption?";

    if (!fieldOptionsFilters.user) fieldOptionsFilters.user = Session.userId;

    Object.keys(fieldOptionsFilters).forEach((key) => {
        if (fieldOptionsFilters[key]) url += `${key}=${fieldOptionsFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get field options. Please try again."
        );
    }

    return result.data;
};

export const deleteFieldOptionById = async (fieldOptionId) => {
    const result = await new Client({
        path: `/fieldOption/${fieldOptionId}`,
    }).delete();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to delete field option. Please try again."
        );
    }

    return result?.data?.isDeleted;
};

export const getDistinctFields = async () => {

    let url = `/fieldOption/distinct`;

    let result = await new Client({
        path: url
    }).get();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete template. Please try again."
        );

    return result.data;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getFieldOptionById,
    saveFieldOption,
    getFieldOptions,
    deleteFieldOptionById,
    getDistinctFields,
};

export const RatingOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

export const ApplicationOptions = [
  { "value": "interview", "label": "INTERVIEW" },
  { "value": "extension", "label": "EXTENSION" },
  { "value": "inprep", "label": "INPREP" }
];
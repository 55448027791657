import Client from "./_client";

export const getWebhookEvents = async (filters = {}) => {
    let url = "/webhookEvent?";

    if (filters.client) {
        filters = { ...filters, "data.client": filters.client };
    }

    Object.keys(filters).forEach((key) => {
        if (filters[key]) url += `${key}=${filters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get webhookevents. Please try again.');
    }

    result.data.webHookEvents.forEach((webhookEvent) => {
        webhookEvent.response = webhookEvent?.response?.pop()
    });
    return result.data;
}

import { Link, useLocation, useResolvedPath } from 'react-router-dom';

export default function CustomLink({
    children,
    to,
    activeClassName,
    ...props
}) {
    const location = useLocation();
    const resolved = useResolvedPath(location.pathname);
    const match = resolved.pathname.indexOf(to);

    return (
        <Link
            style={{ color: '#2DABFF' }}
            to={to}
            {...props}
            className={match >= 0 ? activeClassName : props.className}
        >
            {children}
        </Link>
    );
}
export const SubscriptionModel = [
    {
        _id: "pay_per_user",
        name: "Pay per user"
    },
    {
        _id: "pay_per_attempt",
        name: "Pay Per Attempt"
    },
]

export const CreditType = {
  USER_BASED : "user_based",
  ATTEMPT_BASED : "attempt_based",
}

export const OrganisationType = [
    {
        _id: "edtech",
        name: "Edtech",
    },
    {
        _id: "bootcamp",
        name: "Bootcamp",
    },
    {
        _id: "university",
        name: "University"
    },
    {
        _id: "enterprise",
        name: "Enterprise"
    },
    {
        _id: "offline_coaching",
        name: "Offline Coaching",
    }
];


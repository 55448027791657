import React from 'react';
import Papa from "papaparse";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useSnackbar } from 'contexts';
import CustomButton from 'components/CustomButton';

function CSVReaderInput({ onData, inputProps, label = "Import CSV" }) {
  const snackbar = useSnackbar();

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        // Parsed Data Response in array format
        onData(results.data);
        snackbar.showSnackbar("Successfully imported the file!!")
      },
      error: (error) => snackbar.showSnackbar("Uh Oh! Unable to import CSV", "error"),
    });
  };

  return (
    <CustomButton
      color="primary"
      aria-label="upload csv file"
      component="label"
      variant="outlined"
      startIcon={<FileUploadIcon color="primary" />}
    >
      <input
        hidden
        type="file"
        name="csvFile"
        {...inputProps}
        accept=".csv"
        onChange={changeHandler}
      />
      {label}
    </CustomButton>
  );
}

export default CSVReaderInput;
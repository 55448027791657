import React from 'react';

import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

import ContentHeader from 'components/ContentHeader';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import { useList } from 'hooks/useList';
import { 
  deletePathway, 
  getPathwayRequestById, 
  getPathwayRequests, 
  updateResourceRequest 
} from 'services/pathwayService';
import { useSnackbar } from 'contexts';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import FilterList from './FilterList';
import ListQuickSearch from 'components/ListQuickSearch';
import { SimpleSelect } from 'components/FormSelect';
import { getPathwayRequestActions } from 'utils';
import EntityViewDialog from 'components/dialogs/EntityViewDialog';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: 562,
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    pathwayButton: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        borderRadius: '5px',
        marginBottom: '10px'
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    includeTotal: true, orderBy: "updatedAt", order: 'desc', include: ["user", "batch"],
    type: 'pathway'
};

const RequestStatus = [
  { _id: 'pending', name: 'Pending' },
  { _id: 'approved', name: 'approved' },
  { _id: 'rejected', name: 'rejected' },
]

export default function PathwayRequest() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [activeItem, setActiveItem] = React.useState(null);
    const [activeAction, setActiveAction] = React.useState(null);
    const [changeStatus, setChangeStatus] = React.useState(null);

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
        editItem,
        refresh,
    } = useList({
        getItems: getPathwayRequests,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "resourceRequests",
        deleteItemById: null,
    });

    const handleDelete = async () => {
      try {
        let result= await deletePathway(activeItem.id);
        if(result){
          setActiveItem(null);
          refresh();
          snackbar.success('Pathway deleted successfully!!!');
        }
      } catch (error) {
        snackbar.showSnackbar(error.message, "error", true);
      }
      finally {
        loading.hide();
      }
    };

    const updateStatus = async () => {
      if(!changeStatus) return
      try {
        let result= await updateResourceRequest(changeStatus?.id, {status: changeStatus?.status});
        if(result){
          setChangeStatus(null);
          refresh();
          snackbar.success('Pathway Request status updated successfully!!!');
        }
      } catch (error) {
        snackbar.showSnackbar(error.message, "error", true);
      }
      finally {
        loading.hide();
      }
    };

    const handleSortModelChange = (newModel) => {
        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value !== undefined) {
            setFilters({ ...BaseFilters, ...filters, [columnField]: value });
        } else {
            const newFilters = { ...BaseFilters, ...filters };
            delete newFilters[[columnField]];
            setFilters(BaseFilters);
        }
    };

    const handleStatusChange = (e, item) => {
      setChangeStatus({
        id: item._id, status: e.target.value, user: item?.user
      });
    };

    return (
        <>
          <ContentHeader
              title="Pathway Requests"
              BaseFilters={BaseFilters}
              filters={filters}
              setFilters={setFilters}
              clearFilter={true}
          >
            <FilterList>
              <ListQuickSearch 
                onFilterChange={handleFilterChange} 
                value={filters["email"]} 
                field = "email"
                label= "Email"
              />
              <SimpleSelect 
                label="status"
                id={`status`}
                options={RequestStatus}
                value={filters["status"]}
                name={`status`}
                onChange={(e)=>
                  handleFilterChange({items: [{columnField: e.target.name, value: e.target.value}]})
                }
              />
            </FilterList>
          </ContentHeader>

          <Box className={classes.tableContainer}>
              <DataGrid
                  pagination
                  autoPageSize
                  paginationMode='server'
                  filterMode='server'
                  density='compact'
                  loading={loading}
                  rowCount={total}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  onPageChange={setPageNo}
                  onPageSizeChange={setPageSize}
                  rows={items}
                  getRowId={(row) => row._id}
                  columns={[
                      {
                          field: 'name',
                          headerName: 'Name',
                          flex: 1,
                          headerClassName: classes.tableHeader,
                          valueGetter: (params) => ( params?.row?.user?.name )
                      },
                      {
                          field: 'email',
                          headerName: 'Email',
                          flex: 1,
                          headerClassName: classes.tableHeader,
                          valueGetter: (params) => ( params?.row?.user?.email )
                      },
                      {
                          field: 'status',
                          headerName: 'Status',
                          flex: 1,
                          headerClassName: classes.tableHeader,
                          renderCell: (params) => {
                            return (
                              <SimpleSelect 
                                label="status"
                                id={`status`}
                                options={RequestStatus || []}
                                value={params?.row?.status || null}
                                name={`status`}
                                onChange={(e)=>handleStatusChange(e, params?.row)}
                              />
                            )
                          }
                      },
                      {
                          field: `company`,
                          headerName: 'Company',
                          flex: 1,
                          headerClassName: classes.tableHeader,
                          valueGetter: (params) => ( params?.row?.metadata?.company ),
                      },
                      {
                          field: `jobProfile`,
                          headerName: 'Job Profile',
                          flex: 1,
                          headerClassName: classes.tableHeader,
                          valueGetter: (params) => ( params?.row?.metadata?.jobProfile ),
                      },
                      {
                          field: `description`,
                          headerName: 'Description',
                          flex: 1,
                          headerClassName: classes.tableHeader,
                          valueGetter: (params) => ( params?.row?.metadata?.description ),
                      },
                      {
                          field: 'actions',
                          headerName: 'Actions',
                          type: "actions",
                          width: 220,
                          headerClassName: classes.tableHeader,
                          getActions: (params) =>
                            getPathwayRequestActions(params, setActiveItem, setActiveAction, "ResourceRequest")
                      },
                  ]}
                  onFilterModelChange={handleFilterChange}
                  components={{
                      Toolbar: GridToolbar,
                      LoadingOverlay: LinearProgress,
                      Pagination: ListPagination,
                  }}
                  componentsProps={{
                      pagination: {
                          rowCount: total
                      }
                  }}
              />
          </Box>
          <ConfirmationDialog
              message={
                  `This Pathway Request will permanantly get deleted, Are you sure?`
              }
              open={!!activeItem && activeAction==='DELETE'}
              title="Delete Pathway Request"
              primaryActionVariant="error"
              primaryAction="Yes"
              secondaryAction="Cancel"
              onClose={() => setActiveItem(null)}
              onSuccess={handleDelete}
          />
          <ConfirmationDialog
              message={
                `Are you sure, you want to send Email Confirmation to ${activeItem?.row?.user?.name}?`
              }
              open={!!activeItem && activeAction==='EMAIL'}
              title="Send Email Confirmation"
              primaryActionVariant="error"
              primaryAction="Yes"
              secondaryAction="Cancel"
              onClose={() => setActiveItem(null)}
              // onSuccess={}
          />
          <EntityViewDialog
            open={!!activeItem && activeAction==='VIEW'}
            getEntity={getPathwayRequestById}
            title="Pathway Request Details"
            entityId={activeItem?.id}
            onClose={() => setActiveItem(null)}
          />
          <ConfirmationDialog
              message={
                `Do you want to change the status to ${changeStatus?.status} 
                  for ${changeStatus?.user?.name}?`
              }
              open={!!changeStatus}
              title="Update Request Status"
              primaryActionVariant="error"
              primaryAction="Yes"
              secondaryAction="Cancel"
              onClose={() => setChangeStatus(null)}
              onSuccess={updateStatus}
          />
        </>
    );
};
import Client from "./_client";

export const getReferrals = async (referralFilters = {}) => {
    let url = "/referral?";

    Object.keys(referralFilters).forEach((key) => {
        if (referralFilters[key]) url += `${key}=${referralFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get referrals. Please try again.');
    }

    return result.data;
}

export const getReferralById = async (referralId) => {
    const result = await new Client({
        path: `/referral/${referralId}`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get referral. Please try again.');
    }

    return result.data;
}

export const saveReferral = async (referralId, name, permissions) => {
    let result;

    if (referralId) {
        result = await new Client({
            path: `/referral/${referralId}`,
            payload: {
                name,
                permissions
            },
        }).put();
    } else {
        result = await new Client({
            path: `/referral`,
            payload: {
                name,
                permissions
            },
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save referral. Please try again."
        );
    } else {
        return result.data;
    }
}

export const deleteReferralById = async (referralId) => {

    let url = `/referral/${referralId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete referral. Please try again."
        );

    return result.data;
}
import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomButton from 'components/CustomButton';

export default function AddButton({ children, menuItems, onClick, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
    if (id) onClick(id);
  };

  return (
    <div>
      <CustomButton
        size="small"
        variant="outlined"
        color="success"
        startIcon={<AddIcon />}
        onClick={menuItems ? handleClick : onClick}
        {...props}
      >
        {children}
      </CustomButton>
      {menuItems && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {menuItems.map((mi, i) => (
            <MenuItem key={i} onClick={() => handleClose(mi.id)}>
              {mi.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';


import { signIn } from "services";
import { useSnackbar, useLoading } from 'contexts';

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string()
        .min(8, 'Password must be 8 characters or more')
        .required('Required')
});

export default function SignInForm() {

    const [showPassword, setShowPassword] = useState(false);
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const loading = useLoading();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                loading.show();
                await signIn({ email: values.email, password: values.password });
                navigate('/clients/inprep');
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error', false);
            } finally {
                loading.hide();
            }
        }
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <form onSubmit={formik.handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <Typography variant='h4' sx={{ margin: 4 }}>
                Admin SignIn
            </Typography>
            <TextField
                error={formik.touched.email && formik.errors.email}
                id="email"
                label="Email"
                variant="outlined"
                sx={{ width: '60%', margin: 2, }}
                value={formik.values.email}
                spellCheck="false"
                helperText={(formik.touched.email && formik.errors.email) ?
                    formik.errors.email :
                    ''}
                onChange={formik.handleChange} >
            </TextField>
            <FormControl
                sx={{ m: 1, width: '60%', }}
                variant="outlined">
                <InputLabel
                    htmlFor="password"
                    error={formik.touched.password && formik.errors.password}
                >Password</InputLabel>
                <OutlinedInput
                    error={formik.touched.password && formik.errors.password}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    spellCheck="false"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ?
                                    <VisibilityOff /> :
                                    <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
                {(formik.touched.password && formik.errors.password) ?
                    <FormHelperText error>
                        {formik.errors.password}
                    </FormHelperText> :
                    ''}
                <Button
                    sx={{ my: 3, width: '60%' }}
                    disabled={loading.state}
                    variant="contained"
                    type='submit'
                >
                    Submit
                </Button>
            </FormControl>
        </form>
    );
}
import React, { useEffect, useMemo } from 'react'
import { Box, Typography, Button } from '@mui/material';
import { getCreditByClientId } from 'services';
import { formatDate, getCreditListActions } from 'utils';
import { CreditFormDialog } from './CreditForm';

import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { CreditType, SubscriptionModel } from './constants';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: 562,
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    clientButton: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        borderRadius: '5px',
        marginBottom: '10px'
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    creditRow: {
        color: theme.palette.success.main,

    },
    debitRow: {
        color: theme.palette.error.main,
    },
    expiresCredit: {
        color: theme.palette.error.main,
    }
}));

function CreditDataGrid({ tableData = [], setEditCredit }) {
    const classes = useStyles();

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: "Transaction Id",
                flex: 1,
                headerClassName: classes.tableHeader
            },
            {
                field: 'expiresOn',
                headerName: "Expires",
                flex: 1,
                headerClassName: classes.tableHeader,
                valueFormatter: (value) => {
                    const expireDate = new Date(value.value);
                    const currentDate = new Date();
                    if (expireDate > currentDate) {
                        return formatDate(value.value);
                    } else {
                        return (formatDate(value.value) + " (Expired)");
                    }

                },
                cellClassName: (params) => {
                    const expireDate = new Date(params?.row?.expiresOn);
                    const currentDate = new Date();
                    if (expireDate < currentDate) {
                        return classes.expiresCredit;
                    }
                },
            },
            {
                field: 'initialAmount',
                headerName: "Initial Amount",
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const value = params?.row?.initialAmount;
                    if (value === -99) return "Unlimited";
                    return value;
                }
            },
            {
                field: 'remainingAmount',
                headerName: "Remaining Amount",
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const value = params?.row?.remainingAmount;
                    if (value === -99) return "Unlimited";
                    return value;
                },
                cellClassName: (params) => {
                    const expireDate = new Date(params?.row?.expiresOn);
                    const currentDate = new Date();
                    if (expireDate < currentDate) {
                        return classes.expiresCredit;
                    }
                },
            },
            {
                field: 'createdAt',
                headerName: "Created At",
                flex: 1,
                headerClassName: classes.tableHeader,
                valueFormatter: (value) => {
                    return formatDate(value.value);
                }
            },
            {
              field: 'actions',
              headerName: 'Actions',
              type: "actions",
              width: 80,
              headerClassName: classes.tableHeader,
              getActions: (params) =>
                getCreditListActions(params, setEditCredit, "Credit")
          },
        ]
    }, []);

    return (<DataGrid columns={columns}
        rows={tableData}
        sx={{
            width: "100%",
            minHeight: "450px"
        }} />);

}



function CreditTable({ client, clientId, onTransactionClick }) {

    const [tableData, setTableData] = React.useState([]);
    const [totalAmountRemaining, setTotalAmountRemaining] = React.useState(0);
    const [totalAmountUsed, setTotalAmountUsed] = React.useState(0);
    const [activeCredit, setActiveCredit] = React.useState(0);
    const [showAddCredit, setShowAddCredit] = React.useState(false);
    const [addedCount, setAddedCount] = React.useState(0);
    const [editCredit, setEditCredit] = React.useState(null); 
    const creditType = client.subscriptionModel !== SubscriptionModel[0]._id
        ? CreditType.ATTEMPT_BASED : CreditType.USER_BASED

    const handleCreditAddition = () => {
        setAddedCount(prev => prev + 1);
    }

    const handleAddCredit = (event) => {
        event.preventDefault();
        setShowAddCredit(true);
    }

    const handleAddCreditClose = (event) => {
        setShowAddCredit(false);
        setEditCredit(null);
    }
    React.useEffect(() => {
        (async () => {
            const data = await getCreditByClientId(clientId);
            const credits = data?.credits.map((el) => {
                return { ...el, id: el?._id };
            })
            setTableData(credits ?? []);
        })()
    }, [clientId, addedCount])

    useEffect(() => {

        const tmr = tableData.reduce((prev, el) => {
            const expireDate = new Date(el?.expiresOn);
            const currentDate = new Date();
            if (expireDate > currentDate) {
                return prev + el.remainingAmount;
            } else {
                return prev;
            }
        }, 0)
        const tau = tableData.reduce((prev, el) => {
            return prev + (el.initialAmount - el.remainingAmount);
        }, 0)
        const ar = tableData.reduce((prev, el) => {
            return prev + (el.isActive ? 1 : 0);
        }, 0);
        setTotalAmountRemaining(tmr);
        setTotalAmountUsed(tau);
        setActiveCredit(ar);
    }, [tableData])


    return (
        <>
            <Box width={"100%"} paddingTop={'.5rem'}>
                <Box display={'flex'} justifyContent={'space-between'} marginY={".75rem"}>
                    <Typography variant="h6" color="initial">
                        Credits:
                    </Typography>
                    <Box display={'flex'} columnGap={'0.5rem'}>
                        <Button variant="outlined" color="primary" onClick={handleAddCredit}>
                            Add Credit
                        </Button>
                        <Button variant="outlined" color="primary" onClick={onTransactionClick}>
                            Transactions
                        </Button>
                    </Box>
                </Box>
                <Box display={'flex'} columnGap={'1.25rem'}>
                    <Typography variant="body1" color="initial">
                        Summary:
                    </Typography>
                    <Typography variant="body1" color="initial">
                        Total Amount Remaining: <br />
                        <u>{totalAmountRemaining} license</u>
                    </Typography>
                    <Typography variant="body1" color="initial">
                        Total Amount Used: <br />
                        <u>{totalAmountUsed} license</u>
                    </Typography>
                    <Typography variant="body1" color="initial">
                        Active Credits <br />
                        <u>{activeCredit} credits</u>
                    </Typography>
                </Box>

                <CreditDataGrid tableData={tableData ?? []} setEditCredit={setEditCredit}/>
            </Box>
            <CreditFormDialog 
              creditType={creditType} clientId={clientId} 
              open={showAddCredit} onClose={handleAddCreditClose}
              onCreditAddition={handleCreditAddition} 
            />
            <CreditFormDialog 
              creditType={creditType} clientId={clientId} 
              open={editCredit ? true : false} onClose={handleAddCreditClose}
              onCreditAddition={handleCreditAddition} 
              updateCredit={true} editData={editCredit?.row}
            />
        </>
    )
}

export default CreditTable
import React from 'react'
import Box from '@mui/material/Box'
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button";
import ContentHeader from 'components/ContentHeader'
import ReactJson from 'react-json-view';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import { SimpleTextField } from 'components/FormTextField';
import { getInterviewPerformance } from 'services';
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
    flexRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    },
    flexWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "4rem",
        height: "75vh",
        width: '100%',
    },
    container: {
        height: "100%",
        position: "relative"
    }
}))

function PerformanceApi() {
    const [entity, setEntity] = React.useState({});
    const [userId, setUserId] = React.useState("");
    const [showDetails, setShowDetails] = React.useState(true);
    const [showSummary, setShowSummary] = React.useState(true);
    const { container } = useStyles();

    const handleDataFetch = async () => {
        if (!userId) return;
        const data = await getInterviewPerformance(userId, showSummary, showDetails);
        setEntity(data);
    }

    return (
        <Box mt={2} className={container}>
            <ContentHeader title="Performance" />
            <Box width="50%" m="auto" marginTop={"2rem"}>
                <Typography>Fill details to check performance: -</Typography>
                <Box
                    sx={
                        {
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            gap: "0.75rem",
                            marginTop: ".25rem"
                        }
                    }
                >
                    <Box>
                        <SimpleTextField
                            required
                            sx={{ width: "14rem" }}
                            label={"Attempt Id of performance"}
                            onInput={(event) => {
                                setUserId(event.target.value);
                            }}
                        />
                    </Box>
                    <FormControlLabel
                        control={<Switch defaultChecked />}
                        label="Summary" onInput={(event) => setShowSummary(event.target.checked)}
                    />
                    <FormControlLabel
                        control={<Switch defaultChecked />}
                        label="Detailed Analysis" onInput={(event) => setShowDetails(event.target.checked)}
                    />
                    <Button variant='contained' onClick={handleDataFetch}>Get Data</Button>
                </Box>
                <Paper sx={{
                    width: "480px",
                    marginInline: "auto",
                    marginBlock: "3rem",
                    minWidth: "240px"
                }}>
                    <Typography variant="subtitle1" sx={{ padding: '.5rem' }}>Response</Typography>
                    <ReactJson src={JSON.parse(JSON.stringify(entity))} />
                </Paper>
            </Box>
        </Box>
    )
}

export default PerformanceApi

import Session from 'utils/Session';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from "@mui/icons-material/EditOutlined";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EmailIcon from '@mui/icons-material/Email';

export const getListActions = (params, editItem, setActiveItemId, entity) => {
    let tempActions = [];
    if (editItem && Session.permissions?.[entity]?.UPDATE)
        tempActions.push(<GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={(e) => editItem(e, params)}
        />);
    if (setActiveItemId && Session.permissions?.[entity]?.DELETE)
        tempActions.push(<GridActionsCellItem
            icon={<DeleteIcon color='error' />}
            label="Delete"
            onClick={() => setActiveItemId(params)}
        />);

    return tempActions;
}

export const getpathwayListActions = (params, editItem, setActiveItem, setActiveAction, entity) => {
    let tempActions = [];
    if (editItem && Session.permissions?.[entity]?.UPDATE)
        tempActions.push(<GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={(e) => editItem(e, params.id)}
        />);
    if (setActiveItem && Session.permissions?.[entity]?.UPDATE)
    tempActions.push(<GridActionsCellItem
        icon={<ContentCopyIcon/>}
        label="Duplicate"
        onClick={() =>{
          setActiveItem(params);
          setActiveAction('DUPLICATE');
        } }
    />);
    if (setActiveItem && Session.permissions?.[entity]?.READ)
    tempActions.push(<GridActionsCellItem
        icon={<FileDownloadIcon/>}
        label="Export"
        onClick={() =>{
          setActiveItem(params);
          setActiveAction('EXPORT');
        } }
    />);
    if (setActiveItem && Session.permissions?.[entity]?.DELETE)
        tempActions.push(<GridActionsCellItem
            icon={<DeleteIcon color='error' />}
            label="Delete"
            onClick={() =>{
              setActiveItem(params);
              setActiveAction('DELETE');
            }
            }
        />);
    return tempActions;
}

export const getPathwayRequestActions = (params, setActiveItem, setActiveAction, entity) => {
  let tempActions = [];
  if (Session.permissions?.[entity]?.UPDATE)
      tempActions.push(<GridActionsCellItem
          icon={<EmailIcon/>}
          label="Email"
          onClick={() => {
            setActiveItem(params);
            setActiveAction('EMAIL');
          }
          }
      />);

    
  if (Session.permissions?.[entity]?.READ)
      tempActions.push(<GridActionsCellItem
          icon={<VisibilityIcon/>}
          label="VIEW"
          onClick={() => {
            setActiveItem(params);
            setActiveAction('VIEW');
          }
          }
      />);

    
  // if (setActiveItem && Session.permissions?.[entity]?.DELETE)
  // tempActions.push(<GridActionsCellItem
  //     icon={<DeleteIcon color='error' />}
  //     label="Delete"
  //     onClick={() =>{
  //       setActiveItem(params);
  //       setActiveAction('DELETE');
  //     }
  //     }
  // />);

  return tempActions;
}

export const getClientListActions = (params, editItem, setActiveItem, setActiveAction) => {
    let tempActions = [];
    if (Session.permissions["Client"].UPDATE) {
        tempActions = [
            <GridActionsCellItem
                icon={<VisibilityIcon />}
                label="View"
                onClick={() => {
                    setActiveItem({
                        id: params.id,
                        ...params.row,
                    });
                    setActiveAction('VIEW');
                }}
            />,
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={(e) => editItem(e, params.id)}
            />,
            <GridActionsCellItem
                icon={
                    <PersonIcon
                        color={params.row?.deactivated
                            ? 'error' : 'success'}
                    />
                }
                label="UpdateStatus"
                onClick={() => {
                    setActiveItem({
                        id: params.id,
                        ...params.row,
                    });
                    setActiveAction('UPDATE_STATUS');
                }}
            />,
            <GridActionsCellItem
                icon={<ForwardToInboxIcon color='primary' />}
                label="Send"
                onClick={() => {
                    setActiveItem({
                        id: params.id,
                        ...params.row,
                    });
                    setActiveAction('SEND');
                }}
            />,
        ];
    }
    return tempActions;
}

export const getExportAnalyticsListActions = (params, showAnalysis) => {
    let tempActions = [];
    if (Session.permissions["MeetingAnalysis"].UPDATE)
        tempActions.push(<GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={(e) => showAnalysis(params)}
        />);
    return tempActions;
}

export const checkCreatePermission = (entity) => {
    return Session.permissions?.[entity]?.CREATE;
}

export const getCreditListActions = (params, setActiveItemId, entity) => {
  let tempActions = [];
  if (Session.permissions?.[entity]?.UPDATE)
      tempActions.push(<GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => setActiveItemId(params)}
      />);

  return tempActions;
}
import Client from "./_client";

export const getSubscribers = async (subscriberFilters = {}) => {
    let url = "/subscriber?";

    Object.keys(subscriberFilters).forEach((key) => {
        if (subscriberFilters[key]) url += `${key}=${subscriberFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();


    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to fetch Subscribers!");


    return result.data || [];
}

export const exportSubscribers = async (emails = []) => {
    let url = "/subscriber/export";

    const result = await new Client({
        path: url,
        payload: {
            emails,
        }
    }).post();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to fetch Subscribers list!");


    return result.data || [];
}
import { makeStyles } from '@mui/styles';
import { signOut } from 'services/auth';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Session from "utils/Session";
import CustomLink from './CustomLink';

const useStyles = makeStyles({
    headerContainer: {
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        zIndex: 201,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
        borderBottom: '1px solid rgb(0 0 0 / 15%)',
    },
    header: {
        display: "flex",
        boxSizing: "border-box",
        height: "56px",
        paddingRight: "12px",
        paddingLeft: "12px",
        position: "relative",
        webkitBoxAlign: "center",
        alignItems: "center",
        webkitBoxPack: "justify",
        justifyContent: "space-between",
        flexShrink: 0,
    },
    menuItem: {
        textDecoration: 'unset',
        color: 'black',
        display: "flex",
        alignItems: "center",
        margin: "0px 8px",
    },
    menuItemText: {
        overflow: "hidden",
        height: "1.6em",
        padding: "0px 8px"
    },
    activeMenuItem: {
        borderBottom: "4px solid #2DABFF",
        textDecoration: 'unset',
        color: 'black',
        display: "flex",
        alignItems: "center",
        margin: "0px 8px",
    },

});


export default function Header() {
    const classes = useStyles();
    const navigate = useNavigate();

    const logout = () => {
        signOut();
        navigate("/");
    }

    const signIn = () => navigate("/");

    const headerMenuItems = [
        {
            name: "Clients",
            path: '/clients'
        },
        {
            name: "Users",
            path: '/users'
        },
        {
            name: "Analytics",
            path: '/analytics'
        },
        {
            name: "Templates",
            path: '/templates'
        },
        {
            name: "General",
            path: '/general'
        },
        {
            name: "Integrations",
            path: '/integrations'
        },
        {
            name: "Configs",
            path: '/configs'
        },
        {
            name: "Pathways",
            path: "/pathways"
        }
    ];

    return (
        <Box className={classes.headerContainer}>
            <header className={classes.header}>
                <img
                    src='https://assets.languify.in/images/lang-logo.svg'
                    alt='languify'
                    className='logo'
                    style={{ height: '2.2rem', padding: '0px 20px 0px 5px' }}
                />

                {Session.isLoggedIn() &&
                    (<Box display="flex" justifyContent="flex-start" flexGrow={1}>
                        {
                            headerMenuItems.map((menuItem, index) => (
                                <CustomLink
                                    to={menuItem.path}
                                    key={index}
                                    className={classes.menuItem}
                                    activeClassName={classes.activeMenuItem}
                                >
                                    <Typography
                                        className={classes.menuItemText}
                                        variant='subtitle1'
                                    >
                                        {menuItem.name}
                                    </Typography>
                                </CustomLink>
                            ))
                        }
                    </Box>)}

                <Box display='flex' justifyContent='flex-end'>
                    {Session.isLoggedIn() ?
                        <Button
                            variant='contained'
                            onClick={logout}>Logout</Button> :
                        <Button
                            variant='contained'
                            onClick={signIn}>Sign In</Button>
                    }
                </Box>
            </header>
        </Box>
    );
}

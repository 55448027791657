import * as React from 'react';
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import PathwayList from "components/lists/Pathways";
import PathwayEditor from "components/forms/PathwayEditor";
import PathwayRequest from 'components/lists/PathwayRequest';
import ContentShell from 'components/ContentShell';
import Tags from 'components/lists/Tags';
import TagEditor from 'components/forms/TagEditor';

export default function Pathways() {
  return (
    <Routes>
      <Route path="/">
        <Route path='/' element={<PathwayLayout />}>
          <Route index element={<Navigate to="list" />} />
          <Route path="list" element={<PathwayList />} />
          <Route path="list/:id" element={<PathwayEditor />} />
          <Route path="create" element={<PathwayEditor />} />
          <Route
            path="request"
            element={
              <React.Suspense fallback={<>...</>}>
                <PathwayRequest />
              </React.Suspense>
            }
          />
          <Route
            path="tag"
            element={
              <React.Suspense fallback={<>...</>}>
                <Tags />
              </React.Suspense>
            }
          />
          <Route path="tag/:id" element={<TagEditor />} />
        </Route>
      </Route>
    </Routes>
  )
}

const SideBarLinks = [
  {
    label: "Pathway List",
    path: 'list',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
  {
    label: "Pathway Requests",
    path: 'request',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
  {
    label: "Tags",
    path: 'tag',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
];

function PathwayLayout() {
  return (
    <ContentShell sideBarLinks={SideBarLinks}>
      <Outlet />
    </ContentShell>
  );
}
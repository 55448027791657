import create from "zustand";

export const defaults = {
    pathway: {},
    questions: [],
    modules: [],
    domains: [],
    domainTags: {}
};

const useStore = create(
    (set, get) => ({
        ...defaults,
        setPathway: (pathway) => set({ pathway }),
        setQuestions: (questions) => set({ questions }),
        setModules: (modules) => set({ modules }),
        setDomainTags: (domainTags) => set({ domainTags }),
        setDomains: (domains) => set({ domains }),

        init: ({
            pathway,
            modules
        }) => {
          set({
              pathway,
              modules
          })
        },
        reset: () => set(defaults),
    }),
);

export default useStore;
import React from "react";
import Pagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridVisibleRowCountSelector,

} from '@mui/x-data-grid';

function ListPagination(props) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const visibleRows = useGridSelector(apiRef, gridVisibleRowCountSelector);

  const path = window.location.pathname;

  React.useEffect(() => {
    const page = JSON.parse(sessionStorage.getItem(`page-${path}`));
    if (page) apiRef.current.setPage(page);
  }, [path, apiRef]);

  const handleChange = React.useCallback((event, value) => {
    apiRef.current.setPage(value - 1);
    (async () => sessionStorage.setItem(`page-${path}`, (value - 1).toString()))();
  }, [apiRef, path]);

  return (
    <>
      <span>Viewing <b>{visibleRows}</b> out of <b>{props.rowCount || 0}</b></span>
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={handleChange}
        {...props}
        siblingCount={2}

      />
    </>
  );
}

export default ListPagination;
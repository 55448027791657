import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import ContentHeader from "components/ContentHeader";
import { useSnackbar } from "contexts";
import { TextField } from "@mui/material";
import CustomButton from "components/CustomButton";
import { getTagById,updateTag } from "services/tagService";
import { saveJobDescription } from "services/jobDescription";
import { DomainSelect } from "components/DomainSelect";
import Session from 'utils/Session';

const jobDescriptionValidationSchema = Yup.object().shape({
  company: Yup.string().required('Required'),
  jobRole: Yup.string().required("Required"),
  domain: Yup.string().nullable().required("Required"),
  jobDescription: Yup.string().required("Required"),
  user:Yup.string().required("Required"),
});

const updateValidationSchema = Yup.object({
  category: Yup.string().required('Category is required'),
  name: Yup.string().required('Tag Name required')
});
  
const InitialValues = {
  company: '', jobRole: '', domain: null, jobDescription: "", user: Session.userId
};

const useStyles = makeStyles({
  box:{
    display:"flex",
    flexDirection:"column",
    gap:"8px"
  }
});

export default function JobDescriptionEditor() {
  const classes = useStyles();
  const { id } = useParams();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [jobDescription, setJobDescription] = useState(InitialValues);
  const [updateData,setUpdateData]=useState({name:"",category:""});
  const [editData,setEditData]=useState(false);

  const handleSubmit = async () => {
    try {
      jobDescription.user = Session.userId;

      if (id && id !== 'create') {

        const valid = await updateValidationSchema.isValid(updateData, { abortEarly: false });
        if (!valid) {
          snackbar.error("Seems like you have some invalid data");
          return;
        }
        await updateTag(id, { ...updateData, content: jobDescription.jobDescription });
      }
      else {
        const valid = await jobDescriptionValidationSchema.isValid(jobDescription, { abortEarly: false });

        if (!valid) {
          snackbar.error("Seems like you have some invalid data");
          return;
        }

        await saveJobDescription(null, jobDescription);
      }

      snackbar.showSnackbar("Data saved successfully !!");
      navigate(`/general/jobDescription`);
    } catch (error) {
      snackbar.showSnackbar(error.message, 'error', false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (id && id !== 'create') {
          const jobDesc = await getTagById(id);
          const jobRole = await getTagById(jobDesc.jobRole);
          const company = await getTagById(jobRole.company)
          setUpdateData({ name: jobDesc.name, category: jobDesc.category });
          setEditData(true)

          const newInitialValues = {
            company: company.name,
            jobRole: jobRole.name,
            domain: jobRole.domain,
            jobDescription: jobDesc?.content || "",
            user: Session.userId,
          };

          setJobDescription(newInitialValues);
        }
      } catch (error) {
        snackbar.showSnackbar(error.message, 'error', false);
      } finally {
        setLoading(false)
      }
    })();
  }, [id]);

  return (
    <Box className={classes.box}>
      <ContentHeader title="Company Job Description Form" />
      <Box className={classes.box}>
        <TextField name="company" value={jobDescription.company} placeholder="Company Name"
          disabled={editData ? true : false}
          onChange={(e)=>{setJobDescription({...jobDescription, company: e.target.value})}}
        />
        <TextField name="jobRole" value={jobDescription.jobRole} placeholder="Job role"
          disabled={editData ? true : false}
          onChange={(e)=>{setJobDescription({...jobDescription, jobRole: e.target.value})}}
        />
        <DomainSelect
          disabled={editData ? true : false}
          defaultValue={jobDescription.domain}
          onChange={(e)=> {setJobDescription({...jobDescription, domain: e?._id || null})}}
        />
        <TextField name="jobDescirption" value={jobDescription.jobDescription} 
          placeholder="Job description"
          sx={{width: '100%'}}
          rows={8} multiline
          onChange={(e)=>{setJobDescription({...jobDescription, jobDescription: e.target.value})}}
        />
      </Box>
      <Box className={classes.buttonContainer}>
        <CustomButton
          type="submit"
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </CustomButton>
      </Box>
    </Box>
  );
}
import Client from "./_client";
import Session from "../utils/Session";

export const getSurveyQuestions = async (surveyFilters = {}) => {
    let url = "/surveyQuestions?";

    if (!surveyFilters.user) surveyFilters.user = Session.userId;

    Object.keys(surveyFilters).forEach((key) => {
        if (surveyFilters[key]) url += `${key}=${surveyFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get survey questions. Please try again.');
    }

    return result.data;
}

export const getSurveyQuestionById = async (surveyQuestionId) => {
    const result = await new Client({
        path: `/surveyQuestions/${surveyQuestionId}`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get survey question. Please try again.');
    }

    return result.data;
}

export const saveSurveyQuestions = async (surveyQuestionId, surveyQuestionData) => {
    let result;

    if (surveyQuestionId) {
        result = await new Client({
            path: `/surveyQuestions/${surveyQuestionId}`,
            payload: surveyQuestionData,
        }).put();
    } else {
        result = await new Client({
            path: `/surveyQuestions`,
            payload: surveyQuestionData,
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save survey question. Please try again."
        );
    } else {
        return result.data;
    }
}

export const deleteSurveyQuestionById = async (surveyQuestionId) => {

    let url = `/surveyQuestions/${surveyQuestionId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete template. Please try again."
        );

    return result.data;
}

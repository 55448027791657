import Client from "./_client";

export const getSEPTemplates = async (filters = {}) => {
  let url = "/sepTemplate?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to fetch SEP templates!");

  return result.data || [];
};

export const getSEPTemplateById = async (id) => {
  const result = await new Client({
    path: `/sepTemplate/${id}`,
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to fetch SEP templates!");

  return result.data || [];
};

export const deleteSEPTemplateById = async (templateId) => {

  let url = `/sepTemplate/${templateId}`;

  let result = await new Client({
    path: url
  }).delete();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to delete template. Please try again."
    );

  return result.data;
};

export const saveSEPTemplate = async (templateId, template) => {
  let result;

  if (templateId) {
    result = await new Client({
      path: `/sepTemplate/${templateId}`,
      payload: template,
    }).put();
  } else {
    result = await new Client({
      path: "/sepTemplate",
      payload: template,
    }).post();
  }

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save template. Please try again."
    );
  }

  return result.data;
};

export const sendBulkInvites = async (templateId, usersData = []) => {
  const result = await new Client({
    path: `/client/sep/template/${templateId}/invite`,
    payload: { usersData },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw result;
  }

  return result?.data;
};
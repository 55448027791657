import * as React from 'react';
import { useField } from "formik";
import { makeStyles } from "@mui/styles";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    }
  },
}));

export function PasswordField(props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        margin: 1,
        ...(props.sx ? { ...props.sx } : {})
      }}
      InputProps={{
        endAdornment: (
          < InputAdornment position="end" >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
    />
  );
};

export function ShadowPasswordField(props) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box width="100%">
      <Typography variant="body1" marginTop="1em">
        {props.title}
      </Typography>
      <TextField
        {...props}
        className={classes.root}
        variant="filled"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end" >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          ...props.InputProps,
        }}
        sx={{
          color: "#9C9C9C",
          minWidth: 120,
          ...(props.sx ? { ...props.sx } : {})
        }}
      />
    </Box>
  );
}

export function FormPasswordField({ label, ...props }) {
  const [field, meta] = useField(props);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        px: 1,
        ...(props.sx ? { ...props.sx } : {})
      }}
      InputProps={{
        endAdornment: (
          < InputAdornment position="end" >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...field,
        ...props.InputProps,
      }}
      label={label}
      error={!!(meta.touched && meta.error)}
      helperText={meta.error}
    />
  );
};
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';

export const PrimaryActionVariants = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

function ConfirmationDialog({
  open,
  onClose,
  onSuccess,
  primaryActionVariant,
  title = "Confirm",
  message = "Are you sure?",
  primaryAction = "Yes",
  secondaryAction = "No",
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: 380, maxWidth: 500 }}>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant='outlined'
          autoFocus
        >
          {secondaryAction}
        </Button>
        <Button
          onClick={onSuccess}
          variant='contained'
          color={PrimaryActionVariants[primaryActionVariant] || 'primary'}
          autoFocus
        >
          {primaryAction}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Badge from '@mui/material/Badge';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

import ClientSelect from 'components/ClientSelect';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ListPagination from 'components/ListPagination';
import ExportSEPAnalyticsDialog
    from "components/dialogs/ExportSEPAnalyticsDialog";
import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import { getSEPAttempts } from "services/attemptService";
import Session from "utils/Session";
import { formatDate } from 'utils';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterList from './FilterList';


const useStyles = makeStyles((theme) => ({
    clickable: {
        cursor: "pointer",
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        height: 'fit-content',
        width: "100%",
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
            height: "fit-content",
            width: "100%",
            "& .MuiDataGrid-columnHeaderTitleContainer": {
                height: "fit-content",
                width: "100%",
                "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                    height: "fit-content",
                    width: "100%",
                }
            }
        }
    },
    dateInput: {
        width: "100%",
        padding: "0.25rem 0.5rem",
        fontFamily: theme.typography.fontFamily,
    }
}));

function
    SEPAnalytics() {
    const classes = useStyles();

    const [exportAnalyticsDialog, setExportAnalyticsDialog] = React.useState(false);

    const [rows, setRows] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [total, setTotal] = React.useState(0);
    const [activeItemId, setActiveItemId] = React.useState(null);
    const [client, setClient] = React.useState(null);
    const [isFilter, setIsFilter] = React.useState(true);
    const [catStartDate, setCatStartDate] = React.useState(null);
    const [catEndDate, setCatEndDate] = React.useState(null);
    const [satStartDate, setSatStartDate] = React.useState(null);
    const [satEndDate, setSatEndDate] = React.useState(null);


    const [device, setDevice] = React.useState(null);

    const handleExportClick = () => {
        setExportAnalyticsDialog(true);
    }

    const closeExportAnalyticsDialog = () => {
        setExportAnalyticsDialog(false);
    }

    const handleDeviceChange = (event, newDevice) => {
        setDevice(newDevice);
    };

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerClassName: classes.tableHeader,
            valueGetter: (params) => params?.row?.user?.email,
            filterable: false,
        },
        {
            field: 'template',
            flex: 2,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: (params) => params?.row?.sepTemplate?.name,
        },
        {
            field: 'summary',
            flex: 2,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: ({ row: { summary: { written, spoken, mcq } } }) => {
                return `W: ${written.score}, S: ${spoken.score}, M: ${mcq.score}`
            },
        },
        {
            field: 'createdAt (mm/dd/yyyy)',
            flex: 2,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: (params) => formatDate(params?.row?.createdAt, 'MM/DD/YYYY')

        },
        {
            field: 'startedAt (mm/dd/yyyy)',
            flex: 2,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: (params) => params?.row?.startedAt ?
                formatDate(params?.row?.startedAt, 'MM/DD/YYYY') : 'null',

        },
        {
            field: 'finishedAt (mm/dd/yyyy)',
            flex: 2,
            headerClassName: classes.tableHeader,
            filterable: false,
            valueGetter: (params) => params?.row?.finishedAt ?
                formatDate(params?.row?.finishedAt, 'MM/DD/YYYY') : 'null',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: "actions",
            width: 130,
            headerClassName: classes.tableHeader,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<VisibilityIcon />}
                    label="View"
                    onClick={(e) => setActiveItemId(params.row._id)}
                />
            ]
        },
    ];


    React.useEffect(() => {
        (async () => {
            if (!client) {
                setRows([]);
                setTotal(0);
                return
            };

            let filters = {};
            if (device === 'mobile') filters['mobile'] = true;
            if (device === 'desktop') filters['mobile'] = false;

            if (catStartDate && catEndDate) {
                filters['createdAtStart'] = catStartDate;
                filters['createdAtEnd'] = catEndDate;
            }
            if (satStartDate && satEndDate) {
                filters['startedAtStart'] = satStartDate;
                filters['startedAtEnd'] = satEndDate;
            }

            const { sepAttempts, total } = await getSEPAttempts({
                client: client._id,
                include: ["user", "sepTemplate"],
                pageNumber: pageNumber + 1,
                pageSize,
                includeTotal: true,
                ...filters
            });

            setRows(sepAttempts);
            setTotal(total);

        })();
    }, [pageNumber, pageSize, client, device,
        catStartDate, catEndDate, satStartDate, satEndDate,
    ]);

 
    return (
        <Box mt={2} position="relative">
            <ContentHeader title="SEP Analytics">
                <Box>
                    {false ?
                        <IconButton onClick={() => { }} title="Clear Filters">
                            <Badge color="secondary" variant="dot" >
                                <FilterListOffIcon color="primary" />
                            </Badge>
                        </IconButton> : null
                    }
                </Box>

                {Session.permissions["InterviewAnswer"].READ && (
                    <CustomButton
                        variant="contained"
                        onClick={handleExportClick}
                    >
                        Export
                    </CustomButton>
                )}
                <FilterList>
                    <ClientSelect onChange={setClient} sepClients />
                    <Box width={"100%"}>
                        <Typography>Created at</Typography>
                        <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"space-around"} >
                            <input type="date" name="date-input" id="" className={classes.dateInput} onChange={(event) => {
                                setCatStartDate(event?.target.value)
                            }} />
                            <input type="date" name="date-input" id="" className={classes.dateInput} onChange={(event) => {
                                setCatEndDate(event?.target.value)
                            }}/>
                        </Box>
                    </Box>
                    <Box width={"100%"}>
                        <Typography>Started at</Typography>
                        <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"space-around"} >
                            <input type="date" name="date-input" id="" className={classes.dateInput} onChange={(event) => {
                                setSatStartDate(event?.target.value)
                            }} />
                            <input type="date" name="date-input" id="" className={classes.dateInput} onChange={(event) => {
                                setSatEndDate(event?.target.value)
                            }}/>
                        </Box>
                    </Box>
                    <ToggleButtonGroup
                        color="primary" value={device} exclusive
                        onChange={handleDeviceChange} aria-label="Device"
                        style={{ height: '30px', widht:"100%", marginInline:"auto", marginTop:"0.5 rem" }}
                    >
                        <ToggleButton value="mobile" style={{ textTransform: 'none' }}>Mobile</ToggleButton>
                        <ToggleButton value="desktop" style={{ textTransform: 'none' }}>Desktop</ToggleButton>
                    </ToggleButtonGroup>
                </FilterList>
            </ContentHeader>

            <Box style={{ height: "75vh", width: '100%' }} >
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    rowCount={total}
                    onPageChange={setPageNumber}
                    onPageSizeChange={setPageSize}
                    rows={rows}
                    columns={columns}
                    disableSelectionOnClick
                    density='compact'
                    getRowClassName={() => classes.row}
                    getRowId={(row) => row._id}
                    components={{
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </Box>
            <ExportSEPAnalyticsDialog
                open={exportAnalyticsDialog}
                onClose={closeExportAnalyticsDialog}
                onSuccess={closeExportAnalyticsDialog}
            />
            <EntityViewDialog
                open={!!activeItemId}
                getEntity={(id) => rows.find(item => item._id === id)}
                title="SEP Attempt"
                entityId={activeItemId}
                onClose={() => setActiveItemId(null)}
            />
        </Box>
    );
}

export default SEPAnalytics;
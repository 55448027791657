import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';

import { SimpleSelect } from "components/FormSelect";
import CustomButton from 'components/CustomButton';
import { useLoading, useSnackbar } from 'contexts';
import { getUsers, exportTemplates } from "services";
import { ROLES } from '../../config';
import { getBatches } from 'services/batch';

function ClientTemplateDialog({
    open,
    onClose,
    onSuccess,
    selectedUsers = [],
    selectedTemplates = [],
    sep = false,
}) {
    const loading = useLoading();
    const snackbar = useSnackbar();
    const [formOptions, setFormOptions] = React.useState(null);
    const [client, setClient] = React.useState("");
    const [batchOptions, setBatchOptions] = React.useState(null);
    const [batch, setBatch] = React.useState("");

    React.useEffect(() => {

        setClient("");
        setFormOptions([]);
        if (open) {
            (async () => {
                try {
                    loading.show();

                    const { users } = await getUsers({
                        roleNames: sep ? ROLES.SEP_CLIENT : ROLES.CLIENT,
                    });

                    setFormOptions(users.filter((user) =>
                        !selectedUsers.has(user._id)
                    ));
                } catch (error) {
                    snackbar.showSnackbar(error.message, "error", true);
                } finally {
                    loading.hide();
                }
            })();
        }
    }, [open]);

    // get batches
    React.useEffect(() => {
        setBatch("");
        setBatchOptions([]);
        if(open && client.length){
            (async () => {
                const { batches } = await getBatches({ client });
                
                setBatchOptions(batches);
            })();
        }
    }, [client]);

    const handleOptionChange = (e) => {
        setClient(e.target.value);
    }

    const handleSaveClick = async () => {
        try {
            loading.show();

            await exportTemplates(selectedTemplates, client, batch, sep);
            snackbar.showSnackbar("Templates exported successfully", "success", true);
            onSuccess();
        } catch (error) {
            snackbar.showSnackbar(error.message, "error", true);
        } finally {
            loading.hide();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Create Template Duplicate
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 380, maxWidth: 500 }}>
                {formOptions && <SimpleSelect
                    options={formOptions}
                    value={client}
                    onChange={handleOptionChange}
                    label="Select a client"
                />}
                { !sep ?
                    batchOptions && 
                    <SimpleSelect
                        options={batchOptions}
                        value={batch}
                        onChange={(e)=>setBatch(e.target.value)}
                        label="Select Batch"
                    />
                    : null
                }
                <Box display="flex">
                    <CustomButton
                        variant="contained"
                        onClick={handleSaveClick}
                        disabled={sep ? !(client.length) : batch.trim() === ""}
                    >
                        Save
                    </CustomButton>
                    <CustomButton
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </CustomButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ClientTemplateDialog;
import { Form, Formik } from "formik";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import FormTextField from "components/FormTextField";
import CustomButton from "components/CustomButton";
import { FormPasswordField } from "components/FormPasswordField";
import { FormSelect } from "components/FormSelect";
import { FormRadioButtonGroup } from "components/FormRadio";
import { FormCheckbox } from "components/FormCheckbox";
import FileUploadInput from "components/FileUploadInput";

const useStyles = makeStyles((theme) => ({
  formRoot: {
    padding: 8,
    border: "1px solid #e9e9e9",
  }
}));

const TextFieldTypes = ["text", "email", "number"];

function FormikForm({
  initialValues = {},
  fields = [],
  formOptions = {},
  validationSchema = null,
  onSubmit = () => { },
  onCancel = () => { },
  primaryLabel = "Save",
  secondaryLabel = "Cancel",
}) {
  const classes = useStyles();
  return (
    <Box className={classes.formRoot}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={1} p={1}>
              {fields.map((field, index) => (
                <Grid key={index} item xs={field.xs || 6} md={field.md || 4}>
                  {TextFieldTypes.includes(field.type) && (
                    <FormTextField
                      {...field}
                      InputProps={{ readOnly: !!field.readOnly }}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                    />
                  )}
                  {(field.type === "select") && (
                    <FormSelect
                      {...field}
                      name={field.name}
                      label={field.label}
                      options={formOptions[field.name] || []}
                      defaultValue={initialValues?.[field.name] || ''}
                    />
                  )}
                  {(field.type === "checkbox") && (
                    <FormCheckbox
                      name={field.name}
                      label={field.label}
                      {...field}
                    />
                  )}
                  {(field.type === "password") && (
                    <FormPasswordField
                      InputProps={{ readOnly: !!field.readOnly }}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                    />
                  )}
                  {(field.type === "radio") && (
                    <FormRadioButtonGroup
                      name={field.name}
                      label={field.label}
                      options={formOptions[field.name] || []}
                      defaultChecked={initialValues?.[field.name] || false}
                      {...field}
                    />
                  )}
                  {(field.type === "file") && (
                    <FileUploadInput
                      {...field}
                      name={field.name}
                      file={initialValues?.[field.name] || {}}
                      onUploadCancelled={() => props.setFieldValue(field.name, {})}
                      onUpload={(file) => props.setFieldValue(field.name, file)}
                      errors={{ file: props.errors[field.name] || false }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <Box
              m={2}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <CustomButton
                type="submit"
                variant="contained"
              >
                {primaryLabel}
              </CustomButton>
              <CustomButton
                variant="outlined"
                onClick={onCancel}
              >
                {secondaryLabel}
              </CustomButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default FormikForm;
import * as React from 'react';
import {
    DataGrid,
    getGridStringOperators,
    GridActionsCellItem
} from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { getUsers, deleteUserById, getUserById } from "services/users";

import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import GridToolbar from 'components/GridToolbar';
import ContentHeader from 'components/ContentHeader';
import ClientSelect from 'components/ClientSelect';
import ListPagination from 'components/ListPagination';
import ListQuickSearch from 'components/ListQuickSearch';

import { useList } from "hooks/useList";

import { ROLES } from 'config';
import { formatDate } from 'utils/date';
import FilterList from './FilterList';
import BatchSelect from 'components/BatchSelect';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = { roleNames: ROLES.STUDENT, includeTotal: true, include: ['client'] };

export default function InterviewUsers() {
    const classes = useStyles();

    const [activeItemId, setActiveItemId] = React.useState(null);

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getUsers,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "users",
        deleteItemById: deleteUserById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        console.log("Items Text", items);
        if (value) {
            setFilters({ ...BaseFilters, ...filters, [columnField]: value });
        } else {
            const newFilters = { ...BaseFilters, ...filters };
            delete newFilters[[columnField]];
            setFilters(BaseFilters);
            setFilters(BaseFilters);
        }
    };
    const handleBatchChange = (newValue) => {
        if (newValue) {
            const batchList = newValue.map((el) => el._id);
            handleFilterChange({ items: [{ columnField: "batch", value: batchList }] });
        }
    }

    const handleSortModelChange = (newModel) => {
        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };
    const batchFilters = React.useMemo(() => {
        return filters['batch'];
    }, [filters]);


    const columns = React.useMemo(() => {
        return [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'client',
                headerName: 'Client',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const client = params?.row?.client?.name;
                    return client ? client : '-';
                },
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'createdAt',
                headerName: 'Registration date',
                flex: 1,
                headerClassName: classes.tableHeader,
                valueGetter: (params) => {
                    const date = params?.row?.createdAt;
                    return formatDate(date);
                }
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 130,
                headerClassName: classes.tableHeader,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="View"
                        onClick={(e) => setActiveItemId(params.row._id)}
                    />
                ]
            },
        ];
    }, [classes.tableHeader]);

    const handleClientChange = React.useCallback((client) => {
        if (!client) {
            handleFilterChange({
                items: [{ columnField: 'client', value: undefined }]
            });
        };
        handleFilterChange({
            items: [{ columnField: 'client', value: client._id }]
        });
    }, []);

    return (
        <Box>
            <ContentHeader
                title="Interview Users"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <FilterList>
                    <ListQuickSearch onFilterChange={handleFilterChange} value={filters["name"]} />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by email"
                        field="email"
                        value={filters["email"]}
                    />
                    <ClientSelect onChange={handleClientChange} defaultValue={filters[['client']]} />
                    <BatchSelect onChange={handleBatchChange} client={filters["client"]} defaultValue={batchFilters} />
                </FilterList>
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <EntityViewDialog
                open={!!activeItemId}
                getEntity={getUserById}
                title="Interview User"
                entityId={activeItemId}
                onClose={() => setActiveItemId(null)}
            />
        </Box>
    );
}
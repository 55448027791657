import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ContentEditableDiv from './ContentEditableDiv';

export default function DND({
  index, question, answer, setAnswer, fibType, options, setOptions
}) {

  const inputHandler = (i) => (e) => {
    setOptions((dndOpts) => {
      const newDndOptions = [...dndOpts];
      newDndOptions[i] = e.target.value;
      return newDndOptions;
    })
  };

  React.useEffect(() => {
    question.options = options;
  }, [options, question]);

  return (
    <>
      <ContentEditableDiv
        required
        label={"Question"}
        onInput={e => question.question = e.target.innerHTML}
        placeholder="Added question content..."
      />
      <TextField
        required
        fullWidth
        disabled={options.length <= 0}
        inputProps={{ minlen: 0, maxlen: 2000 }}
        size="small"
        label={"Correct Order"}
        placeholder="Add comma separated correct order(e.g. 2,3,4,1)"
        value={answer}
        onChange={e => setAnswer(e.target.value.split(","))}
        helperText={options.length <= 0 ? "Add options to add correct order" : ""}
      />
      <br /><br />
      <Stack direction="column" spacing={1}>
        {options.map((option, i) => (
          <Box key={i}>
            <TextField
              required
              fullWidth
              inputProps={{ minlen: 0, maxlen: 2000 }}
              size="small"
              label={"Option ".concat(i + 1)}
              placeholder="300"
              onInput={inputHandler(i)}
            />
          </Box>
        ))}
      </Stack>
    </>
  );
}
